import React, { useState, useEffect, useRef, useCallback, useMemo } from "react";
import "./slider.css"; // Assuming CSS file is imported

//TODO: make this fluid sliding

const Slider = ({ slides, onClose }) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [startX, setStartX] = useState(0); // Starting X position for horizontal swipes
    const [startY, setStartY] = useState(0); // Starting Y position for vertical swipes
    const [isDragging, setIsDragging] = useState(false);
    const [swipeThreshold] = useState(50); // Define the swipe threshold
    const thumbnailsRef = useRef(null); // Ref for the thumbnails container
    const isScrollingRef = useRef(false); // Ref to track if a scroll is in progress

    // Debounce utility function for limiting event execution
    const debounce = (func, wait) => {
        let timeout;
        return (...args) => {
            clearTimeout(timeout);
            timeout = setTimeout(() => func.apply(this, args), wait);
        };
    };

    // Auto-scroll function
    const autoScroll = useCallback(() => {
        if (thumbnailsRef.current) {
            // Scroll to the next thumbnail
            thumbnailsRef.current.scrollBy({
                top: 0,
                left: 80, // Adjust this value based on thumbnail width and spacing
                behavior: "smooth",
            });
        }
    }, []);

    useEffect(() => {
        const throttleScroll = setInterval(() => {
            if (thumbnailsRef.current) {
                autoScroll(); // Call the auto-scroll function
            }
        }, 3000); // Auto-scroll every 3 seconds

        return () => clearInterval(throttleScroll); // Cleanup interval on component unmount
    }, [autoScroll]);

    // Handle mouse wheel scrolling (disabled on mobile)
    const handleWheel = useCallback(
        (e) => {
            e.preventDefault(); // Prevent the default scroll behavior
            if (isScrollingRef.current) return;
            isScrollingRef.current = true;

            // Determine the scroll direction and change slide accordingly
            if (e.deltaY < 0) {
                previousSlide();
            } else if (e.deltaY > 0) {
                nextSlide();
            }

            setTimeout(() => {
                isScrollingRef.current = false;
            }, 200); // Adjust the timeout as needed
        },
        [isScrollingRef]
    );

    // Debounced handleWheel event for better performance
    useEffect(() => {
        const debouncedHandleWheel = debounce((e) => {
            if (window.innerWidth <= 768) return; // Disable wheel scroll on mobile
            handleWheel(e);
        }, 100); // Debounced by 100ms

        window.addEventListener("wheel", debouncedHandleWheel);

        return () => {
            window.removeEventListener("wheel", debouncedHandleWheel);
        };
    }, [handleWheel]);

    // Touch event handlers for mobile swipe gestures
    const handleTouchStart = useCallback((e) => {
        try {
            if (e.touches.length === 1) { // Only proceed if one finger is used for the touch
                setStartX(e.touches[0].clientX); // Get the starting X position
                setStartY(e.touches[0].clientY); // Get the starting Y position
                setIsDragging(true); // Set dragging state
            }
        }
        catch (err) {

        }
    }, []);

    const handleTouchMove = useCallback(() => {
        if (!isDragging) return;
        // You can add visual feedback if needed
    }, [isDragging]);

    const handleTouchEnd = useCallback(
        (e) => {
            if (!isDragging) return;
            const currentX = e.changedTouches[0].clientX;
            const currentY = e.changedTouches[0].clientY;
            const diffX = startX - currentX;
            const diffY = startY - currentY;

            // Handle horizontal swipe (left/right)
            if (Math.abs(diffX) > Math.abs(diffY)) {
                if (diffX > swipeThreshold) {
                    nextSlide(); // Swipe left
                } else if (diffX < -swipeThreshold) {
                    previousSlide(); // Swipe right
                }
            }
            setIsDragging(false); // Reset dragging state
        },
        [isDragging, startX, startY, swipeThreshold]
    );

    // Next and previous slide handlers
    const nextSlide = useCallback(() => {
        setCurrentIndex((prevIndex) =>
            prevIndex === slides.length - 1 ? 0 : prevIndex + 1
        );
    }, [slides.length]);

    const previousSlide = useCallback(() => {
        setCurrentIndex((prevIndex) =>
            prevIndex === 0 ? slides.length - 1 : prevIndex - 1
        );
    }, [slides.length]);

    // Keyboard navigation for accessibility
    useEffect(() => {
        const handleKeyDown = (e) => {
            if (e.key === "ArrowUp") {
                previousSlide();
            } else if (e.key === "ArrowDown") {
                nextSlide();
            }
        };

        window.addEventListener("keydown", handleKeyDown);
        return () => {
            window.removeEventListener("keydown", handleKeyDown);
        };
    }, [previousSlide, nextSlide]);

    // Lazy load the current, next, and previous images
    const currentSlide = useMemo(() => slides[currentIndex], [slides, currentIndex]);
    const prevSlide = useMemo(
        () => slides[currentIndex === 0 ? slides.length - 1 : currentIndex - 1],
        [slides, currentIndex]
    );
    const nextSlideMemo = useMemo(
        () => slides[currentIndex === slides.length - 1 ? 0 : currentIndex + 1],
        [slides, currentIndex]
    );

    return (
        <div className="c-slider-modal">
            <div className="c-slider-backdrop" onClick={onClose}></div>

            <div
                className="c-slider-container"
                onTouchStart={handleTouchStart}
                onTouchMove={handleTouchMove}
                onTouchEnd={handleTouchEnd}
                onMouseDown={handleTouchStart}
                onMouseUp={handleTouchEnd}
            >
                {/* Left Side: Thumbnails */}
                <div className="thumbnails" ref={thumbnailsRef}>
                    {slides.map((slide, index) => (
                        slide.image && (
                            <img
                                key={index}
                                src={slide.image}
                                alt={`Slide ${index}`}
                                className={`thumbnail ${currentIndex === index ? "active" : ""}`}
                                onClick={() => setCurrentIndex(index)}
                            />
                        ))
                    )
                    }
                </div>

                {/* Right Side: Active Image and Description */}
                <div className="current-slide">
                    <div className="main-image">
                        {
                            currentSlide.image && (
                                <img
                                    src={currentSlide.image}
                                    alt={`Slide ${currentIndex + 1}`}
                                    className={isDragging ? "dragging" : ""}
                                />
                            )
                        }
                    </div>

                    {/* Lazy load prev and next images */}
                    <div className="lazy-images" style={{ display: 'none' }}>
                        <img src={prevSlide.image} alt="Previous slide" />
                        <img src={nextSlideMemo.image} alt="Next slide" />
                    </div>

                    {/* Indicator Section */}
                    <div className="indicator-container">
                        {/* Left and Right Buttons */}
                        <button className="nav-button left" onClick={previousSlide}>
                            &#8249;
                        </button>
                        <button className="nav-button right" onClick={nextSlide}>
                            &#8250;
                        </button>
                    </div>

                    {/* Description */}
                    <div className="c-slider-description" >
                        <p style={
                            {
                                color: "white !important",
                            }
                        }  dangerouslySetInnerHTML={{ __html: currentSlide.description }} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Slider;
