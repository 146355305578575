import Config from "../values/Strings/Config";

export const getArticle = ({ articleSlug, token, nextPageUrl }) => {
  let requestOptions;
  if (token) {
    requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };
  } else {
    requestOptions = {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    };
  }
  let url = nextPageUrl ? nextPageUrl : Config.GET_ARTICLE_URL + articleSlug;
  return fetch(url, requestOptions)
    .then((res) => res.json())
    .then((res) => res);
};
