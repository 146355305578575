import Config from "../values/Strings/Config";

export const completeOrder = (data, token) => {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json", "Authorization": "Bearer "+ token },
    body: JSON.stringify(data),
  };
  let url = Config.STRIPE_PAY_URL;
  return fetch(url, requestOptions)
    .then((res) => res.json())
    .then((res) => res);
};
