import { takeEvery, call, put } from 'redux-saga/effects'
import { PROFILE_UPDATE } from '../actions/type'
import { profileUpdate } from '../services/profileUpdate.api'
import { ActionCreators } from '../actions'

export const watchGetProfileUpdate = function* () {    
  yield takeEvery(PROFILE_UPDATE, workerGetProfileUpdate)
}

function* workerGetProfileUpdate(action) {
  try {    
    const res = yield call(profileUpdate, action.data);
    yield put(ActionCreators.profileUpdateSuccess(res));
  } catch (e){
    yield put(ActionCreators.profileUpdateFailure(e));
  }
}
