import { GET_SUBJECT, GET_SUBJECT_SUCCESS, GET_SUBJECT_ERROR } from './type'

export const getSubject = (data) => ({
  type: GET_SUBJECT,
  data
})

export const getSubjectSuccess = (data) => ({
  type: GET_SUBJECT_SUCCESS,
  data
})

export const getSubjectFailure = (error) => ({
  type: GET_SUBJECT_ERROR,
  error
})


