import { FORGET_PASSWORD, FORGET_PASSWORD_SUCCESS, FORGET_PASSWORD_ERROR } from './type'

export const forgetPassword = (data) => ({
  type: FORGET_PASSWORD,
  data
})

export const forgetPasswordSuccess = (data) => ({
  type: FORGET_PASSWORD_SUCCESS,
  data
})

export const forgetPasswordFailure = (error) => ({
  type: FORGET_PASSWORD_ERROR,
  error
})


