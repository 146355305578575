import { FAQ, FAQ_SUCCESS, FAQ_ERROR } from "../actions/type"

const defaultState = {
  load: false,
  data: {},
  error: null,
}

export const faq = (state = defaultState, action) => {
  const response = action.data;
  switch (action.type) {
    case FAQ:
      return {
        ...state,
        load: true,
      }
    case FAQ_SUCCESS:
      return { 
        ...state, 
        data: response,
        load: false
      }
    case FAQ_ERROR:
      return { 
        ...state, 
        error: response,
        load: false,
      }
    default:
      return state
  }
}


