import { takeEvery, call, put, select } from "redux-saga/effects";
import { PLAN } from "../actions/type";
import { plan } from "../services/plan.api";
import { ActionCreators } from "../actions";

export const watchGetPlan = function* () {
   yield takeEvery(PLAN, workerGetPlan);
};

function* workerGetPlan(action) {
   try {
      const tokenValue = yield select((state) => state.login?.user?.data?.token);
      const res = yield call(plan, tokenValue);
      yield put(ActionCreators.planSuccess(res));
   } catch (e) {
      yield put(ActionCreators.planFailure(e));
   }
}
