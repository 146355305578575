import Config from "../values/Strings/Config";

export const removeSavedArticle = ({ article_id, token }) => {
  const requestOptions = {
    method: "GET",
    headers: {
      Authorization: "Bearer " + token,
    },
  };
  
  let url = `${Config.REMOVE_SAVEDARTICLE_URL}?article_id=${article_id}`;

  return fetch(url, requestOptions)
    .then((res) => res.json())
    .then((res) => res);
};
