import { takeEvery, call, put } from 'redux-saga/effects'
import { ARCHIVE_YEAR, ARCHIVE_DATA } from '../actions/type'
import { getArchiveYear, getArchiveData } from '../services/archive.api'
import { ActionCreators } from '../actions'

export const watchArchiveYear = function* () {    
  yield takeEvery(ARCHIVE_YEAR, workerArchiveYear)
}

function* workerArchiveYear(action) {
  try {          
    const res = yield call(getArchiveYear, action.data);
    yield put(ActionCreators.archiveYearSuccess(res));
  } catch (e){
    yield put(ActionCreators.archiveYearFailure(e));
  }
}

export const watchArchiveData = function* () {    
  yield takeEvery(ARCHIVE_DATA, workerArchiveData)
}

function* workerArchiveData(action) {
  try {          
    const res = yield call(getArchiveData, action.data);
    yield put(ActionCreators.archiveDataSuccess(res));
  } catch (e){
    yield put(ActionCreators.archiveDataFailure(e));
  }
}