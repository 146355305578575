import { FAQ, FAQ_SUCCESS, FAQ_ERROR } from './type'

export const faq = (data) => ({
  type: FAQ,
  data
})

export const faqSuccess = (data) => ({
  type: FAQ_SUCCESS,
  data
})

export const faqFailure = (error) => ({
  type: FAQ_ERROR,
  error
})


