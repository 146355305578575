import React, { useEffect, useState } from "react";
import moment from "moment";
import { Link, useNavigate } from "react-router-dom";
import Config from "../../values/Strings/Config";
import { connect } from "react-redux";
import { ActionCreators } from "../../actions";
import { Formik } from "formik";
import * as Yup from "yup";

import { findLastRow } from "../../values/utils";
import objectLogo from "../../assets/images/object-logo-2.png";
import searchIcon from "../../assets/images/search-icon.svg";
import profileIcon from "../../assets/images/profile.svg";
import objectIconLogo from "../../assets/images/object-icon-logo.png";
import objectOCoin from "../../assets/images/object-o-coin.svg";
import headerShapeRight from "../../assets/images/header-shape-right.svg";
import headerShapeLeft from "../../assets/images/header-shape-left.svg";
import ReadingProgressBar from "./ReadingProgressBar";
import LoginPopUp from "../popups/LoginPopUp";
//import css 
import "../popups/popup.css"

function Header({ subjectDSPCH, subjectData, userData }) {
  let navigate = useNavigate();
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);

  const toggleLoginModal = () => {
    setIsLoginModalOpen(!isLoginModalOpen);
    const navbarCollapse = document.getElementById('mainNav');
    if (navbarCollapse.classList.contains('show')) {
      navbarCollapse.classList.remove('show');
      navbarCollapse.classList.add('collapse'); // Manually collapse the navbar
    }

    const mainCollapsed = document.getElementById('mainCollapsed');
    if (!mainCollapsed.classList.contains('show')) {
      mainCollapsed.classList.add('collapsed');
    }
    
  };


  useEffect(() => {
    getSubject();
  }, []);

  const getSubject = () => {
    subjectDSPCH();
  };

  const onSubmit = (fields) => {
    document.getElementById("mainCollapsed").click();
    navigate(`/search?q=${fields.search}`);
  };


  return (
    <div>
      {/* <!--============================== Header Start ==============================--> */}
      <header id="header">
        <nav className="navbar">
          <div className="container">
            <div className="nav-inside d-flex align-items-top justify-content-between">
              <Link className="navbar-brand" to="/">
                <img src={objectLogo} alt="" />
              </Link>
              <button
                className="navbar-toggler collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#mainNav"
                id="mainCollapsed"
                aria-controls="mainNav"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>
              <div className="header-right d-flex flex-column align-items-end">
                <Link to="/search" className="header-search-btn">
                  <img src={searchIcon} alt="" />
                </Link>
                {userData?.success === true ? (
                  <Link
                    to="/profile"
                    className="header-login-btn d-none d-md-block"
                  >
                    <img src={profileIcon} alt="" />
                  </Link>
                ) : (
                  <div
                    onClick={toggleLoginModal}
                  >
                    <Link
                      to=""
                      className="header-login-btn d-none d-md-block"
                    >
                      Sign In
                    </Link>
                  </div>
                )}
                <Link
                  to="/subscription"
                  className="header-subscribe-btn d-none d-md-block"
                >
                  Subscribe
                </Link>
              </div>
            </div>
          </div>
        </nav>
        {isLoginModalOpen && (
          <div className="popUp" id="popUp">
            <LoginPopUp closeModal={toggleLoginModal} />
          </div>
        )}
      </header>
      {/* <!--============================== Header Start ==============================--> */}
      <header id="header1">
        <nav className="navbar">
          <div className="container-fluid">
            <div className="nav-inside d-flex align-items-center justify-content-between">
              <button
                className="navbar-toggler collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#mainNav"
                aria-controls="mainNav"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>
              <Link to="/search" className="navbar-menu-search-btn d-md-none">
                <img src={searchIcon} alt="" />
              </Link>
              <Link className="header-logo" to="/">
                <img src={objectIconLogo} alt="" />
              </Link>
              <div className="header-right d-none d-md-block">
                {userData?.success === true ? (
                  <Link to="/profile" className="header-login-btn me-2">
                    <img src={profileIcon} alt="" />
                  </Link>
                ) : (

                  <Link
                    to=""
                    className="btn btn-defaul"
                  > <div
                    onClick={toggleLoginModal}
                  >
                      Sign In

                    </div>
                  </Link>
                )}
                <Link to="/subscription" className="btn btn-default">
                  SUBSCRIBE
                </Link>
              </div>
            </div>
          </div>
        </nav>
        {/* <ReadingProgressBar/> */}
        <div className="article-progress-bar"></div>
      </header>
      {/* <!--============================== Header Menu Start ==============================-->   */}
      <div className="navbar-collapse collapse" id="mainNav">
        <div className="navbar-inside">
          <div className="navbar-menu-head ">
            <div className="container-fluid">
              <div className="d-flex justify-content-between">
                <button
                  className="navbar-toggler collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#mainNav"
                  aria-controls="mainNav"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span className="navbar-toggler-icon"></span>
                </button>
                <Link to="/search" className="navbar-menu-search-btn d-lg-none">
                  <img src={searchIcon} alt="" />
                </Link>
                <div className="navbar-menu-logo">
                  <img src={objectIconLogo} alt="" />
                </div>
                <div className="navbar-menu-right d-none d-lg-flex align-items-center">
                  {userData?.success === true ? (
                    <Link
                      to="/profile"
                      className="header-login-btn d-none d-md-block me-2"
                    >
                      <img src={profileIcon} alt="" />
                    </Link>
                  ) : (
                    <div
                      onClick={toggleLoginModal}
                    >
                      <Link
                        to=""
                        className="header-login-btn d-none d-md-block"
                      >
                        Sign In
                      </Link>
                    </div>
                  )}
                  <Link to="/subscription" className="btn btn-default">
                    SUBSCRIBE
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="header-menu-body">
            <div className="header-menu-search-box d-none d-lg-block">
              <Formik
                initialValues={{
                  search: "",
                }}
                validationSchema={Yup.object().shape({
                  search: Yup.string().required(),
                })}
                onSubmit={(fields, { resetForm }) => {
                  resetForm();
                  onSubmit(fields);
                }}
              >
                {({ setFieldValue, values, handleSubmit }) => (
                  <form onSubmit={handleSubmit}>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search here "
                      onChange={(e) => setFieldValue("search", e.target.value)}
                      value={values.search}
                    />
                  </form>
                )}
              </Formik>
            </div>

            <ul className="header-menu-list d-flex flex-wrap">
              {subjectData?.success === true &&
                subjectData?.data?.map((item, index) => (
                  <li
                    className={
                      "header-menu-item" +
                      (index > findLastRow(subjectData?.data?.length)
                        ? " no-line"
                        : "")
                    }
                    key={index}
                  >
                    <Link
                      to={`/subject/` + item?.slug}
                      className="header-menu-link"
                    >
                      <div className="header-menu-icon">
                        <img
                          src={Config.SUBJECT_IMG_URL + item?.image}
                          alt=""
                        />
                      </div>
                      <span>{item?.title}</span>
                    </Link>
                  </li>
                ))}
              <li className="header-menu-item d-lg-none">
                <a
                  target={"_blank"}
                  href="https://www.journeyofobjects.com"
                  className="header-menu-link explore-journy-box"
                >
                  <h5> Explore Journey Of Objects</h5>
                </a>
              </li>
            </ul>

            <div className="header-menu-bottom">
              <div className="d-none d-lg-flex align-items-center ">
                <div className="hmb-icon">
                  <img src={objectOCoin} alt="" />
                </div>
                <div className="hmb-text">
                  <p>
                    {" "}
                    Written by the best journalists in the country, our
                    newsletters cover multiple topics.{" "}
                    <Link to="/newsletter">
                      Click here to subscribe to our Newsletters.
                    </Link>
                  </p>
                </div>
              </div>
              <div className="hmb-btn-group d-flex d-lg-none justify-content-center">
                {/* <Link to="#!" className="btn">
                  Popular
                </Link> */}
                {userData?.success === true ? (
                  <Link to="/profile" className="header-login-btn">
                    <img src={profileIcon} alt="" />
                  </Link>
                ) : (

                  <Link
                    className="btn btn-default"
                  ><div
                    onClick={toggleLoginModal}
                  >
                      SIGN IN

                    </div>
                  </Link>
                )}
                <Link to="/subscription" className="btn btn-default">
                  SUBSCRIBE
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!--============================== Header End ==============================--> */}
      <div className="post-meta">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="post-meta-content">
                <span>
                  <img src={headerShapeLeft} alt="" />
                </span>
                <h5 style={{ textTransform: "uppercase" }}>
                  Bombay, {moment(new Date()).format("dddd") +
                    ", " +
                    moment(new Date()).format("D MMMM, YYYY")}
                </h5>
                <span>
                  <img src={headerShapeRight} alt="" />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  subjectData: state.getSubject.data,
  userData: state.login.user,
});

const mapDispatchToProps = (dispatch) => ({
  subjectDSPCH: () => {
    dispatch(ActionCreators.getSubject());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
