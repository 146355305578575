import { AUTHOR, AUTHOR_SUCCESS, AUTHOR_ERROR } from './type'

export const author = () => ({
  type: AUTHOR,
})

export const authorSuccess = (data) => ({
  type: AUTHOR_SUCCESS,
  data
})

export const authorFailure = (error) => ({
  type: AUTHOR_ERROR,
  error
})


