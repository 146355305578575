import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { ActionCreators } from "../actions";
import { Header, Footer, Loading } from "../components";
import { Link } from "react-router-dom";
import Config from "../values/Strings/Config";
import { Helmet } from "react-helmet";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../css/slider.css";
import { Formik } from "formik";
import * as Yup from "yup";
import { useAlert } from "react-alert";
import {
  convertDateFormate,
  fixedHeader,
  isValidUrl,
  wordsCutting,
} from "../values/utils";
import headerShapeRight from "../assets/images/header-shape-right.svg";
import headerShapeLeft from "../assets/images/header-shape-left.svg";
import objectVol1 from "../assets/images/ObjectInPrintv4.jpg";
import qmscoin from "../assets/images/qmscoin.svg";
import qmscoin2024 from "../assets/logos/Qalam Emblem Final-02.png";
import crosswordImg from "../assets/images/crosword.png";
import playIcon from "../assets/images/play-icon.svg";
import objectLogo from "../assets/images/object-icon-logo.png";
import menuIcon from "../assets/images/menu-icon8.svg";
import moment from "moment";


const Home = ({ pageDataDSPCH, pageData, pageDataLoad, userData }) => {
  const alert = useAlert();
  const [newsletterLoading, setNewsLetterLoading] = useState("");

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }, 100);
    getPageData();
    fixedHeader();
  }, []);

  const getPageData = () => {
    pageDataDSPCH();
  };

  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          arrows: true,
          slidesToShow: 2,
          slidesToScroll: 1,
          dots: false,
          adaptiveHeight: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          arrows: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
          adaptiveHeight: false,
          infinite: true,
          margin: 50
        },
      },
    ],
  };

  const onSubmit = (val) => {
    let auth_obj = {
      fullname: userData?.data?.user?.name,
      email: val.email,
      subscription_type: val.type,
    };
    let without_auth_obj = {
      email: val.email,
      subscription_type: val.type,
    };
    setNewsLetterLoading(true);
    let final_obj = userData?.success ? auth_obj : without_auth_obj;
    fetch(Config.NEWSLETTER_SUBSCRIPTION_URL, {
      method: "POST",
      body: JSON.stringify(final_obj),
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => res.json())
      .then((res) => {
        setNewsLetterLoading(false);
        if (res?.success === true) {
          alert.success(
            <div style={{ textTransform: "initial" }}>{res?.data?.message}</div>
          );
        }
      })
      .catch((err) => {
        setNewsLetterLoading(false);
        alert.success(
          <div style={{ textTransform: "initial" }}>{err?.message}</div>
        );
      });
  };

  return (
    <div className="home">
      <Helmet>
        <title>
          {pageData?.success === true && pageData?.data?.meta_title}
        </title>
        <meta
          name="description"
          content={
            pageData?.success === true && pageData?.data?.meta_description
          }
        />
      </Helmet>
      <Header />
      {/* <!--============================== Main Start ==============================--> */}

      <main id="main">

        {/* <!--============================== Content Start ==============================--> */}
        <div className="content-container pb-0 pt-4 main_post_container">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="news-post-content text-center">
                  <div className="news-post-img">
                    <img src={pageData?.data?.cover_story?.image} alt="" />
                  </div>
                  <h1>{pageData?.data?.cover_story?.title}</h1>
                  <h4>{pageData?.data?.cover_story?.sub_title}</h4>
                  <div className="news-post-meta d-flex justify-content-between">
                    <span className="news-post-cat">
                      {pageData?.data?.cover_story?.category_name}{" "}
                    </span>

                    <span className="article-post-by">
                      by <span>{pageData?.data?.cover_story?.author_name}{" "}</span>
                    </span>
                    <span className="news-post-date">
                      {convertDateFormate(
                        pageData?.data?.cover_story?.article_date
                      )}
                    </span>
                  </div>
                  <Link
                    to={"/article/" + pageData?.data?.cover_story?.slug}
                    className="read-more-btn"
                  >
                    Read Full Story
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!--============================== Content End ==============================--> */}
        {/* <!--============================== Content Start ==============================--> */}
        <div className="content-container home-popular-news-container pb-0">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-heading">
                  <h5>
                    <span className="left">
                      <img src={headerShapeRight} alt="" />
                    </span>
                    popular
                    <span className="right">
                      <img src={headerShapeLeft} alt="" />
                    </span>
                  </h5>
                </div>

                <div className="slider-container">
                  <Slider
                    {...settings}
                    className="grid-list pb-0 popular_slider"
                  >
                    {pageData?.data?.popular_data?.map((item, ind) => (
                      <div className="grid-item" key={ind}>
                        <div className="grid-box">
                          <Link
                            to={"/article/" + item?.slug}
                            className="grid-img"
                          >
                            {isValidUrl(item?.image) ? (
                              <img src={item?.image} alt="popularimage" />
                            ) : (
                              <img
                                src={Config.IMG_URL + "thumb/" + item?.image}
                                alt="popularimage"
                              />
                            )}
                          </Link>
                          <Link to={"/article/" + item?.slug}>
                            <h4>{item?.title}</h4>
                            {/* <h4>{wordsCutting(item?.title, 18)}</h4> */}
                          </Link>
                          <div className="post-by">
                            By <span>{item?.author_name}</span>
                          </div>

                          <p
                            style={{
                              textTransform: "uppercase",
                            }}
                          >
                            {convertDateFormate(item?.publish_date)}
                          </p>
                        </div>
                      </div>
                    ))}
                  </Slider>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!--============================== Content End ==============================--> */}
        {/* <!--============================== Content Start ==============================--> */}
        <div className="content-container home-opinion-container pb-0">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-heading">
                  <h5>
                    <span className="left">
                      <img src={headerShapeRight} alt="" />
                    </span>
                    OPINIONS
                    <span className="right">
                      <img src={headerShapeLeft} alt="" />
                    </span>
                  </h5>
                </div>
                <div className="home-openion-content text-center">
                  <blockquote>
                    {pageData?.data?.opinions_data?.quote}
                  </blockquote>
                  <div className="post-by">
                    By <span>{pageData?.data?.opinions_data?.author_name}</span>
                  </div>
                  <Link
                    to={"/article/" + pageData?.data?.opinions_data?.slug}
                    className="read-more-btn"
                  >
                    Read Full Story
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!--============================== Content End ==============================--> */}
        {/* <!--============================== Content Start ==============================--> */}

        {
          pageData.success > 0 && (
            <div className="content-container home-case-update-container pb-10">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="hcu-content">
                      <div className="hcu-head">
                        <div className="hcu-logo">
                          <img src={objectLogo} alt="" />
                        </div>
                        <h3>
                          {wordsCutting(
                            pageData?.data?.tracker_data?.title,
                            26
                          )}
                        </h3>
                        <div
                          className="hcuh-right"
                          style={{ textTransform: "uppercase" }}
                        >
                          UPDATED ON <br />
                          {moment(
                            pageData?.data?.tracker_data["date"]
                          ).format("dddd")}
                          , <br />{" "}
                          {moment(
                            pageData?.data?.tracker_data["date"]
                          ).format("D MMMM YYYY")}
                        </div>
                      </div>
                      <div className="hcu-body">
                        <div className="hcu-left">
                          <div className="hcu-left-upper">
                            <h6>ARTICLES</h6>
                            <Link
                              to={
                                "/article/" +
                                pageData?.data?.tracker_data?.articles[0]
                                  ?.slug
                              }
                            >
                              <h3>
                                {wordsCutting(
                                  pageData?.data?.tracker_data?.articles[0]
                                    ?.title,
                                  32
                                )}
                              </h3>
                            </Link>
                            <h5>
                              By{" "}
                              {wordsCutting(
                                pageData?.data?.tracker_data?.articles[0]
                                  ?.author_name,
                                64
                              )}
                            </h5>
                          </div>
                          <div
                            className="hcu-left-mid d-flex"
                            style={{ minHeight: 167 }}
                          >
                            <div className="hculm-left">
                              <Link
                                to={
                                  "/article/" +
                                  pageData?.data?.tracker_data?.articles[1]
                                    ?.slug
                                }
                              >
                                <h3>
                                  {wordsCutting(
                                    pageData?.data?.tracker_data?.articles[1]
                                      ?.title,
                                    39
                                  )}
                                </h3>
                              </Link>
                              <h5>
                                By{" "}
                                {
                                  pageData?.data?.tracker_data?.articles[1]
                                    ?.author_name
                                }
                              </h5>
                            </div>
                            <div className="hculm-right">
                              <Link
                                to={
                                  "/article/" +
                                  pageData?.data["tracker_data"]?.articles[2]
                                    ?.slug
                                }
                              >
                                <h3>
                                  {wordsCutting(
                                    pageData?.data["tracker_data"]
                                      ?.articles[2]?.title,
                                    27
                                  )}
                                </h3>
                              </Link>
                              <div className="hculm-icon">
                                <img src={menuIcon} alt="" />
                              </div>
                            </div>
                          </div>
                          <div
                            className="hcu-left-lower"
                            style={{ minHeight: 154 }}
                          >
                            <Link
                              to={
                                "/article/" +
                                pageData?.data["tracker_data"]?.articles[3]
                                  ?.slug
                              }
                            >
                              <h3>
                                {wordsCutting(
                                  pageData?.data["tracker_data"]?.articles[3]
                                    ?.title,
                                  64
                                )}{" "}
                              </h3>
                            </Link>
                            <h5>
                              By{" "}
                              {wordsCutting(
                                pageData?.data["tracker_data"]?.articles[3]
                                  ?.author_name,
                                64
                              )}
                            </h5>
                          </div>
                        </div>

                        <div className="hcu-right">
                          <div style={{ height: 252 }}>
                            <img
                              src={
                                Config.TRACKERDATA_IMG_URL +
                                `${pageData?.data?.tracker_data?.image_1}`
                              }
                              alt=""
                              style={{
                                width: "100%",
                                height: "100%",
                                objectFit: "fill",
                              }}
                            />
                          </div>
                          <div style={{ height: 222 }}>
                            <img
                              src={
                                Config.TRACKERDATA_IMG_URL +
                                `${pageData?.data?.tracker_data?.image_2}`
                              }
                              alt=""
                              style={{
                                width: "100%",
                                height: "100%",
                                objectFit: "fill",
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )
        }
        {/* <!--============================== Content End ==============================--> */}
        {/* <!--============================== Content Start ==============================--> */}
        <div className="content-container essay-series-container pb-0">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-heading">
                  <h5>
                    <span className="left">
                      <img src={headerShapeRight} alt="" />
                    </span>
                    essay
                    <span className="right">
                      <img src={headerShapeLeft} alt="" />
                    </span>
                  </h5>
                </div>
                <ul className="essay-series-post-list mobile_slider">
                  {pageData?.data?.essay_series?.articles?.map((item, ind) => (
                    <li className="essay-series-post-item" key={ind}>
                      <Link
                        to={"/article/" + item?.slug}
                        className="essay-series-post-img"
                      >
                        {isValidUrl(item?.image) ? (
                          <img src={item?.image} alt="" />
                        ) : (
                          <img src={Config.IMG_URL + item?.image} alt="" />
                        )}
                      </Link>
                      <div className="essay-series-post-content">
                        <Link to={"/article/" + item?.slug}>
                          <h4>{item?.title}</h4>
                        </Link>
                        <p>{item?.excerpt}</p>
                        <div className="post-by">
                          By <span>{item?.author_name}</span>
                        </div>
                        <div
                          className={
                            ind === 0 ? "post-date post-date-1" : "post-date"
                          }
                        >
                          {convertDateFormate(item?.publish_date)}
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
        {/* <!--============================== Content End ==============================--> */}
        {/* <!--============================== Content Start ==============================--> */}
        <div className="content-container essay-of-month-container pb-0">
          <div className="container">
            <div className="row">
              <div className="col-lg-8">
                <div className="section-heading">
                  <h5>
                    <span className="left">
                      <img src={headerShapeRight} alt="" />
                    </span>
                    ESSAY OF THE MONTH
                    <span className="right">
                      <img src={headerShapeLeft} alt="" />
                    </span>
                  </h5>
                </div>
                <div className="eom-content d-flex flex-wrap">
                  <div className="eom-img">
                    {isValidUrl(pageData?.data?.essay_of_month?.image) ? (
                      <img src={pageData?.data?.essay_of_month?.image} alt="" />
                    ) : (
                      <img
                        src={
                          Config.IMG_URL +
                          "thumb/" +
                          pageData?.data?.essay_of_month?.image
                        }
                        alt=""
                      />
                    )}
                  </div>
                  <div className="eom-text">
                    <h4 className="d-none d-md-block">
                      {pageData?.data?.essay_of_month?.title}
                    </h4>
                    <h4 className="d-block d-md-none">
                      {pageData?.data?.essay_of_month?.title}
                    </h4>
                    <p>{pageData?.data?.essay_of_month?.excerpt}</p>
                    <div className="post-by d-none">
                      By{" "}
                      <span>{pageData?.data?.essay_of_month?.author_name}</span>
                    </div>

                    <div className="post-date  d-none">
                      {convertDateFormate(
                        pageData?.data?.essay_of_month?.publish_date
                      )}
                    </div>
                    <Link
                      to={"/article/" + pageData?.data?.essay_of_month?.slug}
                      className="read-more-btn mt-1"
                    >
                      Read Full Story
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="eom-card">
                  <div className="eom-card-head">Q&A</div>
                  <div className="eom-card-body">
                    <blockquote>{pageData?.data?.qna?.quote}</blockquote>
                    <div className="post-by">
                      By <span>{pageData?.data?.qna?.author_name}</span>
                    </div>
                  </div>
                  <div className="eom-card-bottom">
                    <Link to={"/article/" + pageData?.data?.qna?.slug}>
                      Read More
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!--============================== Content End ==============================--> */}
        {/* <!--============================== Content Start ==============================--> */}
        <div className="content-container home-photo-story-container pb-0">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-heading">
                  <h5>
                    <span className="left">
                      <img src={headerShapeRight} alt="" />
                    </span>
                    PHOTO STORY
                    <span className="right">
                      <img src={headerShapeLeft} alt="" />
                    </span>
                  </h5>
                </div>
                <div className="home-photo-story-content text-center">
                  <Link
                    to={"/article/" + pageData?.data?.photo_story?.slug}
                    className="home-photo-story-img"
                  >
                    <img src={pageData?.data?.photo_story?.image} alt="" />
                  </Link>

                  <div className="home-photo-story-text">
                    <Link
                      to={"/article/" + pageData?.data?.photo_story?.slug}
                      className=""
                    >
                      <h4>{pageData?.data?.photo_story?.title}</h4>
                    </Link>
                    <p>{pageData?.data?.photo_story?.sub_title}</p>
                    <div className="post-by">
                      By <span>{pageData?.data?.photo_story?.author_name}</span>
                    </div>
                    <div
                      className="post-date"
                      style={{ textTransform: "uppercase" }}
                    >
                      {convertDateFormate(
                        pageData?.data?.photo_story?.publish_date
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!--============================== Content End ==============================--> */}
        {/* <!--============================== Content Start ==============================--> */}
        <div className="content-container home-latest-blog-container pb-0">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-heading">
                  <h5>
                    <span className="left">
                      <img src={headerShapeRight} alt="" />
                    </span>
                    LATEST
                    <span className="right">
                      <img src={headerShapeLeft} alt="" />
                    </span>
                  </h5>
                </div>
                <ul className="grid-list d-md-flex flex-wrap mobile_slider">
                  {pageData?.data?.latest?.map((item, ind) => (
                    <li className="grid-item" key={ind}>
                      <div className="grid-box">
                        <Link
                          to={"/article/" + item?.slug}
                          className="grid-img"
                        >
                          {isValidUrl(item?.image) ? (
                            <img src={item?.image} alt="" />
                          ) : (
                            <img
                              src={Config.IMG_URL + "thumb/" + item?.image}
                              alt=""
                            />
                          )}
                        </Link>
                        <Link to={"/article/" + item?.slug}>
                          <h4>{item?.title}</h4>
                        </Link>
                        <div className="post-by">
                          By <span>{item?.author_name}</span>
                        </div>
                        <p
                          style={{
                            textTransform: "uppercase",
                          }}
                        >
                          {convertDateFormate(item?.publish_date)}
                        </p>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
        {/* <!--============================== Content End ==============================--> */}
        {/* <!--============================== Content Start ==============================--> */}
        <div className="content-container home-monthly-subscription-container pb-0">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="hms-content-outer d-flex flex-wrap">
                  <div className="hms-left">
                    <div className="section-heading">
                      <h5>
                        <span className="left">
                          <img src={headerShapeRight} alt="" />
                        </span>
                        JoB Shop
                        <span className="right">
                          <img src={headerShapeLeft} alt="" />
                        </span>
                      </h5>
                    </div>
                    {/*<div className="hms-membership d-flex flex-wrap">
                      <div className="heading text-center d-md-none w-100">
                        <h4>Object in Print</h4>
                      </div>
                      <div className="hms-membership-logo">
                        <img src={objectVol1} alt="" />
                      </div>
                      <div className="hms-membership-form-content">
                        <h3 className="d-none d-md-block">
                          Object in Print
                        </h3>
                        <p>
                          Object is a hardback, hand-bound magazine. While our
                          stories can be enjoyed through this screen, the real
                          journey is one in which you can touch and feel. To get
                          your print copy please send us an email at
                          <a
                            className="email-mag"
                            href="mailto:magazine@journeyofobjects.com"
                          >
                            magazine@journeyofobjects.com
                          </a>
                        </p>
                        {/* <Formik
                          initialValues={{
                            email: "",
                            type: "weekly",
                          }}
                          validationSchema={Yup.object().shape({
                            email: Yup.string()
                              .email("Email is invalid")
                              .required("Email is required"),
                          })}
                          onSubmit={(fields, { resetForm }) => {
                            resetForm();
                            onSubmit(fields);
                          }}
                        >
                          {({
                            errors,
                            touched,
                            setFieldValue,
                            values,
                            handleSubmit,
                            isSubmitting,
                          }) => (
                            <form onSubmit={handleSubmit}>
                              <div className="hms-membership-form">
                                <div className="form-group">
                                  <input
                                    type="email"
                                    placeholder="Email Address"
                                    className="form-control "
                                    id="email"
                                    value={values.email}
                                    onChange={(e) =>
                                      setFieldValue("email", e.target.value)
                                    }
                                  />
                                </div>
                                {touched.email && errors.email && (
                                  <div
                                    style={{
                                      textAlign: "left",
                                      color: "red",
                                      marginBottom: 10,
                                      fontSize: 12,
                                      backgroundColor: "transparent",
                                    }}
                                  >
                                    {errors.email}
                                  </div>
                                )}
                                <div className="form-group">
                                  <button
                                    type="submit"
                                    className="btn btn-sm btn-default"
                                    disabled={isSubmitting}
                                  >
                                    SIGN UP
                                  </button>
                                </div>
                              </div>
                            </form>
                          )}
                        </Formik> */}
                    {/*</div>
                    </div>*/}
                    <div className="hms-membership d-flex flex-wrap">
                      <div className="heading text-center d-md-none w-100">
                        <h4>Object in Print</h4>
                      </div>
                      <div className="hms-membership-logo" onClick={
                        () => {
                          window.open("https://shop.journeyofobjects.com/", "_blank");
                        }
                      }>
                        <img src={qmscoin2024} alt="" />
                      </div>
                      <div className="hms-membership-form-content">
                        <div>
                          <h3 className="d-none d-md-block">
                            Object Monthly
                            Subscription
                          </h3>
                          <p>
                            {/*If you enjoy consuming your news in long format as much as we like writing it, then this subscription is catered just for you. Monthly printed hard format newsprints with our cover story delivered to your doorstep. Just perfect.*/}
                            In Moulmein, in lower Burma, I was hated by large numbers of people – the only time in my life that I have been important enough for this to happen to me. I was sub-divisional police officer of the town, and in an aimless, petty kind of way anti-European feeling was very bitter.
                          </p>
                        </div>
                        <Formik
                          initialValues={{
                            email: "",
                            type: "magazine",
                          }}
                          validationSchema={Yup.object().shape({
                            email: Yup.string()
                              .email("Email is invalid")
                              .required("Email is required"),
                          })}
                          onSubmit={(fields, { resetForm }) => {
                            resetForm();
                            onSubmit(fields);
                          }}
                        >
                          {({
                            errors,
                            touched,
                            setFieldValue,
                            values,
                            handleSubmit,
                            isSubmitting,
                          }) => (
                            <form onSubmit={handleSubmit}>
                              <div className="hms-membership-form">
                                {/*
                                <div className="form-group">
                                  <input
                                    type="email"
                                    placeholder="Email Address"
                                    className="form-control "
                                    id="email"
                                    value={values.email}
                                    onChange={(e) =>
                                      setFieldValue("email", e.target.value)
                                    }
                                  />
                                </div>
                                {touched.email && errors.email && (
                                  <div
                                    style={{
                                      textAlign: "left",
                                      color: "red",
                                      marginBottom: 10,
                                      fontSize: 12,
                                      backgroundColor: "transparent",
                                    }}
                                  >
                                    {errors.email}
                                  </div>
                                )}*/}
                                <div className="form-group">
                                  <button
                                    type="submit"
                                    className="btn btn-sm btn-default"
                                    disabled={isSubmitting}
                                    onClick={() => {
                                      window.open(
                                        "https://shop.journeyofobjects.com/",
                                        "_blank"
                                      );
                                    }
                                    }
                                  >
                                    To The Shop
                                  </button>
                                </div>
                              </div>
                            </form>
                          )}
                        </Formik>
                      </div>
                    </div>
                  </div>
                  <div className="hms-right">
                    <div className="section-heading">
                      <h5>
                        <span className="left">
                          <img src={headerShapeRight} alt="" />
                        </span>
                        CROSSWORD
                        <span className="right">
                          <img src={headerShapeLeft} alt="" />
                        </span>
                      </h5>
                    </div>
                    <div className="crosword-contetnt">
                      <h5>
                        Challenge yourself to the Object Crossword. Updated
                        monthly.
                      </h5>
                      <div className="crosword-img">
                        <img src={crosswordImg} alt="" />
                      </div>
                    </div>
                    <Link
                      to="/daily-crossword"
                      className="btn btn-default btn-sm btn-block "
                    >
                      PLAY NOW
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!--============================== Content End ==============================--> */}
        {/* <!--============================== Content Start ==============================--> */}
        <div className="content-container home-contributor-container pb-0">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-heading">
                  <h5>
                    <span className="left">
                      <img src={headerShapeRight} alt="" />
                    </span>
                    CONTRIBUTORS
                    <span className="right">
                      <img src={headerShapeLeft} alt="" />
                    </span>
                  </h5>
                </div>
                <ul className="home-contributor-list">
                  {pageData?.data?.contributors?.map((item, ind) => (
                    <li className="home-contributor-item" key={ind}>
                      <div className="home-contributor-box  d-flex flex-wrap align-items-center">
                        <div className="home-contributor-left">
                          <div className="home-contributor-img">
                            <img
                              src={Config.AUTHOR_IMG_URL + item?.image}
                              alt=""
                            />
                          </div>
                        </div>
                        <div className="home-contributor-mid">
                          <h4>
                            {item?.firstname}
                            <span>{item?.lastname}</span>
                          </h4>
                          <h6>{item?.designation}</h6>
                        </div>
                        <div className="home-contributor-right">
                          <p>{item?.about}</p>
                          <Link
                            to={"/author/" + item?.slug}
                            className="read-more-btn"
                          >
                            View All Articles
                          </Link>
                        </div>
                        <div className="text-center d-md-none">
                          <Link
                            to={"/author/" + item?.slug}
                            className="read-more-btn "
                          >
                            Read Full Story
                          </Link>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
        {/* <!--============================== Content End ==============================--> */}
        {/* <!--============================== Content Start ==============================--> */}
        <div className="content-container home-newsletter-container pb-0">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-heading">
                  <h5>
                    <span className="left">
                      <img src={headerShapeRight} alt="" />
                    </span>
                    NEWSLETTER
                    <span className="right">
                      <img src={headerShapeLeft} alt="" />
                    </span>
                  </h5>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 offset-md-3">
                <div className="home-newsletter-form-content text-center">
                  <h5>
                    Get our editors’ guide to what matters in the world,
                    delivered to your inbox fortnightly.
                  </h5>
                  <div className="home-newsletter-form">
                    <Formik
                      initialValues={{
                        email: "",
                        type: "weekly",
                      }}
                      validationSchema={Yup.object().shape({
                        email: Yup.string()
                          .email("Email is invalid")
                          .required("Email is required"),
                      })}
                      onSubmit={(fields, { resetForm }) => {
                        resetForm();
                        onSubmit(fields);
                      }}
                    >
                      {({
                        errors,
                        touched,
                        setFieldValue,
                        values,
                        handleSubmit,
                        isSubmitting,
                      }) => (
                        <form onSubmit={handleSubmit}>
                          <div className="form-group">
                            <input
                              type="email"
                              placeholder="Email Address"
                              className="form-control"
                              id="email"
                              value={values.email}
                              onChange={(e) =>
                                setFieldValue("email", e.target.value)
                              }
                            />
                          </div>
                          {touched.email && errors.email && (
                            <div
                              style={{
                                textAlign: "left",
                                color: "red",
                                marginBottom: 10,
                                fontSize: 12,
                                backgroundColor: "transparent",
                              }}
                            >
                              {errors.email}
                            </div>
                          )}
                          <div className="form-group">
                            <button
                              type="submit"
                              className="btn btn-default btn-block "
                              disabled={isSubmitting}
                            >
                              {newsletterLoading ? "Loading..." : "SIGN UP"}
                            </button>
                          </div>
                        </form>
                      )}
                    </Formik>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!--============================== Content End ==============================--> */}
        {/* <!--============================== Content Start ==============================--> */}
        <div className="content-container home-podcast-container">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-heading">
                  <h5>
                    <span className="left">
                      <img src={headerShapeRight} alt="" />
                    </span>
                    VIDEOS / PODCASTS
                    <span className="right">
                      <img src={headerShapeLeft} alt="" />
                    </span>
                  </h5>
                </div>

                <ul className="home-podcast-list">
                  {pageData?.data?.video_podcast_data?.map((item, ind) => (
                    <li className="home-podcast-item" key={ind}>
                      <div className="home-podcast-box">
                        {isValidUrl(item?.image) ? (
                          <img
                            className="home-podcast-img"
                            src={item?.image}
                            alt=""
                          />
                        ) : (
                          <img
                            className="home-podcast-img"
                            src={Config.IMG_URL + item?.image}
                            alt=""
                          />
                        )}
                        <div className="home-podcast-caption">
                          <Link
                            to={"/article/" + item?.slug}
                            className="home-podcast-ply-btn"
                          >
                            <img src={playIcon} alt="" />
                          </Link>
                          <Link to={"/article/" + item?.slug}>
                            {" "}
                            <h4>{item?.title}</h4>
                          </Link>

                          <div className="home-podcast-duration">
                            {item?.duration}
                          </div>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
                <div className="text-center">
                  <Link to="/videos-podcasts" className="read-more-btn ">
                    See All
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!--============================== Content End ==============================--> */}
        {/* <!--============================== Content Start ==============================--> */}
        <div className="content-container cta-normal-container pt-0">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="cta-normal-content text-center">
                  <p>
                    The best of <span>Object Magazine,</span> in your inbox.{" "}
                    <Link to="/newsletter">
                      Sign up for our Newsletters now.
                    </Link>{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!--============================== Content End ==============================--> */}
      </main>
      {/* <!--============================== Main End ==============================--> */}
      <Footer />
    </div>
  );
};

const mapStateToProps = (state) => ({
  pageData: state.pageData.data,
  pageDataLoad: state.pageData.load,
  userData: state.login.user,
});

const mapDispatchToProps = (dispatch) => ({
  pageDataDSPCH: () => {
    dispatch(ActionCreators.pageData());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Home);
