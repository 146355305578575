import { GET_SAVED_ARTICLE, GET_SAVED_ARTICLE_SUCCESS, GET_SAVED_ARTICLE_ERROR } from './type'

export const getSavedArticle = (data) => ({
  type: GET_SAVED_ARTICLE,
  data
})

export const getSavedArticleSuccess = (data) => ({
  type: GET_SAVED_ARTICLE_SUCCESS,
  data
})

export const getSavedArticleFailure = (error) => ({
  type: GET_SAVED_ARTICLE_ERROR,
  error
})


