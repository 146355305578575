import React, { useEffect, useState, useRef } from "react";
import { Helmet } from "react-helmet";
import { Footer, Header, Loading } from "../components";
import Config from "../values/Strings/Config";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import { ActionCreators } from "../actions";
import { Link, useNavigate } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import { convertDateFormate, fixedHeader, isValidUrl } from "../values/utils";
import searchIcon from "../assets/images/search-icon.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import faPlus from "../assets/icons/add-button.svg";

const Subject = ({
  getIndividualSubjectDSPCH,
  IndividualSubjectData,
  subjectData,
  IndividualSubjectLoad,
}) => {
  // get full url
  const pageURL = window.location.href;
  const params = new URLSearchParams(pageURL.split("?")[1]);
  // get params values
  const query = params.get("q");
  const sortBy = params.get("sort_by");

  const { id } = useParams();
  const [sort_by, setSortBy] = useState(0);
  const [search, setSearch] = useState("");
  const [nextPageUrl, setNextPageUrl] = useState("");
  const [articleList, setArticleList] = useState([]);

  const IndividualSubjectDataRef = useRef(IndividualSubjectData);

  const navigate = useNavigate();

  useEffect(() => {
    if (IndividualSubjectData !== IndividualSubjectDataRef.current) {
      if (
        IndividualSubjectData?.success === true &&
        IndividualSubjectData?.data?.articles
      ) {
        setArticleList((articleList) => [
          ...articleList,
          ...IndividualSubjectData?.data?.articles?.data,
        ]);
      } else {
        setArticleList([]);
      }
    }
  }, [IndividualSubjectData, IndividualSubjectDataRef]);

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }, 100);
    fixedHeader();
    getIndividualSubject();
    if (query) {
      setSearch(query);
    }
    if (sortBy) {
      setSortBy(sortBy);
    }
  }, [id]);

  const getIndividualSubject = () => {
    setArticleList([]);
    if (query && sortBy) {
      getIndividualSubjectDSPCH({
        subjectId: id,
        keyword: query,
        sort_by: sortBy,
      });
    } else if (sortBy) {
      getIndividualSubjectDSPCH({ subjectId: id, sort_by: sortBy });
    } else if (query) {
      getIndividualSubjectDSPCH({ subjectId: id, keyword: query });
    } else {
      getIndividualSubjectDSPCH({ subjectId: id });
    }
  };

  const handleClick = () => {
    if (articleTextRef.current) {
      // Change the style of the paragraph
      articleTextRef.current.style.display = 'block'; // Change to 'block' when clicked
    }
  };

  
  // Define the onClick handler
  const handleLinkClick = () => {
    const navbarCollapse = document.getElementById('mainNav');
    if (navbarCollapse.classList.contains('show')) {
      navbarCollapse.classList.remove('show');
      navbarCollapse.classList.add('collapse'); // Manually collapse the navbar
    }

    const mainCollapsed = document.getElementById('mainCollapsed');
    if (!mainCollapsed.classList.contains('show')) {
      mainCollapsed.classList.add('collapsed');
    }
  };

  const getActive = (title, subjectName) => {

    if (title === subjectName) {
      console.log(title, subjectName);
      handleLinkClick();
      return "active";
    } else {
      return "";
    }
  };

  const onHandleLoadMore = (nextPageUrl) => {
    if (sort_by && search) {
      navigate(`/subject/${id}?q=${search}&sort_by=${sort_by}`);
      getIndividualSubjectDSPCH({
        nextPageUrl: nextPageUrl,
        subjectId: id,
        sort_by: sort_by,
        keyword: search,
      });
    } else if (sort_by) {
      navigate(`/subject/${id}?sort_by=${sort_by}`);
      getIndividualSubjectDSPCH({
        nextPageUrl: nextPageUrl,
        subjectId: id,
        sort_by: sort_by,
      });
    } else if (search) {
      navigate(`/subject/${id}?q=${search}`);
      getIndividualSubjectDSPCH({
        nextPageUrl: nextPageUrl,
        subjectId: id,
        keyword: search,
      });
    } else {
      getIndividualSubjectDSPCH({ subjectId: id, nextPageUrl: nextPageUrl });
    }
  };

  const handleSortBy = (event) => {
    setArticleList([]);
    if (event.target.value) {
      setSortBy(event.target.value);
      if (search && nextPageUrl) {
        navigate(`/subject/${id}?q=${search}&sort_by=${event.target.value}`);
        getIndividualSubjectDSPCH({
          subjectId: id,
          sort_by: event.target.value,
          keyword: search,
          nextPageUrl: nextPageUrl,
        });
      } else if (search) {
        navigate(`/subject/${id}?q=${search}&sort_by=${event.target.value}`);
        getIndividualSubjectDSPCH({
          subjectId: id,
          sort_by: event.target.value,
          keyword: search,
        });
      } else if (nextPageUrl) {
        navigate(`/subject/${id}?sort_by=${event.target.value}`);
        getIndividualSubjectDSPCH({
          subjectId: id,
          sort_by: event.target.value,
          nextPageUrl: nextPageUrl,
        });
      } else {
        navigate(`/subject/${id}?sort_by=${event.target.value}`);
        getIndividualSubjectDSPCH({
          subjectId: id,
          sort_by: event.target.value,
        });
      }
    } else {
      getIndividualSubjectDSPCH({
        subjectId: id,
      });
    }
  };

  const onSubmitSearch = (fields) => {
    setArticleList([]);
    setSearch(fields.search);
    if (nextPageUrl && sort_by) {
      navigate(`/subject/${id}?q=${fields.search}&sort_by=${sort_by}`);
      getIndividualSubjectDSPCH({
        subjectId: id,
        keyword: fields.search,
        nextPageUrl: nextPageUrl,
        sort_by: sort_by,
      });
    } else if (nextPageUrl) {
      navigate(`/subject/${id}?q=${fields.search}`);
      getIndividualSubjectDSPCH({
        subjectId: id,
        keyword: fields.search,
        nextPageUrl: nextPageUrl,
      });
    } else if (sort_by) {
      navigate(`/subject/${id}?q=${fields.search}&sort_by=${sort_by}`);
      getIndividualSubjectDSPCH({
        subjectId: id,
        keyword: fields.search,
        sort_by: sort_by,
      });
    } else {
      navigate(`/subject/${id}?q=${fields.search}`);
      getIndividualSubjectDSPCH({ subjectId: id, keyword: fields.search });
    }
  };

  const articleTextRef = useRef(null); // Create a ref to access the paragraph




  return (
    <div>
      <Helmet>
        <title>
          {IndividualSubjectData?.success === true &&
            IndividualSubjectData?.data?.meta_title}
        </title>
        <meta
          name="description"
          content={
            IndividualSubjectData?.success === true &&
            IndividualSubjectData?.data?.meta_description
          }
        />
      </Helmet>
      <Header />
      {/* <!--============================== Main Start ==============================--> */}
      <main id="main">
        {/* <!--============================== Content Start ==============================--> */}
        <div className="content-container search-container pb-0">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="category-heading">
                  <div className="category-heading-box">
                    <div>
                      <img
                        src={
                          Config.SUBJECT_IMG_URL +
                          IndividualSubjectData?.data?.image
                        }
                        alt=""
                      />
                    </div>
                    <h3>{IndividualSubjectData?.data?.title}</h3>
                  </div>
                </div>
                {/*<ul className="category-tab-list d-flex flex-wrap mb-4 mb-md-5">*/}
                <ul className="category-tab-list mb-4 mb-md-5">
                  {subjectData?.success === true &&
                    subjectData?.data?.map((item, ind) => (
                      <div
                        key={ind}
                        className={
                          getActive(
                            IndividualSubjectData?.success === true &&
                            IndividualSubjectData?.data?.title,
                            item?.title
                          )}
                      >
                        <Link
                          to={`/subject/` + item?.slug}
                          className="category-tab-link"
                        >
                          {item?.title}
                        </Link>
                      </div>
                    ))}
                </ul>
                <div className="search-bar mb-0">
                  <Formik
                    initialValues={{
                      search: search,
                    }}
                    enableReinitialize={true}
                    validationSchema={Yup.object().shape({
                      search: Yup.string().required(
                        "Please type in your query in the Search Box."
                      ),
                    })}
                    onSubmit={(fields) => {
                      onSubmitSearch(fields);
                    }}
                  >
                    {({
                      errors,
                      touched,
                      setFieldValue,
                      values,
                      handleSubmit,
                      isSubmitting,
                    }) => (
                      <form onSubmit={handleSubmit}>
                        <div className="search-bar">
                          <input
                            type="text"
                            placeholder="Search"
                            className="form-control"
                            value={values.search}
                            onChange={(e) => {
                              setFieldValue("search", e.target.value);
                            }}
                          />
                          <button type="submit" className="search-bar-btn">
                            <img src={searchIcon} alt="" />
                          </button>
                        </div>
                        {touched.search && errors.search && (
                          <div
                            style={{
                              textAlign: "center",
                              marginTop: 40,
                              marginBottom: 40,
                            }}
                          >
                            {errors.search}
                          </div>
                        )}
                      </form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!--============================== Content End ==============================--> */}
        {/* <!--============================== Content Start ==============================--> */}
        <div className="content-container article-container">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="filter-content d-flex flex-wrap ">
                  <h5>TOP RESULTS</h5>
                  {articleList?.length > 0 && (
                    <div className="filter-box d-flex">
                      <span>Sort by: </span>
                      <select value={sort_by} onChange={handleSortBy}>
                        <option value="">Select</option>

                        <option value="newest">Newest</option>
                        <option value="oldest">Oldest</option>
                      </select>
                    </div>
                  )}
                </div>
                <ul className="article-list">
                  {articleList?.length > 0 &&
                    articleList?.map((item, ind) => (
                      <li className="article-item" key={ind}>
                        <div className="article-box d-flex flex-wrap">
                          <div className="article-text">
                            <ol className="breadcrumb">
                              <li
                                className="breadcrumb-item active"
                                aria-current="page"
                              >
                                {item?.category_name}
                              </li>
                            </ol>
                            <h3>
                              <Link to={"/article/" + item?.slug}>
                                {item?.title}
                              </Link>
                            </h3>
                            <p
                              id={"articleText" + item?.id}
                              ref={articleTextRef} // Attach the ref to the paragraph
                              className="article-excerpt">{item?.excerpt}
                            </p>
                            <div className="post-by">
                              By <span>{item?.author_name}</span>
                            </div>
                            <div
                              className="post-date"
                              style={{ textTransform: "uppercase" }}
                            >
                              {convertDateFormate(item?.publish_date)}
                              {/**Add + icon button */}
                              {/*
                              <span className="icon-plus">
                                <Link to={"/article/" + item?.slug}>
                                  <img src={faPlus} alt="" />
                                </Link>
                              </span>
                              */}

                              <span className="icon-plus">
                                <img
                                  id={item?.id}
                                  onClick={(e) => {
                                    console.log("Clicked", e.target.id);
                                    const articleText = document.getElementById("articleText" + e.target.id);
                                    if (articleText.style.display === 'block') {
                                      articleText.style.display = '-webkit-box';
                                      //rotate image to 0deg
                                      e.target.style.transform = 'rotate(0deg)';
                                    } else {
                                      articleText.style.display = 'block';
                                      //rotate current img 45deg
                                      e.target.style.transform = 'rotate(45deg)';
                                    }
                                  }}
                                  src={faPlus}
                                  alt=""
                                  style={{ cursor: 'pointer' }} // Optional: Change cursor to pointer
                                />
                              </span>

                            </div>
                          </div>
                          <div className="article-thumbnail">
                            {item?.mediaData !== null && (
                              <Link to={"/article/" + item?.slug}>
                                {isValidUrl(item?.image) ? (
                                  <img src={item?.image} alt="" />
                                ) : (
                                  <img
                                    src={
                                      Config.IMG_URL + "thumb/" + item?.image
                                    }
                                    alt=""
                                  />
                                )}
                              </Link>
                            )}
                          </div>
                        </div>
                      </li>
                    ))}
                </ul>
                {IndividualSubjectLoad ? (
                  <Loading type={"spin"} color={"#000"} />
                ) : IndividualSubjectData?.data?.articles?.next_page_url ? (
                  <div className="load-more text-center pb-5">
                    <a
                      href="javascript:void(0)"
                      onClick={() =>
                        onHandleLoadMore(
                          IndividualSubjectData?.data?.articles?.next_page_url
                        )
                      }
                    >
                      Load more result
                    </a>
                  </div>
                ) : (
                  <>
                    {articleList.length === 0 && (
                      <p className="text-center my-5">No article found</p>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        {/* <!--============================== Content End ==============================--> */}
      </main >
      {/* <!--============================== Main End ==============================--> */}
      {/* <!--============================== Footer Start ==============================--> */}
      <Footer />
      {/* <!--============================== Footer End ==============================--> */}
    </div >
  );
};

const mapStateToProps = (state) => ({
  IndividualSubjectData: state.getIndividualSubject.data,
  IndividualSubjectLoad: state.getIndividualSubject.load,
  subjectData: state.getSubject.data,
});

const mapDispatchToProps = (dispatch) => ({
  getIndividualSubjectDSPCH: (data) => {
    dispatch(ActionCreators.getIndividualSubject(data));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Subject);
