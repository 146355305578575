import { GET_QUOTE, GET_QUOTE_SUCCESS, GET_QUOTE_ERROR } from './type'

export const getQuote = (data) => ({
  type: GET_QUOTE,
  data
})

export const getQuoteSuccess = (data) => ({
  type: GET_QUOTE_SUCCESS,
  data
})

export const getQuoteFailure = (error) => ({
  type: GET_QUOTE_ERROR,
  error
})


