import { takeEvery, call, put } from 'redux-saga/effects'
import { GET_ARTICLE } from '../actions/type'
import { getArticle } from '../services/getArticle.api'
import { ActionCreators } from '../actions'

export const watchGetArticle = function* () {    
  yield takeEvery(GET_ARTICLE, workerGetArticle)
}

function* workerGetArticle(action) {
  try {   
    const res = yield call(getArticle, action.data);
    yield put(ActionCreators.getArticleSuccess(res))
  } catch (e){
    yield put(ActionCreators.getArticleFailure(e))
  }
}
