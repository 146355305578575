import { REMOVE_SAVEDARTICLE, REMOVE_SAVEDARTICLE_SUCCESS, REMOVE_SAVEDARTICLE_ERROR } from "../actions/type"

const defaultState = {
  load: false,
  data: {},
  error: null,
}

export const removeSavedArticle = (state = defaultState, action) => {
  const response = action.data;
  switch (action.type) {
    case REMOVE_SAVEDARTICLE:
      return {
        ...state,
        load: true
      }
    case REMOVE_SAVEDARTICLE_SUCCESS:
      return { 
        ...state, 
        data: response,
        load: false
      }
    case REMOVE_SAVEDARTICLE_ERROR:
      return { 
        ...state, 
        error: response,
        load: false,
      }
    default:
      return state
  }
}


