import { CANCEL_SUBSCRIPTION, CANCEL_SUBSCRIPTION_SUCCESS, CANCEL_SUBSCRIPTION_ERROR } from './type'

export const cancelSubscription = (data) => ({
  type: CANCEL_SUBSCRIPTION,
  data
})

export const cancelSubscriptionSuccess = (data) => ({
  type: CANCEL_SUBSCRIPTION_SUCCESS,
  data
})

export const cancelSubscriptionFailure = (error) => ({
  type: CANCEL_SUBSCRIPTION_ERROR,
  error
})


