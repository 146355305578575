
import { combineReducers} from 'redux'
import { login } from './login.reducer'
import { pageData } from './pageData.reducer'
import { getQuote } from './getQuote.reducer'
import { getQna } from './getQna.reducer'
import { getArticle } from './getArticle.reducer'
import { register } from './register.reducer'
import { author } from './author.reducer'
import { getSubject } from './getSubject.reducer'
import { profile } from './profile.reducer'
import { getIndividualSubject } from './getIndividualSubject.reducer'
import { getIndividualAuthor } from './getIndividualAuthor.reducer'
import { completeOrder } from './completeOrder.reducer'
import { plan } from './plan.reducer'
import { profileUpdate } from './profileUpdate.reducer'
import { search } from './search.reducer'
import { forgetPassword } from './forgetPassword.reducer'
import { resetPassword } from './resetPassword.reducer'
import { cmsFooter } from './cmsFooter.reducer'
import { saveArticle } from './saveArticle.reducer'
import { getCrossword } from './getCrossword.reducer'
import { getCrosswordSingle } from './getCrosswordSingle.reducer'
import { getSavedArticle } from './getSavedArticle.reducer'
import { archiveYear, archiveData } from './archive.reducer'
import { faq } from './faq.reducer'
import { removeSavedArticle } from './removeSavedArticle.reducer'
import { cancelSubscription } from './cancelSubscription.reducer'
import { getAllVideoArticle } from './getAllVideoArticle.reducer'

const rootReducer = combineReducers({
  login,
  pageData,
  getQuote,
  getQna,
  getArticle,
  register,
  author,
  getSubject,
  profile,
  getIndividualSubject,
  getIndividualAuthor,
  completeOrder,
  plan,
  profileUpdate,
  search,
  forgetPassword,
  resetPassword,
  cmsFooter,
  saveArticle,
  getCrossword,
  getCrosswordSingle,
  getSavedArticle,
  archiveYear,
  archiveData,
  faq,
  removeSavedArticle,
  cancelSubscription,
  getAllVideoArticle
})

export default rootReducer;