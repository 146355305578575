import { SEARCH, SEARCH_SUCCESS, SEARCH_ERROR } from "../actions/type";

const defaultState = {
  load: false,
  data: {},
  error: null,
};

export const search = (state = defaultState, action) => {
  const response = action.data;
  switch (action.type) {
    case SEARCH:
      return {
        ...state,
        load: true,
      };
    case SEARCH_SUCCESS:
      return {
        ...state,
        data: response,
        load: false,
      };
    case SEARCH_ERROR:
      return {
        ...state,
        error: response,
        data: {},
        load: false,
      };
    default:
      return state;
  }
};
