import { GET_ARTICLE, GET_ARTICLE_SUCCESS, GET_ARTICLE_ERROR } from './type'

export const getArticle = (data) => ({
  type: GET_ARTICLE,
  data
})

export const getArticleSuccess = (data) => ({
  type: GET_ARTICLE_SUCCESS,
  data
})

export const getArticleFailure = (error) => ({
  type: GET_ARTICLE_ERROR,
  error
})


