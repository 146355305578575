import React, { useEffect } from "react";
import { Header, Footer } from "../components";
import { Helmet } from "react-helmet";
import { fixedHeader } from "../values/utils";

const SubscriptionThankyou = () => {
  useEffect(() => {
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }, 100);
    fixedHeader();
  }, []);

  return (
    <div>
      <Helmet>
        <title>Subscription Success - Object Magazine</title>
        <meta name="description" content="{}" />
      </Helmet>
      <Header />
      {/* <!--============================== modal Start ==============================--> */}
      <main id="main">
        <div className="content-container">
          <div className="container">
            <div className="row">
              <div className="col-md-6 offset-md-3">
                <div className="student-plane">
                  <h4>Congratulations!</h4>
                  <p>
                    Your subscription has been confirmed. We hope you have a
                    great journey with us.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
      {/* <!--============================== modal End ==============================--> */}
    </div>
  );
};

export default SubscriptionThankyou;
