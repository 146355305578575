import React, { useRef, useEffect } from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { ActionCreators } from "../actions";
import { Formik } from "formik";
import * as Yup from "yup";
import { Header, Footer } from "../components";
import { useNavigate, useParams } from "react-router-dom";
import { useAlert } from "react-alert";
import { fixedHeader } from "../values/utils";

const ResetPassword = ({
  resetPasswordDSPCH,
  resetPasswordData,
  resetPasswordLoad,
}) => {
  const { token } = useParams();

  const alert = useAlert();

  const resetPasswordDatasRef = useRef(resetPasswordData);

  let navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }, 100);
    fixedHeader();
  }, []);

  useEffect(() => {
    if (resetPasswordData !== resetPasswordDatasRef.current) {
      if (resetPasswordData?.success === true) {
        alert.success(
          <div style={{ textTransform: "initial" }}>
            {resetPasswordData?.message}
          </div>
        );
        navigate("/sign-in");
      } else {
        alert.error(
          <div style={{ textTransform: "initial" }}>
            {resetPasswordData?.data}
          </div>
        );
      }
    }
  }, [resetPasswordData, resetPasswordDatasRef]);

  const onSubmit = (fields) => {
    resetPasswordDSPCH({
      password: fields.password,
      token: token,
    });
  };

  return (
    <div>
      <Helmet>
        <title>Reset Password - Object Magazine</title>
        <meta name="description" content={""} />
      </Helmet>
      <Header />
      {/* <!--============================== modal Start ==============================--> */}
      <main id="main">
        <div className="content-container">
          <div className="container">
            <div className="row">
              <div className="col-md-6 offset-md-3">
                <div className="cc-content-box position-relative">
                  <div className="login-form-modal w-100">
                    <h4>Reset Password</h4>
                    <div className="member-form">
                      <Formik
                        initialValues={{
                          password: "",
                          confirmPassword: "",
                        }}
                        validationSchema={Yup.object().shape({
                          password: Yup.string()
                            .min(8, "Password must be at least 8 characters")
                            .required("Password is required"),
                          confirmPassword: Yup.string().when("password", {
                            is: (val) => (val && val.length > 0 ? true : false),
                            then: Yup.string().oneOf(
                              [Yup.ref("password")],
                              "Both password need to be the same"
                            ),
                          }),
                        })}
                        onSubmit={(fields, { resetForm }) => {
                          resetForm();
                          onSubmit(fields);
                        }}
                      >
                        {({
                          errors,
                          touched,
                          setFieldValue,
                          values,
                          handleSubmit,
                          isSubmitting,
                        }) => (
                          <form onSubmit={handleSubmit}>
                            <div className="form-group">
                              <input
                                type="password"
                                className="form-control"
                                id="password"
                                placeholder="Password"
                                value={values.password}
                                onChange={(e) =>
                                  setFieldValue("password", e.target.value)
                                }
                              />
                            </div>
                            {touched.password && errors.password && (
                              <div
                                style={{
                                  textAlign: "left",
                                  color: "red",
                                  marginBottom: 10,
                                  fontSize: 12,
                                }}
                              >
                                {errors.password}
                              </div>
                            )}
                            <div className="form-group">
                              <input
                                type="password"
                                className="form-control"
                                id="password"
                                placeholder="Confirm Password"
                                value={values.confirmPassword}
                                onChange={(e) =>
                                  setFieldValue(
                                    "confirmPassword",
                                    e.target.value
                                  )
                                }
                              />
                            </div>
                            {touched.confirmPassword &&
                              errors.confirmPassword && (
                                <div
                                  style={{
                                    textAlign: "left",
                                    color: "red",
                                    marginBottom: 10,
                                    fontSize: 12,
                                  }}
                                >
                                  {errors.confirmPassword}
                                </div>
                              )}
                            <div className="form-group">
                              <button
                                type="submit"
                                disabled={isSubmitting}
                                className="btn  btn-default"
                              >
                                {resetPasswordLoad ? "Loading..." : "Submit"}
                              </button>
                            </div>
                          </form>
                        )}
                      </Formik>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
      {/* <!--============================== modal End ==============================--> */}
    </div>
  );
};

const mapStateToProps = (state) => ({
  resetPasswordData: state.resetPassword.data,
  resetPasswordLoad: state.resetPassword.load,
});

const mapDispatchToProps = (dispatch) => ({
  resetPasswordDSPCH: (data) => {
    dispatch(ActionCreators.resetPassword(data));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
