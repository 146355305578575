import Config from '../values/Strings/Config';

export const googleLogin = ({token}) => {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({token})
  }
  let url = Config.GOOGLE_SIGNIN_URL;
  return fetch(url, requestOptions)
    .then(res => res.json())
    .then(res => res)
}