import Config from "../values/Strings/Config";

export const getCrossword = ({ sort_by, nextPageUrl }) => {
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  };

  let url = nextPageUrl? nextPageUrl: sort_by
    ? Config.GET_CROSSWORD_URL + `?sort_by=${sort_by}`
    : Config.GET_CROSSWORD_URL;

    return fetch(url, requestOptions)
    .then((res) => res.json())
    .then((res) => res);
};
