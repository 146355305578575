import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { Col2Header, Footer, Header, Loading } from "../components";
import { ActionCreators } from "../actions";
import { connect } from "react-redux";
import headerShapeRight from "../assets/images/header-shape-right.svg";
import headerShapeLeft from "../assets/images/header-shape-left.svg";
import { fixedHeader } from "../values/utils";

const DeliveryShipping = ({
  deliveryAndShippingDSPCH,
  deliveryAndShippingData,
  deliveryAndShippingLoad,
}) => {
  useEffect(() => {
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }, 100);
    fixedHeader();
    getDeliveryAndShipping();
  }, []);

  const getDeliveryAndShipping = () => {
    deliveryAndShippingDSPCH({
      slug: "delivery-and-shipping",
    });
  };

  return (
    <div>
      <Helmet>
        <title>
          {deliveryAndShippingData?.success === true &&
            deliveryAndShippingData?.data?.meta_title}
        </title>
        <meta
          name="description"
          content={
            deliveryAndShippingData?.success === true &&
            deliveryAndShippingData?.data?.meta_description
          }
        />
      </Helmet>

      <Header />

      {/* <!--============================== Main Start ==============================--> */}
      <main id="main">
        {/* <!--============================== Content Start ==============================--> */}
        <Col2Header />
        {/* <!--============================== Content End ==============================--> */}
        {/* <!--============================== Content Start ==============================--> */}
        {deliveryAndShippingLoad ? (
          <Loading type={"spin"} color={"#000"} />
        ) : (
          <div className="content-container">
            <div className="container">
              <div className="row">
                <div className="col-lg-10 offset-lg-1">
                  <div
                    className="block-text text-center"
                    dangerouslySetInnerHTML={{
                      __html: deliveryAndShippingData?.data?.description,
                    }}
                  ></div>
                </div>
              </div>
            </div>
          </div>
        )}
        {/* <!--============================== Content End ==============================--> */}
        {/* <!--============================== Content Start ==============================--> */}
        <div className="content-container">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="end-section">
                  <h5>
                    <span className="left">
                      <img src={headerShapeRight} alt="" />
                    </span>
                    end
                    <span className="right">
                      <img src={headerShapeLeft} alt="" />
                    </span>
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!--============================== Content End ==============================--> */}
      </main>
      {/* <!--============================== Main End ==============================--> */}
      {/* <!--============================== Footer Start ==============================--> */}
      <Footer />
      {/* <!--============================== Footer End ==============================--> */}
    </div>
  );
};

const mapStateToProps = (state) => ({
  deliveryAndShippingData: state.cmsFooter.data,
  deliveryAndShippingLoad: state.cmsFooter.load,
});

const mapDispatchToProps = (dispatch) => ({
  deliveryAndShippingDSPCH: (data) => {
    dispatch(ActionCreators.cmsFooter(data));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(DeliveryShipping);
