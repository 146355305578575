import { LOGIN_USER, LOGIN_USER_ERROR, LOGIN_USER_SUCCESS, LOGOUT } from './type'

export const loginUser = (data) => ({
  type: LOGIN_USER,
  data
})

export const loginUserSuccess = (data) => ({
  type: LOGIN_USER_SUCCESS,
  data
})

export const loginUsersFailure = (error) => ({
  type: LOGIN_USER_ERROR,
  error
})

export const logoutUser = () => ({
  type: LOGOUT
})

