import { PROFILE_UPDATE, PROFILE_UPDATE_SUCCESS, PROFILE_UPDATE_ERROR } from './type'

export const profileUpdate = (data) => ({
  type: PROFILE_UPDATE,
  data
})

export const profileUpdateSuccess = (data) => ({
  type: PROFILE_UPDATE_SUCCESS,
  data
})

export const profileUpdateFailure = (error) => ({
  type: PROFILE_UPDATE_ERROR,
  error
})

