import { takeEvery, call, put } from 'redux-saga/effects'
import { FAQ } from '../actions/type'
import { faq } from '../services/faq.api'
import { ActionCreators } from '../actions'

export const watchGetFaq = function* () {    
  yield takeEvery(FAQ, workerGetFaq)
}

function* workerGetFaq(action) {
  try {   
    const res = yield call(faq, action.data);
    yield put(ActionCreators.faqSuccess(res))
  } catch (e){
    yield put(ActionCreators.faqFailure(e))
  }
}
