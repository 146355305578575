import React, { useEffect, useState, useRef } from "react";
import { Footer, Header, Loading } from "../components";
import { Formik } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import Config from "../values/Strings/Config";
import { useAlert } from "react-alert";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { ActionCreators } from "../actions";
import { convertDateFormate, fixedHeader, isValidUrl } from "../values/utils";
import moment from "moment";
import menuIcon8 from "../assets/images/menu-icon8.svg";

const NewsLetterEnvironment = ({
  userData,
  getIndividualSubjectDSPCH,
  IndividualSubjectData,
  IndividualSubjectLoad,
}) => {
  const alert = useAlert();
  const [articleList, setArticleList] = useState([]);
  const [sort_by, setSortBy] = useState(0);
  const [newsletterLoading, setNewsLetterLoading] = useState("");

  const IndividualSubjectDataRef = useRef(IndividualSubjectData);

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }, 100);
    fixedHeader();
    getArticle();
  }, []);

  useEffect(() => {
    if (IndividualSubjectData !== IndividualSubjectDataRef.current) {
      if (
        IndividualSubjectData?.success === true &&
        IndividualSubjectData?.data?.articles
      ) {
        setArticleList((articleList) => [
          ...articleList,
          ...IndividualSubjectData?.data?.articles?.data,
        ]);
      } else {
        setArticleList([]);
      }
    }
  }, [IndividualSubjectData, IndividualSubjectDataRef]);

  const getArticle = () => {
    getIndividualSubjectDSPCH({ subjectId: "environment" });
  };

  const onSubmit = (val) => {
    let auth_obj = {
      fullname: userData?.data?.user?.name,
      email: val.email,
      subscription_type: "weekly",
    };
    let without_auth_obj = {
      email: val.email,
      subscription_type: "weekly",
    };
    setNewsLetterLoading(true);
    let final_obj = userData?.success ? auth_obj : without_auth_obj;
    fetch(Config.NEWSLETTER_SUBSCRIPTION_URL, {
      method: "POST",
      body: JSON.stringify(final_obj),
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => res.json())
      .then((res) => {
        setNewsLetterLoading(false);
        alert.success(
          <div style={{ textTransform: "initial" }}>{res?.data?.message}</div>
        );
      })
      .catch((err) => {
        setNewsLetterLoading(false);
        alert.success(
          <div style={{ textTransform: "initial" }}>{err?.message}</div>
        );
      });
  };

  const onHandleLoadMore = (nextPageUrl) => {
    getIndividualSubjectDSPCH({
      subjectId: "environment",
      nextPageUrl: nextPageUrl,
    });
  };

  const handleSortBy = (event) => {
    setArticleList([]);
    setSortBy(event.target.value);
    getIndividualSubjectDSPCH({
      subjectId: "environment",
      sort_by: event.target.value,
    });
  };

  return (
    <div>
      <Helmet>
        <title>Newletter Environment - Object magazine</title>
        <meta name="description" content={""} />
      </Helmet>
      <Header />
      <main id="main">
        {/* <!--============================== container Start ==============================--> */}
        <div className="content-container pb-0">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <ul className="newsletter-list">
                  <li className="newsletter-item">
                    <div className="newsletter-box d-flex flex-wrap bg-muted">
                      <div className="newsletter-left">
                        <div className="newsletter-icon">
                          <img src={menuIcon8} alt="" />
                        </div>
                        <div className="newsletter-cat">Environment</div>
                      </div>
                      <div className="newsletter-mid">
                        <div className="newsletter-title">
                          <h3>HOT ROCK</h3>
                        </div>
                        <div className="newsletter-weekend">
                          WEEKDAYS ON WEDNESDAY
                        </div>
                      </div>
                      <div className="newsletter-right">
                        <div className="newsletter-text">
                          <p>
                            {" "}
                            You can activate a free 14-day trial to unlock
                            access to the latest edition and the next two
                            editions of this newsletter.{" "}
                          </p>
                        </div>
                        <div className="newsletter-link">
                          <Formik
                            initialValues={{
                              email: "",
                            }}
                            validationSchema={Yup.object().shape({
                              email: Yup.string()
                                .email("Email is invalid")
                                .required("Email is required"),
                            })}
                            onSubmit={(fields, { resetForm }) => {
                              resetForm();
                              onSubmit(fields);
                            }}
                          >
                            {({
                              errors,
                              touched,
                              setFieldValue,
                              values,
                              handleSubmit,
                              isSubmitting,
                            }) => (
                              <form onSubmit={handleSubmit}>
                                <div className="newsletter-form">
                                  <input
                                    type="email"
                                    className="form-control"
                                    id="email"
                                    placeholder="Email Address"
                                    value={values.email}
                                    onChange={(e) =>
                                      setFieldValue("email", e.target.value)
                                    }
                                  />
                                  <button
                                    disabled={isSubmitting}
                                    type="submit"
                                    className="btn btn-default "
                                  >
                                    {newsletterLoading
                                      ? "Loading..."
                                      : "SIGN UP"}
                                  </button>
                                </div>
                                {touched.email && errors.email && (
                                  <div
                                    style={{
                                      textAlign: "left",
                                      color: "red",
                                      fontSize: 12,
                                      backgroundColor: "transparent",
                                    }}
                                  >
                                    {errors.email}
                                  </div>
                                )}
                              </form>
                            )}
                          </Formik>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        {/* <!--============================== Content End ==============================--> */}
        {/* <!--============================== Content Start ==============================--> */}
        <div className="content-container article-container">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="filter-content d-flex flex-wrap ">
                  <h5>PREVIOUS EDITIONS OF HOT ROCK</h5>
                  {articleList?.length > 0 && (
                    <div className="filter-box d-flex">
                      <span>Sort by: </span>
                      <select value={sort_by} onChange={handleSortBy}>
                        <option value="">Select</option>
                        <option value="newest">Newest</option>
                        <option value="oldest">Oldest</option>
                      </select>
                    </div>
                  )}
                </div>
                <ul className="article-list">
                  {articleList?.length > 0 &&
                    articleList?.map((item, ind) => (
                      <li className="article-item" key={ind}>
                        <div className="article-box d-flex flex-wrap">
                          <div className="article-text">
                            <h3>
                              <Link to={"/article/" + item?.slug}>
                                {item?.title}
                              </Link>
                            </h3>
                            <p>{item?.sub_title}</p>
                            <div className="post-by">
                              By <span>{item?.author_name}</span>
                            </div>
                            <div
                              className="post-date"
                              style={{ textTransform: "uppercase" }}
                            >
                              {convertDateFormate(item?.publish_date)}
                            </div>
                          </div>
                          <div className="article-thumbnail">
                            <Link to={"/article/" + item?.slug}>
                              {isValidUrl(item?.image) ? (
                                <img src={item?.image} alt="" />
                              ) : (
                                <img
                                  src={Config.IMG_URL + item?.image}
                                  alt=""
                                />
                              )}
                            </Link>
                          </div>
                        </div>
                      </li>
                    ))}
                </ul>
                {IndividualSubjectLoad ? (
                  <Loading type={"spin"} color={"#000"} />
                ) : IndividualSubjectData?.data?.articles?.next_page_url ? (
                  <div className="load-more text-center">
                    <a
                      href="javascript:void(0)"
                      onClick={() =>
                        onHandleLoadMore(
                          IndividualSubjectData?.data?.articles?.next_page_url
                        )
                      }
                    >
                      Load more result
                    </a>
                  </div>
                ) : (
                  <>
                    {articleList.length === 0 && (
                      <p className="text-center my-5">No article found</p>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        {/* <!--============================== Content End ==============================--> */}
      </main>
      <Footer />
    </div>
  );
};

const mapStateToProps = (state) => ({
  userData: state.login.user,
  IndividualSubjectData: state.getIndividualSubject.data,
  IndividualSubjectLoad: state.getIndividualSubject.load,
});

const mapDispatchToProps = (dispatch) => ({
  getIndividualSubjectDSPCH: (data) => {
    dispatch(ActionCreators.getIndividualSubject(data));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewsLetterEnvironment);
