import {
  CMS_FOOTER,
  CMS_FOOTER_SUCCESS,
  CMS_FOOTER_ERROR,
} from "../actions/type";

const defaultState = {
  load: false,
  data: {},
  error: null,
};

export const cmsFooter = (state = defaultState, action) => {
  const response = action.data;
  switch (action.type) {
    case CMS_FOOTER:
      return {
        ...state,
        load: true,
      };
    case CMS_FOOTER_SUCCESS:
      return {
        ...state,
        data: response,
        load: false,
      };
    case CMS_FOOTER_ERROR:
      return {
        ...state,
        error: response,
        load: false,
      };
    default:
      return state;
  }
};
