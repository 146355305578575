export function convertDataToSlides(articleData) {
    const articleContent = articleData?.data?.description || ""; // Safeguard for undefined
    const articleContentArray = articleContent.split(/(?=<p>)/);
    const articleContentArrayNew = [];

    for (let i = 0; i < articleContentArray.length; i++) {
        const articleContents = {
            image: "",
            description: "", // Correct spelling
        };

        if (articleContentArray[i] !== "") {
            // Check if current element has an image
            if (articleContentArray[i].includes("<img")) {
                const imageMatch = articleContentArray[i].match(/src="([^"]+)"/);
                if (imageMatch) {
                    articleContents.image = imageMatch[1]; // Extract image URL
                }

                // Check if the next element exists and doesn't have an image
                if (i + 1 < articleContentArray.length && !articleContentArray[i + 1].includes("<img")) {
                    const descriptionMatch = articleContentArray[i + 1].replace(/<p>|<\/p>/g, ""); // Remove <p> tags
                    articleContents.description = descriptionMatch; // Assign description from next element
                    i++; // Skip the next array element since we've used it
                }
            } else {
                const descriptionContact = articleContentArray[i].replace(/<p>|<\/p>/g, ""); // Remove <p> tags
                articleContents.description = descriptionContact; // Non-image content
            }

            // Only push if image or description is present
            if (articleContents.image || articleContents.description) {
                articleContentArrayNew.push(articleContents);
            }
        }
    }

    return articleContentArrayNew;
}
