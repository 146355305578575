import { REGISTER_USER, REGISTER_USER_SUCCESS, REGISTER_USER_ERROR } from './type'

export const registerUser = (data) => ({
  type: REGISTER_USER,
  data
})

export const registerUserSuccess = (data) => ({
  type: REGISTER_USER_SUCCESS,
  data
})

export const registerUserFailure = (error) => ({
  type: REGISTER_USER_ERROR,
  error
})


