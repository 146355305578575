import { takeEvery, call, put } from 'redux-saga/effects'
import { AUTHOR } from '../actions/type'
import { getAuthor } from '../services/getAuthor.api'
import { ActionCreators } from '../actions'

export const watchAuthor = function* () {    
  yield takeEvery(AUTHOR, workerAuthor)
}

function* workerAuthor(action) {
  try {          
    const res = yield call(getAuthor, action.data);
    yield put(ActionCreators.authorSuccess(res));
  } catch (e){
    yield put(ActionCreators.authorFailure(e));
  }
}
