import { GET_INDIVIDUAL_AUTHOR, GET_INDIVIDUAL_AUTHOR_SUCCESS, GET_INDIVIDUAL_AUTHOR_ERROR } from './type'

export const getIndividualAuthor = (data) => ({
  type: GET_INDIVIDUAL_AUTHOR,
  data
})

export const getIndividualAuthorSuccess = (data) => ({
  type: GET_INDIVIDUAL_AUTHOR_SUCCESS,
  data
})

export const getIndividualAuthorFailure = (error) => ({
  type: GET_INDIVIDUAL_AUTHOR_ERROR,
  error
})


