import { takeEvery, call, put } from 'redux-saga/effects'
import { SAVE_ARTICLE } from '../actions/type'
import { saveArticle } from '../services/saveArticle.api'
import { ActionCreators } from '../actions'

export const watchSaveArticle = function* () {    
  yield takeEvery(SAVE_ARTICLE, workerSaveArticle)
}

function* workerSaveArticle(action) {
  try {   
    const res = yield call(saveArticle, action.data);
    yield put(ActionCreators.saveArticleSuccess(res))
  } catch (e){
    yield put(ActionCreators.saveArticleFailure(e))
  }
}
