import Config from "../values/Strings/Config";

export const getArchiveYear = () => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  };
  let url = Config.GET_ARCHIVE_YEAR_URL;
  return fetch(url, requestOptions)
    .then((res) => res.json())
    .then((res) => res);
};

export const getArchiveData = ({ year, month, nextPageUrl }) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      year,
      month,
    }),
  };
  let url = nextPageUrl ? nextPageUrl : Config.GET_ARCHIVE_DATA_URL;
  return fetch(url, requestOptions)
    .then((res) => res.json())
    .then((res) => res);
};
