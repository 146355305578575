import { useState } from "react";
import "../assets/css/paymentsuccess.css";

import thincross from "../assets/icons/thincross.svg";
import objectlogowithframe from "../assets/logos/objectlogowithframe.svg";


const PaymentSuccess = () => {
    const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);

    const toggleLoginModal = () => {
        //close and navigate to home
        setIsLoginModalOpen(!isLoginModalOpen);
        document.getElementById("popUp").style.display = "none";
        window.location.href = "/";
        

    };

    return (
        <div className="popUp" id="popUp">
            <div className="paymentsuccpopUpContent">
                {/**LOGO */}
                <div className="logo">
                    <img src={objectlogowithframe} alt="logo" />
                </div>
                <div className="text-body">
                    <div className="popUpHeader">
                        <button className="close-button" onClick={toggleLoginModal}>
                            <img src={thincross}
                                alt="close" className="thin-cross" />
                        </button> {/* Close button */}
                        <h2>Congratulations!</h2>

                    </div>
                    <div className="popUpBody">
                        <p>
                            Your subscription has been confirmed. We hope you have
                            a great journey with us.
                        </p>
                    </div>
                </div>

            </div>
        </div>
    );
}


export default PaymentSuccess;