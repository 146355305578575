import Config from "../values/Strings/Config";

export const getSavedArticle = ({ token, nextPageUrl, sort_by }) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  };

  let url = nextPageUrl
    ? nextPageUrl
    : sort_by
    ? Config.GET_SAVED_ARTICLE_URL + `?sort_by=${sort_by}`
    : Config.GET_SAVED_ARTICLE_URL;

  return fetch(url, requestOptions)
    .then((res) => res.json())
    .then((res) => res);
};
