import { GOOGLE_LOGIN, GOOGLE_LOGIN_SUCCESS, GOOGLE_LOGIN_ERROR, GOOGLE_LOGOUT } from './type'

export const googleLogin = (data) => ({
  type: GOOGLE_LOGIN,
  data
})

export const googleLoginSuccess = (data) => ({
  type: GOOGLE_LOGIN_SUCCESS,
  data
})

export const googleLoginFailure = (error) => ({
  type: GOOGLE_LOGIN_ERROR,
  error
})

export const googleLogout = () => ({
  type: GOOGLE_LOGOUT
})

