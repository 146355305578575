import { takeEvery, call, put } from 'redux-saga/effects'
import { GET_INDIVIDUAL_SUBJECT } from '../actions/type'
import { getIndividualSubject } from '../services/getIndividualSubject.api'
import { ActionCreators } from '../actions'

export const watchGetIndividualSubject = function* () {    
  yield takeEvery(GET_INDIVIDUAL_SUBJECT, workerGetIndividualSubject)
}

function* workerGetIndividualSubject(action) {
  try { 
    const res = yield call(getIndividualSubject, action.data);
    yield put(ActionCreators.getIndividualSubjectSuccess(res))
  } catch (e){
    yield put(ActionCreators.getIndividualSubjectFailure(e))
  }
}
