import Config from "../values/Strings/Config";

export const cancelSubscription = ({ token, stripe_subscription_id }) => {

  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token
    },
    body: JSON.stringify({
      stripe_subscription_id
    }),
  };

  let url = Config.CANCEL_SUBSCRIPTION_URL;

  return fetch(url, requestOptions)
    .then((res) => res.json())
    .then((res) => res);
};
