import React, { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { Link, useNavigate } from "react-router-dom";
import { Footer, Header, Loading } from "../components";
import Config from "../values/Strings/Config";
import { connect } from "react-redux";
import { ActionCreators } from "../actions";
import { useAlert } from "react-alert";
import { GoogleLogout } from "react-google-login";
import { gapi } from "gapi-script";
import moment from "moment";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { convertDateFormate, fixedHeader, isValidUrl } from "../values/utils";
import profileImg from "../assets/images/profile.svg";
import deleteImg from "../assets/images/delete.svg";
import goodIcon from "../assets/images/good-icon.png";
import headerShapeRight from "../assets/images/header-shape-right.svg";
import headerShapeLeft from "../assets/images/header-shape-left.svg";

const Profile = ({
  logoutDSPCH,
  userData,
  profileDSPCH,
  profileData,
  getSavedArticleDSPCH,
  getSavedArticleData,
  getSavedArticleLoad,
  removeArticleDSPCH,
  removeSavedArticleData,
}) => {
  const alert = useAlert();
  const [sortBy, setSortBy] = useState(0);
  const [articleList, setArticleList] = useState(() => []);

  let navigate = useNavigate();
  const profileDataRef = useRef(profileData);
  const getSavedArticleDataRef = useRef(getSavedArticleData);
  const removeSavedArticleDataRef = useRef(removeSavedArticleData);

  useEffect(() => {
    if (getSavedArticleData !== getSavedArticleDataRef.current) {
      if (getSavedArticleData?.success === true) {
        setArticleList((articleList) => [
          ...articleList,
          ...getSavedArticleData?.data?.data,
        ]);
      } else {
        setArticleList([]);
      }
    }
  }, [getSavedArticleData, getSavedArticleDataRef]);

  useEffect(() => {
    if (removeSavedArticleData !== removeSavedArticleDataRef.current) {
      if (removeSavedArticleData?.success === true) {
        alert.success(
          <div style={{ textTransform: "initial" }}>
            {removeSavedArticleData?.message}
          </div>
        );
        setArticleList([]);
        getSavedArticle();
      } else {
        setArticleList([]);
      }
    }
  }, [removeSavedArticleData, removeSavedArticleDataRef]);

  useEffect(() => {
    if (profileData !== profileDataRef.current) {
      if (profileData?.success === false) {
        logoutDSPCH();
        navigate("/");
      }
    }
  }, [profileData, profileDataRef]);

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }, 100);
    fixedHeader();
    function start() {
      gapi.client.init({
        clientId: Config.GOOGLE_CLIENT_ID,
        scope: "email",
      });
    }
    gapi.load("client:auth2", start);
    getProfile();
    getSavedArticle();
  }, []);

  const getProfile = () => {
    if (userData?.success === true) {
      profileDSPCH({
        token: userData?.data?.token,
      });
    } else {
      navigate("/");
      logoutDSPCH();
    }
  };

  const getSavedArticle = () => {
    if (userData?.success === true) {
      getSavedArticleDSPCH({
        token: userData?.data?.token,
      });
    } else {
      navigate("/");
      logoutDSPCH();
    }
  };

  const logout = () => {
    navigate("/");
    logoutDSPCH();
    alert.info(
      <div style={{ textTransform: "initial" }}>
        You have successfully signed out!
      </div>
    );
  };

  const onSuccess = () => {
    // const auth2 = gapi.auth2.getAuthInstance();

    // if (auth2 != null) {
    //   auth2.signOut().then(auth2.disconnect().then(logout()));
    // }
    const auth2 = gapi.auth2.getAuthInstance();
    if (auth2 != null) {
      auth2.signOut().then(auth2.disconnect().then(logout()));
    }
  };

  const onHandleLoadMore = (nextPageUrl) => {
    getSavedArticleDSPCH({
      token: userData?.data?.token,
      nextPageUrl: nextPageUrl,
    });
  };

  const handleSortBy = (event) => {
    setArticleList([]); // when click sortby empty state
    setSortBy(event.target.value);
    getSavedArticleDSPCH({
      token: userData?.data?.token,
      sort_by: event.target.value,
    });
  };

  const onHandleRemove = (article_id) => {
    confirmAlert({
      message: "Are you sure want to delete this article?",
      overlayClassName: "overlay-custom-class-name",
      buttons: [
        {
          label: "Yes",
          onClick: () =>
            removeArticleDSPCH({
              article_id: article_id,
              token: userData?.data?.token,
            }),
        },
        {
          label: "No",
          onClick: () => console.log("Click No"),
        },
      ],
    });
  };

  const onFailure = (error) => {
    alert.error(
      <div style={{ textTransform: "initial" }}>{error?.message}</div>
    );
  };

  return (
    <div>
      <Helmet>
        <title>Profile - Object magazine</title>
        <meta name="description" content={""} />
      </Helmet>

      <Header />

      <main id="main">
        {/* <!--============================== Content Start ==============================--> */}
        <div className="content-container profile-container">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="profile-content d-flex flex-wrap">
                  <div className="profile-img">
                    <img
                      src={
                        profileData?.data?.user?.image
                          ? `${Config.USER_IMG_URL}${profileData?.data?.user?.image}`
                          : profileImg
                      }
                      alt="userphoto"
                    />
                  </div>
                  <div className="profile-deatail">
                    <h6>
                      PROFILE DETAILS
                      <span>
                        <Link to="/profile-edit" className="read-more-btn">
                          Edit
                        </Link>
                      </span>
                    </h6>
                    <h3>{profileData?.data?.user?.name}</h3>
                    <p>{profileData?.data?.user?.email}</p>
                    {userData?.success === true &&
                    userData?.data?.hasOwnProperty("google_login") ? (
                      <GoogleLogout
                        clientId={Config.GOOGLE_CLIENT_ID}
                        render={(renderProps) => (
                          <button
                            className="btn btn-default"
                            onClick={renderProps.onClick}
                          >
                            sign out
                          </button>
                        )}
                        buttonText="Logout"
                        onLogoutSuccess={onSuccess}
                        onLogoutFailure={onFailure}
                      ></GoogleLogout>
                    ) : (
                      <button
                        className="btn btn-default"
                        onClick={() => logout()}
                      >
                        sign out
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!--============================== Content End ==============================--> */}
        {/* <!--============================== Content Start ==============================--> */}
        <div className="content-container p-0">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="subscription-tabs-content">
                  <ul className="subscription-tabs d-flex justify-content-between">
                    <li className="active">
                      <Link to="/profile" className="subscription-tabs-link">
                        SAVED ARTICLES
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={
                          profileData?.success === true &&
                          profileData?.data?.subscription
                            ? "/subscription-active"
                            : "/subscription-inactive"
                        }
                        className="subscription-tabs-link"
                      >
                        SUBSCRIPTION DETAILS
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!--============================== Content End ==============================-->  */}
        {/* <!--============================== Content Start ==============================--> */}
        <div className="content-container article-container pt-0 pb-0">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="filter-content d-flex flex-wrap ">
                  <h5>
                    {" "}
                    {moment(new Date()).format("dddd") +
                      ", " +
                      moment(new Date()).format("D MMM, YYYY")}
                  </h5>
                  {articleList.length > 0 && (
                    <div className="filter-box d-flex">
                      <span>Sort by: </span>
                      <select value={sortBy} onChange={handleSortBy}>
                        <option value="">Select</option>

                        <option value="newest">Newest</option>
                        <option value="oldest">Oldest</option>
                      </select>
                    </div>
                  )}
                </div>
                <ul className="article-list">
                  {articleList?.map((item, ind) => (
                    <li className="article-item" key={ind}>
                      <div className="article-box d-flex flex-wrap">
                        <div className="article-text">
                          <ol className="breadcrumb">
                            <li className="breadcrumb-item">
                              {item?.category_name}
                            </li>
                            <li
                              className="breadcrumb-item active"
                              aria-current="page"
                            >
                              {item?.subject_name}
                            </li>
                            <li
                              className="breadcrumb-item active"
                              aria-current="page"
                            >
                              <span
                                className="add-btn"
                                style={{
                                  cursor: "pointer",
                                }}
                                onClick={() => onHandleRemove(item?.id)}
                              >
                                <img src={deleteImg} alt="" />
                              </span>
                            </li>
                          </ol>
                          <h3>
                            <Link to={"/article/" + item?.slug}>
                              {item?.title}
                            </Link>
                          </h3>
                          <p>{item?.sub_title}</p>
                          <div className="post-by">
                            By <span>{item?.author_name}</span>
                          </div>
                          <div
                            className="post-date"
                            style={{ textTransform: "uppercase" }}
                          >
                            {convertDateFormate(item?.publish_date)}
                            <span className="add-btn">
                              <img src={goodIcon} alt="" />
                            </span>
                          </div>
                        </div>
                        <div className="article-thumbnail">
                          <Link to={"/article/" + item?.slug}>
                            {isValidUrl(item?.image) ? (
                              <img src={item?.image} alt="" />
                            ) : (
                              <img src={Config.IMG_URL + item?.image} alt="" />
                            )}
                          </Link>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
                {getSavedArticleLoad ? (
                  <Loading type={"spin"} color={"#000"} />
                ) : getSavedArticleData?.data?.next_page_url ? (
                  <div className="load-more text-center">
                    <a
                      href="javascript:void(0)"
                      onClick={() =>
                        onHandleLoadMore(
                          getSavedArticleData?.data["next_page_url"]
                        )
                      }
                    >
                      Load more results
                    </a>
                  </div>
                ) : (
                  <>
                    {articleList.length === 0 && (
                      <p className="text-center my-5">No article found</p>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        {/* <!--============================== Content End ==============================--> */}
        {/* <!--============================== Content Start ==============================--> */}
        <div className="content-container">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="end-section">
                  <h5>
                    <span className="left">
                      <img src={headerShapeRight} alt="" />
                    </span>
                    end
                    <span className="right">
                      <img src={headerShapeLeft} alt="" />
                    </span>
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!--============================== Content End ==============================--> */}
      </main>
      {/* <!--============================== Main End ==============================--> */}
      {/* <!--============================== Footer Start ==============================--> */}
      <Footer />
      {/* <!--============================== Footer End ==============================--> */}
    </div>
  );
};

const mapStateToProps = (state) => ({
  userData: state.login.user,
  profileData: state.profile.data,
  getSavedArticleData: state.getSavedArticle.data,
  getSavedArticleLoad: state.getSavedArticle.load,
  removeSavedArticleData: state.removeSavedArticle.data,
});

const mapDispatchToProps = (dispatch) => ({
  logoutDSPCH: () => {
    dispatch(ActionCreators.logoutUser());
  },
  profileDSPCH: (data) => {
    dispatch(ActionCreators.profile(data));
  },
  getSavedArticleDSPCH: (data) => {
    dispatch(ActionCreators.getSavedArticle(data));
  },
  removeArticleDSPCH: (data) => {
    dispatch(ActionCreators.removeSavedArticle(data));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
