import { REGISTER_USER, REGISTER_USER_SUCCESS, REGISTER_USER_ERROR } from "../actions/type"

const defaultState = {
  load: false,
  user: {},
  error: null,
}

export const register = (state = defaultState, action) => {
  const response = action.data;
  switch (action.type) {
    case REGISTER_USER:
      return {
        ...state,
        load: true
      }
    case REGISTER_USER_SUCCESS:
      return { 
        ...state, 
        user: response,
        load: false
      }
    case REGISTER_USER_ERROR:
      return { 
        ...state, 
        error: response,
        load: false,
      }
    default:
      return state
  }
}


