import { RESET_PASSWORD, RESET_PASSWORD_SUCCESS, RESET_PASSWORD_ERROR } from './type'

export const resetPassword = (data) => ({
  type: RESET_PASSWORD,
  data
})

export const resetPasswordSuccess = (data) => ({
  type: RESET_PASSWORD_SUCCESS,
  data
})

export const resetPasswordFailure = (error) => ({
  type: RESET_PASSWORD_ERROR,
  error
})


