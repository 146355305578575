import Config from '../values/Strings/Config';

export const loginUser = ({email, password}) => {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', "Accept": 'application/json' },
    body: JSON.stringify({email, password})
  }
  let url = Config.LOGIN_URL;
  return fetch(url, requestOptions)
    .then(res => res.json())
    .then(res => res)
}