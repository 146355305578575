import { takeEvery, call, put } from 'redux-saga/effects'
import { CANCEL_SUBSCRIPTION } from '../actions/type'
import { cancelSubscription } from '../services/cancelSubscription.api'
import { ActionCreators } from '../actions'

export const watchCancelSubscription = function* () {    
  yield takeEvery(CANCEL_SUBSCRIPTION, workerCancelSubscription)
}

function* workerCancelSubscription(action) {
  try {   
    const res = yield call(cancelSubscription, action.data);
    yield put(ActionCreators.cancelSubscriptionSuccess(res))
  } catch (e){
    yield put(ActionCreators.cancelSubscriptionFailure(e))
  }
}
