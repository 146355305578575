import { all } from "redux-saga/effects";
import { watchLogin } from "./login.sagas";
import { watchPageData } from "./pageData.sagas";
import { watchGetQuote } from "./getQuote.sagas";
import { watchGetQna } from "./getQna.sagas";
import { watchGetArticle } from "./getArticle.sagas";
import { watchRegister } from "./register.sagas";
import { watchAuthor } from "./author.sagas";
import { watchGetSubject } from "./getSubject.sagas";
import { watchProfile } from "./profile.sagas";
import { watchGetIndividualSubject } from "./getIndividualSubject.sagas";
import { watchGetIndividualAuthor } from "./getIndividualAuthor.sagas";
import { watchGoogleLogin } from "./googleLogin.sagas";
import { watchCompleteOrder } from "./completeOrder.sagas";
import { watchGetPlan } from "./plan.sagas";
import { watchGetProfileUpdate } from "./profileUpdate.sagas";
import { watchSearch } from "./search.sagas";
import { watchForgetPassword } from "./forgetPassword.sagas";
import { watchResetPassword } from "./resetPassword.sagas";
import { watchGetCmsFooter } from "./cmsFooter.sagas";
import { watchSaveArticle } from "./saveArticle.sagas";
import { watchGetCrossword } from "./getCrossword.sagas";
import { watchGetCrosswordSingle } from "./getCrosswordSingle.sagas";
import { watchGetSavedArticle } from "./getSavedArticle.sagas";
import { watchArchiveYear, watchArchiveData } from "./archive.sagas";
import { watchGetFaq } from "./faq.sagas";
import { watchRemoveSavedArticle } from "./removeSavedArticle.sagas";
import { watchCancelSubscription } from "./cancelSubscription.sagas";
import { watchGetAllVideoArticle } from "./getAllVideoArticle.sagas";

export default function* root() {
  yield all([
    watchLogin(),
    watchPageData(),
    watchGetQuote(),
    watchGetQna(),
    watchGetArticle(),
    watchRegister(),
    watchAuthor(),
    watchGetSubject(),
    watchProfile(),
    watchGetIndividualSubject(),
    watchGetIndividualAuthor(),
    watchGoogleLogin(),
    watchCompleteOrder(),
    watchGetPlan(),
    watchGetProfileUpdate(),
    watchSearch(),
    watchForgetPassword(),
    watchResetPassword(),
    watchGetCmsFooter(),
    watchSaveArticle(),
    watchGetCrossword(),
    watchGetCrosswordSingle(),
    watchGetSavedArticle(),
    watchArchiveYear(),
    watchArchiveData(),
    watchGetFaq(),
    watchRemoveSavedArticle(),
    watchCancelSubscription(),
    watchGetAllVideoArticle()
  ])
}