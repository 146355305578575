import { PROFILE, PROFILE_SUCCESS, PROFILE_ERROR } from './type'

export const profile = (data) => ({
  type: PROFILE,
  data
})

export const profileSuccess = (data) => ({
  type: PROFILE_SUCCESS,
  data
})

export const profileFailure = (error) => ({
  type: PROFILE_ERROR,
  error
})


