import {
  LOGIN_USER_ERROR,
  LOGIN_USER_SUCCESS,
  LOGIN_USER,
  LOGOUT,
  GOOGLE_LOGIN,
  GOOGLE_LOGIN_SUCCESS,
  GOOGLE_LOGIN_ERROR,
  GOOGLE_LOGOUT,
} from "../actions/type";

const defaultState = {
  load: false,
  user: {},
  error: null,
};

export const login = (state = defaultState, action) => {
  const response = action.data;
  switch (action.type) {
    case LOGIN_USER:
      return {
        ...state,
        load: true,
      };
    case LOGIN_USER_SUCCESS:
      return {
        ...state,
        user: response,
        load: false,
      };
    case LOGIN_USER_ERROR:
      return {
        ...state,
        error: response,
        load: false,
      };
    case LOGOUT:
      return defaultState;

    case GOOGLE_LOGIN:
      return {
        ...state,
        load: true,
      };
    case GOOGLE_LOGIN_SUCCESS:
      return {
        ...state,
        user: response,
        load: false,
      };
    case GOOGLE_LOGIN_ERROR:
      return {
        ...state,
        error: response,
        load: false,
      };
    case GOOGLE_LOGOUT:
      return {
        ...state,
        load: false,
        user: {},
        error: null
      };
    default:
      return state;
  }
};
