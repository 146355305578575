import React from "react";
import ReactLoading from "react-loading";

const Loading = ({ type, color }) => (
  <div className="content-container pb-10">
      <ReactLoading className="container" type={type} color={color} height={"8%"} width={"8%"} />
  </div>
);

export default Loading;
