import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { Footer, Header } from "../components";
import { ActionCreators } from "../actions";
import { connect } from "react-redux";
import { fixedHeader } from "../values/utils";

const FAQ = ({ faqDSPCH, faqData }) => {
  useEffect(() => {
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }, 100);
    fixedHeader();
    getFaq();
  }, []);

  const getFaq = () => {
    faqDSPCH();
  };

  return (
    <div>
      <Helmet>
        <title>FAQ - Object Magazine</title>
        <meta name="description" content="{}" />
      </Helmet>

      <Header />

      {/* <!--============================== Main Start ==============================--> */}
      <main id="main">
        {/* <!--============================== Content Start ==============================--> */}
        <div className="content-container less-pad pb-0">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="page-heading">
                  <h1>FREQUENTLY ASKED QUESTIONS</h1>
                </div>
                {faqData?.data?.map((item, ind) => (
                  <div className="subscription-intro pb-5" key={ind}>
                    <h4>{item?.title}</h4>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: item?.description,
                      }}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        {/* <!--============================== Content End ==============================--> */}
      </main>
      {/* <!--============================== Main End ==============================--> */}
      {/* <!--============================== Footer Start ==============================--> */}
      <Footer />
      {/* <!--============================== Footer End ==============================--> */}
    </div>
  );
};

const mapStateToProps = (state) => ({
  faqData: state.faq.data,
  faqLoad: state.faq.load,
});

const mapDispatchToProps = (dispatch) => ({
  faqDSPCH: (data) => {
    dispatch(ActionCreators.faq(data));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(FAQ);
