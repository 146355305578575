import { REMOVE_SAVEDARTICLE, REMOVE_SAVEDARTICLE_SUCCESS, REMOVE_SAVEDARTICLE_ERROR } from './type'

export const removeSavedArticle = (data) => ({
  type: REMOVE_SAVEDARTICLE,
  data
})

export const removeSavedArticleSuccess = (data) => ({
  type: REMOVE_SAVEDARTICLE_SUCCESS,
  data
})

export const removeSavedArticleFailure = (error) => ({
  type: REMOVE_SAVEDARTICLE_ERROR,
  error
})


