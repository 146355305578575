import { PLAN, PLAN_SUCCESS, PLAN_ERROR } from "../actions/type";

const defaultState = {
  load: false,
  data: {},
  error: null,
};

export const plan = (state = defaultState, action) => {
  const response = action.data;
  switch (action.type) {
    case PLAN:
      return {
        ...state,
        load: true,
      };
    case PLAN_SUCCESS:
      return {
        ...state,
        data: response,
        load: false,
      };
    case PLAN_ERROR:
      return {
        ...state,
        error: response,
        load: false,
      };
    default:
      return state;
  }
};
