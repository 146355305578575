import React, { useRef, useEffect } from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { ActionCreators } from "../actions";
import { Formik } from "formik";
import * as Yup from "yup";
import { Header, Footer } from "../components";
import { useAlert } from "react-alert";
import { Link } from "react-router-dom";
import { fixedHeader } from "../values/utils";

const ForgotPassword = ({
  forgetPasswordDSPCH,
  forgetPasswordData,
  forgetPasswordLoad,
}) => {
  const forgetPasswordDataRef = useRef(forgetPasswordData);
  const alert = useAlert();

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }, 100);
    fixedHeader();
  }, []);

  useEffect(() => {
    if (forgetPasswordData !== forgetPasswordDataRef.current) {
      alert.show(forgetPasswordData?.message);
    }
  }, [forgetPasswordData, forgetPasswordDataRef]);

  const onSubmit = (fields) => {
    forgetPasswordDSPCH({
      email: fields.email,
    });
  };

  return (
    <div>
      <Helmet>
        <title>Forgot Password - Object magazine</title>
        <meta name="description" content={""} />
      </Helmet>
      <Header />
      {/* <!--============================== modal Start ==============================--> */}
      <main id="main">
        <div className="content-container">
          <div className="container">
            <div className="row">
              <div className="col-md-6 offset-md-3">
                <div className="cc-content-box position-relative">
                  <div className="login-form-modal w-100">
                    <h4>Forgot Password</h4>
                    <div className="member-form">
                      <Formik
                        initialValues={{
                          email: "",
                        }}
                        validationSchema={Yup.object().shape({
                          email: Yup.string()
                            .email("Email is invalid")
                            .required("Email is required"),
                        })}
                        onSubmit={(fields, { resetForm }) => {
                          resetForm();
                          onSubmit(fields);
                        }}
                      >
                        {({
                          errors,
                          touched,
                          setFieldValue,
                          values,
                          handleSubmit,
                          isSubmitting,
                        }) => (
                          <form onSubmit={handleSubmit}>
                            <div className="form-group">
                              <input
                                type="email"
                                className="form-control "
                                id="email"
                                placeholder="Email Address"
                                value={values.email}
                                onChange={(e) =>
                                  setFieldValue("email", e.target.value)
                                }
                              />
                            </div>
                            {touched.email && errors.email && (
                              <div
                                style={{
                                  textAlign: "left",
                                  color: "red",
                                  marginBottom: 10,
                                  fontSize: 12,
                                }}
                              >
                                {errors.email}
                              </div>
                            )}

                            <div className="form-group">
                              <button
                                type="submit"
                                disabled={isSubmitting}
                                className="btn  btn-default"
                              >
                                {forgetPasswordLoad ? "Loading..." : "Submit"}
                              </button>
                            </div>
                          </form>
                        )}
                      </Formik>
                    </div>
                  </div>
                  <div className="forgot-pass-outer text-center">
                    <Link className="forgot-pass" to="/sign-in">
                      Sign In
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
      {/* <!--============================== modal End ==============================--> */}
    </div>
  );
};

const mapStateToProps = (state) => ({
  forgetPasswordData: state.forgetPassword.data,
  forgetPasswordLoad: state.forgetPassword.load,
});

const mapDispatchToProps = (dispatch) => ({
  forgetPasswordDSPCH: (data) => {
    dispatch(ActionCreators.forgetPassword(data));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
