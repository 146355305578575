import { takeEvery, call, put } from 'redux-saga/effects'
import { REMOVE_SAVEDARTICLE } from '../actions/type'
import { removeSavedArticle } from '../services/removeSavedArticle.api'
import { ActionCreators } from '../actions'

export const watchRemoveSavedArticle = function* () {    
  yield takeEvery(REMOVE_SAVEDARTICLE, workerRemoveSavedArticle)
}

function* workerRemoveSavedArticle(action) {
  try {   
    const res = yield call(removeSavedArticle, action.data);
    yield put(ActionCreators.removeSavedArticleSuccess(res))
  } catch (e){
    yield put(ActionCreators.removeSavedArticleFailure(e))
  }
}
