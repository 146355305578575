import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { Col1Header, Footer, Header, Loading } from "../components";
import Config from "../values/Strings/Config";
import { connect } from "react-redux";
import { ActionCreators } from "../actions";
import { Link } from "react-router-dom";
import objectLogo from "../assets/images/object-icon-logo.png";
import headerShapeRight from "../assets/images/header-shape-right.svg";
import headerShapeLeft from "../assets/images/header-shape-left.svg";
import { fixedHeader } from "../values/utils";

const AboutUs = ({
  authorDSPCH,
  authorData,
  aboutUsDSPCH,
  aboutUsData,
  aboutUsLoad,
}) => {
  useEffect(() => {
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }, 100);
    fixedHeader();
    getAuthor();
    getAboutUs();
  }, []);

  const getAuthor = () => {
    authorDSPCH();
  };

  const getAboutUs = () => {
    aboutUsDSPCH({
      slug: "about-us",
    });
  };

  return (
    <div>
      <Helmet>
        <title>
          {aboutUsData?.success === true && aboutUsData?.data?.meta_title}
        </title>
        <meta
          name="description"
          content={
            aboutUsData?.success === true && aboutUsData?.data?.meta_description
          }
        />
      </Helmet>
      <Header />
      {/* <!--============================== Main Start ==============================--> */}

      <main id="main">
        {/* <!--============================== Content Start ==============================--> */}
        <Col1Header />
        {/* <!--============================== Content End ==============================--> */}
        {/* <!--============================== Content Start ==============================--> */}
        {aboutUsLoad ? (
          <Loading type={"spin"} color={"#000"} />
        ) : (
          <>
            <div className="content-container about-container pb-0">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="about-logo">
                      <img src={objectLogo} alt="" />
                    </div>
                    <div
                      className="block-text"
                      dangerouslySetInnerHTML={{
                        __html: aboutUsData?.data?.description,
                      }}
                    ></div>
                  </div>
                </div>
              </div>
            </div>
            {/* <!--============================== Content End ==============================--> */}
            {/* <!--============================== Content Start ==============================--> */}
            <div className="content-container other-author-container">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="simple-heading">
                      <h4>AUTHORS</h4>
                    </div>

                    <ul className="other-author-list">
                      {authorData?.success === true &&
                        authorData?.data?.map((item, ind) => (
                          <li className="other-author-item" key={ind}>
                            <div className="other-author-box d-flex flex-wrap">
                              <div className="other-author-img">
                                <img
                                  src={Config.AUTHOR_IMG_URL + item?.image}
                                  alt=""
                                />
                              </div>
                              <div className="other-author-content">
                                <h3>{item?.designation}</h3>
                                <h5>{item?.name}</h5>
                                <p>{item?.about}</p>
                                <Link
                                  to={"/author/" + item?.slug}
                                  className="read-more-btn"
                                >
                                  View All Articles
                                </Link>
                              </div>
                            </div>
                          </li>
                        ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
        {/* <!--============================== Content Start ==============================--> */}
        <div className="content-container cta-normal-container pt-0">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="cta-normal-content text-center">
                  <p>
                    The best of <span>Object Magazine,</span> in your inbox.{" "}
                    <a href="/newsletter">Sign up for our Newsletters now.</a>{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!--============================== Content End ==============================--> */}
        {/* <!--============================== Content Start ==============================--> */}
        <div className="content-container">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="end-section">
                  <h5>
                    <span className="left">
                      <img src={headerShapeRight} alt="" />
                    </span>
                    end
                    <span className="right">
                      <img src={headerShapeLeft} alt="" />
                    </span>
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      {/* <!--============================== Main End ==============================--> */}
      {/* <!--============================== Footer Start ==============================--> */}
      <Footer />
      {/* <!--============================== Footer End ==============================--> */}
    </div>
  );
};

const mapStateToProps = (state) => ({
  authorData: state.author.data,
  aboutUsData: state.cmsFooter.data,
  aboutUsLoad: state.cmsFooter.load,
});

const mapDispatchToProps = (dispatch) => ({
  authorDSPCH: (data) => {
    dispatch(ActionCreators.author(data));
  },
  aboutUsDSPCH: (data) => {
    dispatch(ActionCreators.cmsFooter(data));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(AboutUs);
