import React, { useRef, useEffect } from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { ActionCreators } from "../actions";
import { Formik } from "formik";
import * as Yup from "yup";
import { Header, Footer } from "../components";
import Config from "../values/Strings/Config";
import { Link, useNavigate } from "react-router-dom";
import { GoogleLogin } from "react-google-login";
import { gapi } from "gapi-script";
import { useAlert } from "react-alert";
import googleIcon from "../assets/images/google-icon.svg";
import { fixedHeader } from "../values/utils";

const Login = ({ loginDSPCH, userData, userDataLoad, googleLoginDSPCH }) => {
  const alert = useAlert();

  const userDatasRef = useRef(userData);
  let navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }, 100);
    fixedHeader();
    // gapi used for google signin
    function start() {
      gapi.client.init({
        clientId: Config.GOOGLE_CLIENT_ID,
        scope: "email",
      });
    }

    gapi.load("client:auth2", start);
  }, []);

  useEffect(() => {
    if (userData !== userDatasRef.current) {
      if (userData?.success === true) {
        alert.success(
          <div style={{ textTransform: "initial" }}>{userData?.message}</div>
        );
        navigate("/");
      } else {
        alert.error(
          <div style={{ textTransform: "initial" }}>{userData?.message}</div>
        );
      }
    }
  }, [userData, userDatasRef]);

  const onSubmit = (fields) => {
    loginDSPCH({
      email: fields.email,
      password: fields.password,
    });
  };

  const onSuccess = (res) => {
    googleLoginDSPCH({
      token: res?.tokenObj?.id_token,
    });
  };

  return (
    <div>
      <Helmet>
        <title>Sign In - Object magazine</title>
        <meta name="description" content={""} />
      </Helmet>
      <Header />
      {/* <!--============================== modal Start ==============================--> */}
      <main id="main">
        <div className="content-container">
          <div className="container">
            <div className="row">
              <div className="col-md-6 offset-md-3">
                <div className="cc-content-box position-relative">
                  <div className="login-form-modal w-100">
                    <h4>Sign In To Join Our Community </h4>
                    <div className="member-form">
                      <GoogleLogin
                        clientId={Config.GOOGLE_CLIENT_ID}
                        render={(renderProps) => (
                          <div className="form-group">
                            <button
                              style={{
                                backgroundColor: "white",
                                border: 0,
                              }}
                              onClick={renderProps.onClick}
                            >
                              <div className="login-google d-flex align-items-center">
                                <span>
                                  <img src={googleIcon} alt="" />
                                </span>
                                Sign in with Google
                              </div>
                            </button>
                          </div>
                        )}
                        buttonText="Login"
                        onSuccess={onSuccess}
                        cookiePolicy={"single_host_origin"}
                        style={{ marginTop: "100px" }}
                        isSignedIn={true}
                      />
                      <div
                        className="form-group"
                        style={{ marginTop: 30, marginBottom: 30 }}
                      >
                        <p> Or</p>
                      </div>
                      <Formik
                        initialValues={{
                          email: "",
                          password: "",
                        }}
                        validationSchema={Yup.object().shape({
                          email: Yup.string()
                            .email("Email is invalid")
                            .required("Email is required"),
                          password: Yup.string()
                            .min(8, "Password must be at least 8 characters")
                            .required("Password is required"),
                        })}
                        onSubmit={(fields, { resetForm }) => {
                          resetForm();
                          onSubmit(fields);
                        }}
                      >
                        {({
                          errors,
                          touched,
                          setFieldValue,
                          values,
                          handleSubmit,
                          isSubmitting,
                        }) => (
                          <form onSubmit={handleSubmit}>
                            <div className="form-group">
                              <input
                                type="email"
                                className="form-control"
                                id="email"
                                placeholder="Email Address"
                                value={values.email}
                                onChange={(e) =>
                                  setFieldValue("email", e.target.value)
                                }
                              />
                            </div>
                            {touched.email && errors.email && (
                              <div
                                style={{
                                  textAlign: "left",
                                  color: "red",
                                  marginBottom: 10,
                                  fontSize: 12,
                                }}
                              >
                                {errors.email}
                              </div>
                            )}
                            <div className="form-group">
                              <input
                                type="password"
                                className="form-control"
                                id="password"
                                placeholder="Password"
                                value={values.password}
                                onChange={(e) =>
                                  setFieldValue("password", e.target.value)
                                }
                              />
                            </div>
                            {touched.password && errors.password && (
                              <div
                                style={{
                                  textAlign: "left",
                                  color: "red",
                                  marginBottom: 10,
                                  fontSize: 12,
                                }}
                              >
                                {errors.password}
                              </div>
                            )}
                            <div className="form-group">
                              <button
                                type="submit"
                                disabled={isSubmitting}
                                className="btn  btn-default"
                              >
                                {userDataLoad ? "Loading..." : "Sign In"}
                              </button>
                            </div>
                          </form>
                        )}
                      </Formik>
                    </div>
                  </div>
                  <div className="forgot-pass-outer text-center">
                    <Link className="forgot-pass" to="/forgot-password">
                      Forgot Password?
                    </Link>
                  </div>
                  <div className="forgot-pass-outer text-center">
                    <Link className="login-register" to="/sign-up">
                      Sign Up
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
      {/* <!--============================== modal End ==============================--> */}
    </div>
  );
};

const mapStateToProps = (state) => ({
  userData: state.login.user,
  userDataLoad: state.login.load,
});

const mapDispatchToProps = (dispatch) => ({
  loginDSPCH: (data) => {
    dispatch(ActionCreators.loginUser(data));
  },
  googleLoginDSPCH: (data) => {
    dispatch(ActionCreators.googleLogin(data));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
