import { takeEvery, call, put } from 'redux-saga/effects'
import { GET_CROSSWORD } from '../actions/type'
import { getCrossword } from '../services/getCrossword.api'
import { ActionCreators } from '../actions'

export const watchGetCrossword = function* () {    
  yield takeEvery(GET_CROSSWORD, workerGetCrossword)
}

function* workerGetCrossword(action) {
  try {  
    const res = yield call(getCrossword, action.data);
    yield put(ActionCreators.getCrosswordSuccess(res))
  } catch (e){
    yield put(ActionCreators.getCrosswordFailure(e))
  }
}
