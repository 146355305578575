import { GET_CROSSWORD_SINGLE, GET_CROSSWORD_SINGLE_SUCCESS, GET_CROSSWORD_SINGLE_ERROR } from './type'

export const getCrosswordSingle = (data) => ({
  type: GET_CROSSWORD_SINGLE,
  data
})

export const getCrosswordSingleSuccess = (data) => ({
  type: GET_CROSSWORD_SINGLE_SUCCESS,
  data
})

export const getCrosswordSingleFailure = (error) => ({
  type: GET_CROSSWORD_SINGLE_ERROR,
  error
})


