import { takeEvery, call, put } from 'redux-saga/effects'
import { SEARCH } from '../actions/type'
import { search } from '../services/search.api'
import { ActionCreators } from '../actions'

export const watchSearch = function* () {    
  yield takeEvery(SEARCH, workerSearch)
}

function* workerSearch(action) {
  try {   
    const res = yield call(search, action.data);
    yield put(ActionCreators.searchSuccess(res))
  } catch (e){
    yield put(ActionCreators.searchFailure(e))
  }
}
