import Config from "../values/Strings/Config";

export const getIndividualAuthor = ({ authorId, nextPageUrl, sort_by }) => {
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  };
  let url = nextPageUrl
    ? nextPageUrl
    : sort_by
    ? Config.INDIVIDUAL_AUTHOR_URL + authorId + `?sort_by=${sort_by}`
    : Config.INDIVIDUAL_AUTHOR_URL + authorId;
    
  return fetch(url, requestOptions)
    .then((res) => res.json())
    .then((res) => res);
};
