import React, { useEffect, useState, useRef } from "react";
import { Helmet } from "react-helmet";
import { Footer, Header, Loading } from "../components";
import { ActionCreators } from "../actions";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import moment from "moment";
import crosswordImg from "../assets/images/crosword.png";
import { fixedHeader } from "../values/utils";

const DailyCrossword = ({
  dailyCrosswordDSPCH,
  getCrosswordData,
  getCrosswordLoad,
}) => {
  const [sortBy, setSortBy] = useState("");
  const [crosswordList, setCrosswordLit] = useState([]);

  const getCrosswordDataRef = useRef(getCrosswordData);

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }, 100);
    fixedHeader();
    getDailyCrossword();
  }, []);

  useEffect(() => {
    if (getCrosswordData !== getCrosswordDataRef.current) {
      if (getCrosswordData?.success === true) {
        setCrosswordLit([...crosswordList, ...getCrosswordData?.data?.data]);
      } else {
        setCrosswordLit([]);
      }
    }
  }, [getCrosswordData, getCrosswordDataRef]);

  const getDailyCrossword = () => {
    dailyCrosswordDSPCH({ sort_by: sortBy });
  };

  const onHandleLoadMore = (nextPageUrl) => {
    dailyCrosswordDSPCH({ nextPageUrl: nextPageUrl });
  };

  const handleSortBy = (event) => {
    if (event.target.value) {
      setCrosswordLit([]); // set empty when click sort by
      setSortBy(event.target.value);
      dailyCrosswordDSPCH({ sort_by: event.target.value });
    } else {
      setCrosswordLit([]); // set empty when click sort by
      setSortBy("");
      dailyCrosswordDSPCH({ sort_by: sortBy });
    }
  };

  return (
    <div>
      <Helmet>
        <title>
          {getCrosswordData?.success === true &&
            getCrosswordData?.data?.meta_title}
        </title>
        <meta
          name="description"
          content={
            getCrosswordData?.success === true &&
            getCrosswordData?.data?.meta_description
          }
        />
      </Helmet>
      <Header />
      <main id="main">
        {/* <!--============================== Content Start ==============================--> */}
        <div className="content-container less-pad pb-0">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="page-heading">
                  <h1>DAILY CROSSWORDS</h1>
                  <h3>
                    THINK YOU KNOW ENOUGH? <br /> TEST YOURSELF WITH OUR
                    CROSSWORDS.{" "}
                  </h3>
                </div>

                <div className="filter-content d-flex flex-wrap ">
                  <h5>MOST RECENT</h5>
                  {crosswordList?.length > 0 && (
                    <div className="filter-box d-flex">
                      <span>Sort by: </span>
                      <select value={sortBy} onChange={handleSortBy}>
                        <option value="">Select</option>
                        <option value="newest">Newest</option>
                        <option value="oldest">Oldest</option>
                      </select>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!--============================== Content End ==============================--> */}
        {/* <!--============================== Content Start ==============================--> */}
        {console.log("crosswordList", crosswordList)}
        <div className="content-container crossword-container">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <ul className="crossword-list">
                  {crosswordList.length > 0 &&
                    crosswordList.map((item, ind) => (
                      <li className="crossword-item" key={ind}>
                        <Link
                          to={"/crossword/" + item?.slug}
                          className="crossword-box d-flex flex-wrap bg-primary"
                        >
                          <div className="crossword-content">
                            <span className="crossword-cat">crossword</span>
                            <h3>
                              {moment(new Date()).format("dddd") +
                                ", " +
                                moment(item?.updated_at).format("D MMMM, YYYY")}
                            </h3>
                            <p>{item?.title}</p>
                            <div className="post-by">
                              By <span>{item?.author_name}</span>
                            </div>
                          </div>
                          <div className="crossword-thumbnail">
                            <img src={crosswordImg} alt="" />
                          </div>
                        </Link>
                      </li>
                    ))}
                </ul>
                {getCrosswordLoad ? (
                  <Loading type={"spin"} color={"#000"} />
                ) : getCrosswordData?.data?.next_page_url ? (
                  <div className="load-more text-center">
                    <a
                      href="javascript:void(0)"
                      onClick={() =>
                        onHandleLoadMore(getCrosswordData?.data?.next_page_url)
                      }
                    >
                      Load more result
                    </a>
                  </div>
                ) : (
                  <>
                    {crosswordList.length === 0 && (
                      <p className="text-center my-5">No article found</p>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        {/* <!--============================== Content End ==============================--> */}
      </main>
      <Footer />
      {/* <!--============================== Main End ==============================--> */}
    </div>
  );
};

const mapStateToProps = (state) => ({
  getCrosswordData: state.getCrossword.data,
  getCrosswordLoad: state.getCrossword.load,
});

const mapDispatchToProps = (dispatch) => ({
  dailyCrosswordDSPCH: (data) => {
    dispatch(ActionCreators.getCrossword(data));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(DailyCrossword);
