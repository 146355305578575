import Config from "../values/Strings/Config";

export const getIndividualSubject = ({
  subjectId,
  nextPageUrl,
  sort_by,
  keyword,
}) => {
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  };
  
  let url;
  if (nextPageUrl)
    if (nextPageUrl && sort_by && keyword) {
      url = `${nextPageUrl}&keyword=${keyword}&sort_by=${sort_by}`;
    } else if (nextPageUrl && sort_by) {
      url = `${nextPageUrl}&sort_by=${sort_by}`;
    } else if (nextPageUrl && keyword) {
      url = `${nextPageUrl}?keyword=${keyword}`;
    } else {
      url = nextPageUrl;
    }
  else if (keyword)
    if (keyword && nextPageUrl && sort_by) {
      url = `${nextPageUrl}&keyword=${keyword}&sort_by=${sort_by}`;
    } else if (keyword && sort_by) {
      url =
        `${Config.INDIVIDUAL_SUBJECT_URL}${subjectId}?keyword=${keyword}&sort_by=${sort_by}`;
    } else if (keyword && nextPageUrl) {
      url = nextPageUrl + `&keyword=${keyword}`;
    } else {
      url = `${Config.INDIVIDUAL_SUBJECT_URL}${subjectId}?keyword=${keyword}`;
    }
  else if (sort_by)
    if (sort_by && nextPageUrl && keyword) {
      url = `${nextPageUrl}&keyword=${keyword}&sort_by=${sort_by}`;
    } else if (sort_by && nextPageUrl) {
      url = `${nextPageUrl}&sort_by=${sort_by}`;
    } else if (sort_by && keyword) {
      url = `${Config.INDIVIDUAL_SUBJECT_URL}${subjectId}?sort_by=${sort_by}&keyword=${keyword}`;
    } else {
      url = `${Config.INDIVIDUAL_SUBJECT_URL}${subjectId}?sort_by=${sort_by}`;
    }
  else url = Config.INDIVIDUAL_SUBJECT_URL + subjectId;

  return fetch(url, requestOptions)
    .then((res) => res.json())
    .then((res) => res);
};
