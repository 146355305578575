import {
  GET_CROSSWORD,
  GET_CROSSWORD_SUCCESS,
  GET_CROSSWORD_ERROR,
} from "./type";

export const getCrossword = (data) => ({
  type: GET_CROSSWORD,
  data
});

export const getCrosswordSuccess = (data) => ({
  type: GET_CROSSWORD_SUCCESS,
  data,
});

export const getCrosswordFailure = (error) => ({
  type: GET_CROSSWORD_ERROR,
  error,
});
