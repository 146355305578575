import React, { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { Footer, Header, Loading } from "../components";
import Config from "../values/Strings/Config";
import { connect } from "react-redux";
import { ActionCreators } from "../actions";
import { Link } from "react-router-dom";
import { convertDateFormate, fixedHeader, isValidUrl } from "../values/utils";
import palyIcon from "../assets/images/play-icon-white.svg";

const PodcastVideo = ({
  getAllVideoArticleDSPCH,
  getAllVideoArticleData,
  getAllVideoArticleLoad,
}) => {
  const [articleList, setArticleList] = useState([]);
  const [sort_by, setSortBy] = useState(0);
  const [all, setAll] = useState(true);
  const [categoryName, setCategoryName] = useState("");

  const getAllVideoArticleDataRef = useRef(getAllVideoArticleData);

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }, 100);
    fixedHeader();
    getAllVideoArticle();
  }, []);

  useEffect(() => {
    if (getAllVideoArticleData !== getAllVideoArticleDataRef.current) {
      if (getAllVideoArticleData?.success === true) {
        setArticleList(articleList.concat(getAllVideoArticleData?.data?.data));
      } else {
        setArticleList([]);
      }
    }
  }, [getAllVideoArticleData, getAllVideoArticleDataRef]);

  const getAllVideoArticle = () => {
    getAllVideoArticleDSPCH({
      sort_by: "",
    });
  };

  const onHandleLoadMore = (nextPageUrl) => {
    getAllVideoArticleDSPCH({ nextPageUrl: nextPageUrl });
  };

  const handleSortBy = (event) => {
    setArticleList([]);
    setSortBy(event.target.value);
    if (categoryName !== "") {
      getAllVideoArticleDSPCH({
        sort_by: event.target.value,
        category: categoryName,
      });
    } else {
      getAllVideoArticleDSPCH({ sort_by: event.target.value });
    }
  };

  const onClickAll = () => {
    setArticleList([]);
    setCategoryName("");
    setAll(true);
    getAllVideoArticleDSPCH({
      sort_by: "",
    });
  };

  const onHandleCategory = (category_slug) => {
    setArticleList([]);
    setSortBy(0);
    setAll(false);
    setCategoryName(category_slug);
    getAllVideoArticleDSPCH({ category: category_slug });
  };

  return (
    <div>
      <Helmet>
        <title>Podcast And Video - Object magazine</title>
        <meta name="description" content={""} />
      </Helmet>

      <Header />

      {/* <!--============================== Main Start ==============================--> */}
      <main id="main">
        {/* <!--============================== Content Start ==============================--> */}
        <div className="content-container less-pad pb-0">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="page-heading">
                  <h1>VIDEOS & PODCASTS</h1>
                </div>
                {getAllVideoArticleData?.catecories?.length > 0 && (
                  <ul className="category-tab-list d-flex flex-wrap">
                    <li className={all === true ? "active" : ""}>
                      <a
                        href="javascript:void(0)"
                        onClick={() => onClickAll()}
                        className="category-tab-link"
                      >
                        All
                      </a>
                    </li>
                    {getAllVideoArticleData?.catecories?.map((item, ind) => (
                      <li
                        key={ind}
                        className={
                          categoryName === item?.category_slug && all === false
                            ? "active"
                            : ""
                        }
                      >
                        <a
                          href="javascript:void(0)"
                          onClick={() => onHandleCategory(item?.category_slug)}
                          className="category-tab-link"
                        >
                          {item?.category_name}
                        </a>
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            </div>
          </div>
        </div>
        {/* <!--============================== Content End ==============================--> */}
        {/* <!--============================== Content Start ==============================--> */}
        <div className="content-container video-container pb-0">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="filter-content d-flex flex-wrap ">
                  <h5>MOST RECENT</h5>
                  {articleList.length > 0 && (
                    <div className="filter-box d-flex">
                      <span>Sort by: </span>
                      <select value={sort_by} onChange={handleSortBy}>
                        <option value="">Select</option>
                        <option value="newest">Newest</option>
                        <option value="oldest">Oldest</option>
                      </select>
                    </div>
                  )}
                </div>
                <ul className="podcast-list">
                  {articleList?.length > 0 &&
                    articleList?.map((item, ind) => (
                      <li className="podcast-item" key={ind}>
                        <Link
                          to={"/article/" + item?.slug}
                          className="podcast-box d-flex flex-wrap"
                        >
                          <div className="podcast-thumbnail">
                            {isValidUrl(item?.image) ? (
                              <img src={item?.image} alt="" />
                            ) : (
                              <img src={Config.IMG_URL + item?.image} alt="" />
                            )}

                            <span className="podcast-ply-btn">
                              <img src={palyIcon} alt="" />
                            </span>
                          </div>
                          <div className="podcast-text">
                            <ol className="breadcrumb">
                              <li
                                className="breadcrumb-item active"
                                aria-current="page"
                              >
                                {item?.post_type === 2 ? "video" : "podcast"}
                              </li>
                            </ol>
                            <h3>{item?.title}</h3>
                            <p>{item?.sub_title}</p>
                            <div className="post-by">
                              Hosted By <span>{item?.author_name}</span>
                            </div>
                            <div
                              className="post-date"
                              style={{ textTransform: "uppercase" }}
                            >
                              {convertDateFormate(item?.publish_date)}
                            </div>
                          </div>
                          <div className="podcast-duration">
                            {item?.duration}
                          </div>
                        </Link>
                      </li>
                    ))}
                </ul>
                {getAllVideoArticleLoad ? (
                  <Loading type={"spin"} color={"#000"} />
                ) : getAllVideoArticleData?.data?.data?.next_page_url ? (
                  <div className="load-more text-center">
                    <a
                      href="javascript:void(0)"
                      role="button"
                      onClick={() =>
                        onHandleLoadMore(
                          getAllVideoArticleData?.data?.data?.next_page_url
                        )
                      }
                    >
                      Load more results
                    </a>
                  </div>
                ) : (
                  <>
                    {articleList.length === 0 && (
                      <p className="text-center my-5">No article found</p>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        {/* <!--============================== Content End ==============================--> */}
      </main>
      {/* <!--============================== Main End ==============================--> */}
      {/* <!--============================== Footer Start ==============================--> */}
      <Footer />
      {/* <!--============================== Footer End ==============================--> */}
    </div>
  );
};

const mapStateToProps = (state) => ({
  getAllVideoArticleData: state.getAllVideoArticle.data,
  getAllVideoArticleLoad: state.getAllVideoArticle.load,
});

const mapDispatchToProps = (dispatch) => ({
  getAllVideoArticleDSPCH: (data) => {
    dispatch(ActionCreators.getAllVideoArticle(data));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(PodcastVideo);
