import createSagaMiddleware from 'redux-saga';
import { applyMiddleware, compose } from 'redux';
import { persistReducer, persistStore } from 'redux-persist';
import { legacy_createStore as createStore} from 'redux'
import localforage from 'localforage';

import rootReducer from '../reducers';
import rootSaga from '../sagas';

const persistConfig = {
  storage: localforage,
  key: 'root',
  whitelist: ["login"],
}

const sagaMiddleware = createSagaMiddleware()
const reducers = persistReducer(persistConfig, rootReducer)

const appMiddleware = (_store) => (next) => (action) => {
  next(action)
}

const middlewares = [sagaMiddleware, appMiddleware]
const enhancers = [applyMiddleware(...middlewares)]
export const store = createStore(reducers, compose(...enhancers))
sagaMiddleware.run(rootSaga)

export const persistor = persistStore(store)
