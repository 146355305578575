import Config from "../values/Strings/Config";

export const getAllVideoArticle = ({nextPageUrl, sort_by, category }) => {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      sort_by: sort_by,
      category: category
    })
  };
  
  let url;
  if (nextPageUrl) {
    url = nextPageUrl;
  } else {
    url = Config.GET_ALL_VIDEO_ARTICLE;
  }
  
  return fetch(url, requestOptions)
    .then((res) => res.json())
    .then((res) => res);
};
