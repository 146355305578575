import { takeEvery, call, put } from 'redux-saga/effects'
import { GET_QUOTE } from '../actions/type'
import { getQuote } from '../services/getQuote.api'
import { ActionCreators } from '../actions'

export const watchGetQuote = function* () {    
  yield takeEvery(GET_QUOTE, workerGetQuote)
}

function* workerGetQuote(action) {
  try {          
    const res = yield call(getQuote, action.data);
    yield put(ActionCreators.getQuoteSuccess(res))
  } catch (e){
    yield put(ActionCreators.getQuoteFailure(e))
  }
}
