import { COMPLETE_ORDER, COMPLETE_ORDER_SUCCESS, COMPLETE_ORDER_ERROR } from "../actions/type"

const defaultState = {
  load: false,
  data: {},
  error: null,
}

export const completeOrder = (state = defaultState, action) => {
  const response = action.data;
  switch (action.type) {
    case COMPLETE_ORDER:
      return {
        ...state,
        load: true
      }
    case COMPLETE_ORDER_SUCCESS:
      return { 
        ...state, 
        data: response,
        load: false
      }
    case COMPLETE_ORDER_ERROR:
      return { 
        ...state, 
        error: response,
        load: false,
      }
    default:
      return state
  }
}


