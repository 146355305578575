import { takeEvery, call, put } from 'redux-saga/effects'
import { COMPLETE_ORDER } from '../actions/type'
import { completeOrder } from '../services/completeOrder.api'
import { ActionCreators } from '../actions'

export const watchCompleteOrder = function* () {    
  yield takeEvery(COMPLETE_ORDER, workerCompleteOrder)
}

function* workerCompleteOrder(action) {
  try {   
    const res = yield call(completeOrder, action.data, action.token);
    yield put(ActionCreators.completeOrderSuccess(res))
  } catch (e){
    yield put(ActionCreators.completeOrderFailure(e))
  }
}
