import React, { useEffect, useState, useRef } from "react";
import { Helmet } from "react-helmet";
import { Footer, Header, Loading } from "../components";
import { Link, useParams } from "react-router-dom";
import { connect } from "react-redux";
import { ActionCreators } from "../actions";
import Config from "../values/Strings/Config";
import { convertDateFormate, fixedHeader, isValidUrl } from "../values/utils";

const Author = ({
  authorDSPCH,
  authorData,
  individualAuthorDSPCH,
  individualAuthorData,
  individualAuthorLoad,
}) => {
  const { id } = useParams();
  const [sortBy, setSortBy] = useState("");
  const [articleList, setArticleList] = useState([]);

  const individualAuthorDataRef = useRef(individualAuthorData);

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }, 100);
    fixedHeader();
    getIndividualAuthor();
    getAuthor();
  }, [id]);

  useEffect(() => {
    if (individualAuthorData !== individualAuthorDataRef.current) {
      if (individualAuthorData?.success === true) {
        setArticleList(
          articleList.concat(individualAuthorData?.data?.articles?.data)
        );
      } else {
        setArticleList([]);
      }
    }
  }, [individualAuthorData, individualAuthorDataRef]);

  const getIndividualAuthor = () => {
    individualAuthorDSPCH({ authorId: id });
  };

  const getAuthor = () => {
    setArticleList([]);
    authorDSPCH();
  };

  const onHandleLoadMore = (nextPageUrl) => {
    individualAuthorDSPCH({ authorId: id, nextPageUrl: nextPageUrl });
  };

  const handleSortBy = (event) => {
    if (event.target.value) {
      setArticleList([]);
      setSortBy(event.target.value);
      individualAuthorDSPCH({ authorId: id, sort_by: event.target.value });
    } else {
      setArticleList([]);
      setSortBy("");
      individualAuthorDSPCH({ authorId: id });
    }
  };

  return (
    <div>
      <Helmet>
        <title>
          {individualAuthorData?.success &&
            individualAuthorData?.data?.meta_title}
        </title>
        <meta
          name="description"
          content={
            individualAuthorData?.success &&
            individualAuthorData?.data?.meta_description
          }
        />
      </Helmet>
      <Header />
      {/* <!--============================== Main Start ==============================--> */}
      <>
        <main id="main">
          {/* <!--============================== Content Start ==============================--> */}
          <div className="content-container author-single-container pb-0">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="author-container d-flex flex-wrap pb-0">
                    <div className="author-content d-flex">
                      <div className="post-auther-img">
                        <img
                          src={
                            Config.AUTHOR_IMG_URL +
                            individualAuthorData?.data?.image
                          }
                          alt=""
                        />
                      </div>
                      <div className="author-name">
                        <h3>{individualAuthorData?.data?.name}</h3>
                        <h6>{individualAuthorData?.data?.designation}</h6>
                        <blockquote>
                          {individualAuthorData?.data?.about}
                        </blockquote>
                        {(individualAuthorData?.data?.facebook ||
                          individualAuthorData?.data?.instagram ||
                          individualAuthorData?.data?.email ||
                          individualAuthorData?.data?.twitter ||
                          individualAuthorData?.data?.linkedin) && (
                          <div className="share-box">
                            <h5>Contact</h5>
                            <ul className="share-post-list d-flex">
                              {individualAuthorData?.data?.facebook && (
                                <li>
                                  <a
                                    href={individualAuthorData?.data?.facebook}
                                    target="_blank"
                                  >
                                    <i
                                      className="fab fa-facebook-f"
                                      aria-hidden="true"
                                    ></i>
                                  </a>
                                </li>
                              )}
                              {individualAuthorData?.data?.instagram && (
                                <li>
                                  <a
                                    href={individualAuthorData?.data?.instagram}
                                    target="_blank"
                                  >
                                    <i
                                      className="fab fa-instagram"
                                      aria-hidden="true"
                                    ></i>
                                  </a>
                                </li>
                              )}
                              {individualAuthorData?.data?.twitter && (
                                <li>
                                  <a
                                    href={individualAuthorData?.data?.twitter}
                                    target="_blank"
                                  >
                                    <i
                                      className="fab fa-twitter"
                                      aria-hidden="true"
                                    ></i>
                                  </a>
                                </li>
                              )}
                              {individualAuthorData?.data?.linkedin && (
                                <li>
                                  <a
                                    href={individualAuthorData?.data?.linkedin}
                                    target="_blank"
                                  >
                                    <i
                                      className="fab fa-linkedin"
                                      aria-hidden="true"
                                    ></i>
                                  </a>
                                </li>
                              )}
                              {individualAuthorData?.data?.email && (
                                <li>
                                  <a
                                    href={`mailto:${individualAuthorData?.data?.email}`}
                                    target="_blank"
                                  >
                                    <i className="fas fa-envelope"></i>
                                  </a>
                                </li>
                              )}
                            </ul>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!--============================== Content End ==============================--> */}
          {/* <!--============================== Content Start ==============================--> */}
          <div className="content-container article-container pb-0">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="filter-content d-flex flex-wrap ">
                    <h5>ARTICLES BY {individualAuthorData?.data?.name}</h5>
                    {articleList?.length > 0 && (
                      <div className="filter-box d-flex">
                        <span>Sort by: </span>
                        <select value={sortBy} onChange={handleSortBy}>
                          <option value="">Select</option>
                          <option value="newest">Newest</option>
                          <option value="oldest">Oldest</option>
                        </select>
                      </div>
                    )}
                  </div>
                  <ul className="article-list">
                    {articleList?.length > 0 &&
                      articleList?.map((item, ind) => (
                        <li className="article-item" key={ind}>
                          <div className="article-box d-flex flex-wrap">
                            <div className="article-text">
                              <ol className="breadcrumb">
                                <li
                                  className="breadcrumb-item active"
                                  aria-current="page"
                                >
                                  {item?.title}
                                </li>
                              </ol>
                              <h3>
                                <Link to={"/article/" + item?.slug}>
                                  {item?.title}
                                </Link>
                              </h3>
                              <p>{item?.sub_title}</p>
                              <div className="post-by">
                                By{" "}
                                <span>{individualAuthorData?.data?.name}</span>
                              </div>
                              <div
                                className="post-date"
                                style={{ textTransform: "uppercase" }}
                              >
                                {convertDateFormate(item?.publish_date)}
                              </div>
                            </div>
                            <div className="article-thumbnail">
                              <Link to={"/article/" + item?.slug}>
                                {item?.mediaData !== null && (
                                  <>
                                    {isValidUrl(item?.image) ? (
                                      <img src={item?.image} alt="" />
                                    ) : (
                                      <img
                                        src={
                                          Config.IMG_URL +
                                          "thumb/" +
                                          item?.image
                                        }
                                        alt=""
                                      />
                                    )}
                                  </>
                                )}
                              </Link>
                            </div>
                          </div>
                        </li>
                      ))}
                  </ul>
                  {individualAuthorLoad ? (
                    <Loading type={"spin"} color={"#000"} />
                  ) : individualAuthorData?.data?.articles?.next_page_url ? (
                    <div className="load-more text-center">
                      <a
                        href="javascript:void(0)"
                        onClick={() =>
                          onHandleLoadMore(
                            individualAuthorData?.data?.articles?.next_page_url
                          )
                        }
                      >
                        Load more results
                      </a>
                    </div>
                  ) : (
                    <>
                      {articleList.length === 0 && (
                        <p className="text-center my-5">No article found</p>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
          {/* <!--============================== Content End ==============================--> */}
          {/* <!--============================== Content Start ==============================--> */}
          <div className="content-container other-author-container">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="simple-heading">
                    <h4>OTHER AUTHORS</h4>
                  </div>
                  <ul className="other-author-list">
                    {authorData?.success === true &&
                      authorData?.data
                        ?.filter(
                          (opt) =>
                            opt?.slug !== individualAuthorData?.data?.slug
                        )
                        ?.map((item, ind) => {
                          return (
                            <li className="other-author-item" key={ind}>
                              <div className="other-author-box d-flex flex-wrap">
                                <div className="other-author-img">
                                  <img
                                    src={Config.AUTHOR_IMG_URL + item?.image}
                                    alt=""
                                  />
                                </div>
                                <div className="other-author-content">
                                  <h3>{item?.designation}</h3>
                                  <h5>{item?.name}</h5>
                                  <p>{item?.about}</p>
                                  <Link
                                    to={"/author/" + item?.slug}
                                    className="read-more-btn"
                                  >
                                    View All Articles
                                  </Link>
                                </div>
                              </div>
                            </li>
                          );
                        })}
                  </ul>
                </div>
              </div>
            </div>
          </div>
          {/* <!--============================== Content End ==============================--> */}
        </main>
      </>

      {/* <!--============================== Main End ==============================--> */}
      {/* <!--============================== Footer Start ==============================--> */}
      <Footer />
      {/* <!--============================== Footer End ==============================--> */}
    </div>
  );
};

const mapStateToProps = (state) => ({
  authorData: state.author.data,
  individualAuthorData: state.getIndividualAuthor.data,
  individualAuthorLoad: state.getIndividualAuthor.load,
});

const mapDispatchToProps = (dispatch) => ({
  authorDSPCH: () => {
    dispatch(ActionCreators.author());
  },
  individualAuthorDSPCH: (data) => {
    dispatch(ActionCreators.getIndividualAuthor(data));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Author);
