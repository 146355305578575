import { GET_CROSSWORD_SINGLE_SUCCESS, GET_CROSSWORD_SINGLE_ERROR, GET_CROSSWORD_SINGLE } from "../actions/type"

const defaultState = {
  load: false,
  data: {},
  error: null,
}

export const getCrosswordSingle = (state = defaultState, action) => {
  const response = action.data;
  switch (action.type) {
    case GET_CROSSWORD_SINGLE:
      return {
        ...state,
        load: true
      }
    case GET_CROSSWORD_SINGLE_SUCCESS:
      return { 
        ...state, 
        data: response,
        load: false
      }
    case GET_CROSSWORD_SINGLE_ERROR:
      return { 
        ...state, 
        error: response,
        load: false,
      }
    default:
      return state
  }
}


