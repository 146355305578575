import React from "react";
import { Link, useLocation } from "react-router-dom";

function Col1Header() {
  const location = useLocation()
  
  return (
    <div className="page-link-container">
      <div className="container">
        <div className="row">
          <div className="col-lg-10 offset-lg-1">
            <ul className="page-links d-flex flex-wrap">
              <li className={`${location.pathname ==="/issue-dispatch" ?"active": ""}`}>
                <Link to="/issue-dispatch" className="page-link">
                  ISSUE DISPATCH
                </Link>
              </li>
              <li className={`${location.pathname ==="/about-us" ?"active": ""}`}>
                <Link to="/about-us" className="page-link">
                  ABOUT US
                </Link>
              </li>
              <li className={`${location.pathname ==="/contact-us" ?"active": ""}`}>
                <Link to="/contact-us" className="page-link">
                  CONTACT US
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Col1Header;
