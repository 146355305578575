import Config from "../values/Strings/Config";

export const getQna = () => {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ id: "62754d3e3cd45928c47bde51" }),
  };
  let url = Config.GET_QNA_URL;
  return fetch(url, requestOptions)
    .then((res) => res.json())
    .then((res) => res);
};
