import Config from '../values/Strings/Config';

export const registerUser = ({name, email, password, phone}) => {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({name, email, password, phone})
  }
  let url = Config.REGISTER_URL;
  return fetch(url, requestOptions)
    .then(res => res.json())
    .then(res => res)
}