import Config from "../values/Strings/Config";

export const search = ({ keyword, category, nextPageUrl, sort_by }) => {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      keyword: keyword,
      category: category,
      sort_by: sort_by,
    }),
  };

  let url;

  if (nextPageUrl) {
    url = nextPageUrl;
  } else {
    url = Config.SEARCH_ARTICLE_URL;
  }

  return fetch(url, requestOptions)
    .then((res) => res.json())
    .then((res) => res);
};
