import { takeEvery, call, put } from 'redux-saga/effects'
import { GOOGLE_LOGIN } from '../actions/type'
import { googleLogin } from '../services/googleLogin.api'
import { ActionCreators } from '../actions'

export const watchGoogleLogin = function* () {    
  yield takeEvery(GOOGLE_LOGIN, workerGooleLogin)
}

function* workerGooleLogin(action) {
  try {          
    const res = yield call(googleLogin, action.data);
    yield put(ActionCreators.googleLoginSuccess(res));
  } catch (e){
    yield put(ActionCreators.googleLoginFailure(e));
  }
}
