import { takeEvery, call, put } from 'redux-saga/effects'
import { REGISTER_USER } from '../actions/type'
import { registerUser } from '../services/register.api'
import { ActionCreators } from '../actions'

export const watchRegister = function* () {    
  yield takeEvery(REGISTER_USER, workerRegister)
}

function* workerRegister(action) {
  try {          
    const res = yield call(registerUser, action.data);
    yield put(ActionCreators.registerUserSuccess(res));
  } catch (e){
    yield put(ActionCreators.registerUserFailure(e));
  }
}
