import React from "react";

import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "./store";
import { positions, Provider as AlertProvider, transitions } from "react-alert";
import AlertTemplate from "react-alert-template-basic";

import Navigation from "./Navigation";
import { ModalProvider } from "./components/popups/ModalContext";

function App() {
  const options = {
    timeout: 5000,
    position: positions.BOTTOM_RIGHT,
    offset: "30px",
    // you can also just use 'scale'
    transition: transitions.SCALE,
  };

  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <AlertProvider template={AlertTemplate} {...options}>
          <Navigation />
        </AlertProvider>
      </PersistGate>
    </Provider>
  );
}

export default App;
