import {
  GET_INDIVIDUAL_SUBJECT,
  GET_INDIVIDUAL_SUBJECT_SUCCESS,
  GET_INDIVIDUAL_SUBJECT_ERROR,
} from "../actions/type";

const defaultState = {
  load: false,
  data: {},
  error: null,
};

export const getIndividualSubject = (state = defaultState, action) => {
  const response = action.data;
  switch (action.type) {
    case GET_INDIVIDUAL_SUBJECT:
      return {
        ...state,
        load: true,
      };
    case GET_INDIVIDUAL_SUBJECT_SUCCESS:
      return {
        ...state,
        data: response,
        load: false,
      };
    case GET_INDIVIDUAL_SUBJECT_ERROR:
      return {
        ...state,
        error: response,
        load: false,
      };
    default:
      return state;
  }
};
