import React, { useEffect, useRef } from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { ActionCreators } from "../actions";
import { Formik } from "formik";
import * as Yup from "yup";
import { Header, Footer } from "../components";
import { Link, useNavigate } from "react-router-dom";
import { useAlert } from "react-alert";
import { fixedHeader } from "../values/utils";

const Register = ({ registerDSPCH, registerData, registerLoad }) => {
  const alert = useAlert();
  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  const registerDataRef = useRef(registerData);
  let navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }, 100);
    fixedHeader();
  }, []);

  useEffect(() => {
    if (registerData !== registerDataRef.current) {
      if (registerData?.success === true) {
        alert.success(
          <div style={{ textTransform: "initial" }}>
            You have successfully signed up!
          </div>
        );
        navigate("/sign-in");
      } else {
        alert.error(
          <div style={{ textTransform: "initial" }}>
            {registerData?.message?.email[0]}
          </div>
        );
      }
    }
  }, [registerData, registerDataRef]);

  const onSubmit = (fields) => {
    registerDSPCH({
      name: fields.name,
      email: fields.email,
      password: fields.password,
      phone: fields.phoneNumber,
    });
  };

  return (
    <div>
      <Helmet>
        <title>Register - Object magazine</title>
        <meta name="description" content={""} />
      </Helmet>
      <Header />
      {/* <!--============================== modal Start ==============================--> */}
      <main id="main">
        <div className="content-container">
          <div className="container">
            <div className="row">
              <div className="col-md-6 offset-md-3">
                <div className="cc-content-box position-relative">
                  <div className="login-form-modal w-100">
                    <h4>Sign Up</h4>
                    <div className="member-form">
                      <Formik
                        initialValues={{
                          name: "",
                          email: "",
                          password: "",
                          phoneNumber: "",
                        }}
                        validationSchema={Yup.object().shape({
                          name: Yup.string().required("Name is required"),
                          email: Yup.string()
                            .email("Email is invalid")
                            .required("Email is required"),
                          password: Yup.string()
                            .min(8, "Password must be at least 8 characters")
                            .required("Password is required"),
                          phoneNumber: Yup.string()
                            .required("Phone number is required")
                            .matches(phoneRegExp, "Phone number is not valid")
                            .min(10, "to short")
                            .max(10, "to long"),
                        })}
                        onSubmit={(fields, { resetForm }) => {
                          resetForm();
                          onSubmit(fields);
                        }}
                      >
                        {({
                          errors,
                          touched,
                          setFieldValue,
                          values,
                          handleSubmit,
                          isSubmitting,
                        }) => (
                          <form onSubmit={handleSubmit}>
                            <div className="form-group">
                              <input
                                type="text"
                                className="form-control"
                                id="name"
                                placeholder="Name"
                                value={values.name}
                                onChange={(e) =>
                                  setFieldValue("name", e.target.value)
                                }
                              />
                            </div>
                            {touched.name && errors.name && (
                              <div
                                style={{
                                  textAlign: "left",
                                  color: "red",
                                  marginBottom: 10,
                                  fontSize: 12,
                                }}
                              >
                                {errors.name}
                              </div>
                            )}
                            <div className="form-group">
                              <input
                                type="email"
                                className="form-control"
                                id="email"
                                placeholder="Email Address"
                                value={values.email}
                                onChange={(e) =>
                                  setFieldValue("email", e.target.value)
                                }
                              />
                            </div>
                            {touched.email && errors.email && (
                              <div
                                style={{
                                  textAlign: "left",
                                  color: "red",
                                  marginBottom: 10,
                                  fontSize: 12,
                                }}
                              >
                                {errors.email}
                              </div>
                            )}
                            <div className="form-group">
                              <input
                                type="password"
                                className="form-control"
                                id="password"
                                placeholder="Password"
                                value={values.password}
                                onChange={(e) =>
                                  setFieldValue("password", e.target.value)
                                }
                              />
                            </div>
                            {touched.password && errors.password && (
                              <div
                                style={{
                                  textAlign: "left",
                                  color: "red",
                                  marginBottom: 10,
                                  fontSize: 12,
                                }}
                              >
                                {errors.password}
                              </div>
                            )}
                            <div className="form-group">
                              <input
                                type="text"
                                className="form-control"
                                id="phoneNumber"
                                placeholder="Phone Number"
                                value={values.phoneNumber}
                                onChange={(e) =>
                                  setFieldValue("phoneNumber", e.target.value)
                                }
                              />
                            </div>
                            {touched.phoneNumber && errors.phoneNumber && (
                              <div
                                style={{
                                  textAlign: "left",
                                  color: "red",
                                  marginBottom: 10,
                                  fontSize: 12,
                                }}
                              >
                                {errors.phoneNumber}
                              </div>
                            )}
                            <div className="form-group">
                              <button
                                type="submit"
                                className="btn  btn-default"
                                disabled={isSubmitting}
                              >
                                {registerLoad ? "Loading..." : "Sign up"}
                              </button>
                            </div>
                          </form>
                        )}
                      </Formik>
                    </div>
                  </div>
                  <div className="forgot-pass-outer text-center">
                    <Link className="forgot-pass" to="/sign-in">
                      Sign In
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
      {/* <!--============================== modal End ==============================--> */}
    </div>
  );
};

const mapStateToProps = (state) => ({
  registerData: state.register.user,
  registerLoad: state.register.load,
});

const mapDispatchToProps = (dispatch) => ({
  registerDSPCH: (data) => {
    dispatch(ActionCreators.registerUser(data));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Register);
