import React, { useEffect, useState, useRef } from "react";
import { Helmet } from "react-helmet";
import { Footer, Header, Loading } from "../components";
import Config from "../values/Strings/Config";
import { Formik } from "formik";
import * as Yup from "yup";
import { ActionCreators } from "../actions";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { convertDateFormate, isValidUrl } from "../values/utils";
import searchIcon from "../assets/images/search-icon.svg";

const Search = ({ searchDSPCH, searchData, searchLoad }) => {
  const pageURL = window.location.href; 
  const params = new URLSearchParams(pageURL.split("?")[1]);

  const paramSearch = params.get("q"); 
  const paramCategory = params.get("category"); 
  const searchDataRef = useRef(searchData);

  const [resultShow, setResultShow] = useState(false); 
  const [categoryName, setCategoryName] = useState(""); 
  const [search, setSearch] = useState(""); 
  const [all, setAll] = useState(false); 
  const [sortBy, setSortBy] = useState(0);
  const [articleList, setArticleList] = useState(() => []);

  const navigate = useNavigate();
  useEffect(() => {
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }, 100);
    initialLoad();
  }, [paramSearch]);

  useEffect(() => {
    if (searchData !== searchDataRef.current) {
      if (searchData?.success === true) {
        setArticleList((articleList) => [
          ...articleList,
          ...searchData?.data?.data,
        ]);
      } else {
        setArticleList([]);
      }
    }
  }, [searchData, searchDataRef]);

  const initialLoad = () => {
    if (paramSearch) {
      if (paramCategory === null) {
        setSearch(paramSearch);
        setAll(true);
        setResultShow(true); 
        searchDSPCH({ keyword: paramSearch });
      } else {
        setAll(false);
        setSearch(paramSearch);
        setCategoryName(paramCategory);
        setResultShow(true); 
        searchDSPCH({ keyword: paramSearch, category: paramCategory });
      }
    }
  };

  const onHandleSearch = (fields) => {
    setArticleList([]); 
    navigate(`/search?q=${fields.search}`); 
    setAll(true); 
    setResultShow(true); 
  };

  const onHandleCategory = (category_slug) => {
    setArticleList([]); 
    setSortBy(0);
    navigate(`/search?q=${search}&category=${category_slug}`); 
    setAll(false); 
    setCategoryName(category_slug); 
    setResultShow(true); 
    searchDSPCH({ keyword: search, category: category_slug });
  };

  const onClickAll = () => {
    setArticleList([]); 
    setSortBy(0);
    navigate(`/search?q=${search}`); 
    setCategoryName(""); 
    setAll(true);
    setResultShow(true); 
    searchDSPCH({ keyword: search });
  };

  const onHandleLoadMore = (nextPageUrl) => {
    if (categoryName !== "") {
      navigate(`/search?q=${search}&category=${categoryName}`);
      searchDSPCH({
        keyword: search,
        nextPageUrl: nextPageUrl,
        category: categoryName,
      });
    } else {
      searchDSPCH({ keyword: search, nextPageUrl: nextPageUrl });
    }
  };

  const handleSortBy = (event) => {
    setArticleList([]); 
    setSortBy(event.target.value);
    if (categoryName !== "") {
      searchDSPCH({
        keyword: search,
        sort_by: event.target.value,
        category: categoryName,
      });
    } else {
      searchDSPCH({ keyword: search, sort_by: event.target.value });
    }
  };

  return (
    <div>
      <Helmet>
        <title>Search - Object magazine</title>
        <meta name="description" content={""} />
      </Helmet>
      <Header />
      {/* <!--============================== Main Start ==============================--> */}
      <main id="main">
        {/* <!--============================== Content Start ==============================--> */}
        <div className="content-container search-container">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <Formik
                  initialValues={{
                    search: search,
                  }}
                  enableReinitialize
                  validationSchema={Yup.object().shape({
                    search: Yup.string().required(
                      "Please type in your query in the Search Box."
                    ),
                  })}
                  onSubmit={(fields) => {
                    onHandleSearch(fields);
                  }}
                >
                  {({
                    errors,
                    touched,
                    setFieldValue,
                    values,
                    handleSubmit,
                    isSubmitting,
                  }) => (
                    <form onSubmit={handleSubmit}>
                      <div className="search-bar">
                        <input
                          type="text"
                          placeholder="Search"
                          className="form-control"
                          value={values.search}
                          onChange={(e) => {
                            setFieldValue("search", e.target.value);
                            setSearch(e.target.value);
                          }}
                        />
                        <button type="submit" className="search-bar-btn">
                          <img src={searchIcon} alt="" />
                        </button>
                      </div>
                      {touched.search && errors.search && (
                        <div
                          style={{
                            textAlign: "center",
                            color: "red",
                          }}
                        >
                          {errors.search}
                        </div>
                      )}
                    </form>
                  )}
                </Formik>
                {resultShow === true &&
                  searchData?.catecories?.length > 0 &&
                  search !== "" && (
                    <ul className="category-tab-list d-flex flex-wrap">
                      <li className={all === true ? "active" : ""}>
                        <a
                          href="javascript:void(0)"
                          onClick={() => onClickAll()}
                          className="category-tab-link"
                        >
                          All
                        </a>
                      </li>
                      {searchData?.catecories?.map((item, ind) => (
                        <li
                          key={ind}
                          className={
                            categoryName === item?.category_slug &&
                            all === false
                              ? "active"
                              : ""
                          }
                        >
                          <a
                            href="javascript:void(0)"
                            onClick={() =>
                              onHandleCategory(item?.category_slug)
                            }
                            className="category-tab-link"
                          >
                            {item?.category_name}
                          </a>
                        </li>
                      ))}
                    </ul>
                  )}
              </div>
            </div>
          </div>
        </div>
        {/* <!--============================== Content End ==============================--> */}
        {/* <!--============================== Content Start ==============================--> */}
        {resultShow && search !== "" && (
          <div className="content-container article-container">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="filter-content d-flex flex-wrap ">
                    <h5>TOP RESULTS</h5>
                    {articleList.length > 0 && (
                      <div className="filter-box d-flex">
                        <span>Sort by: </span>
                        <select value={sortBy} onChange={handleSortBy}>
                          <option value="">Select</option>
                          <option value="newest">Newest</option>
                          <option value="oldest">Oldest</option>
                        </select>
                      </div>
                    )}
                  </div>
                  <ul className="article-list">
                    {articleList?.map((item, ind) => (
                      <li className="article-item" key={ind}>
                        <div className="article-box d-flex flex-wrap">
                          <div className="article-text">
                            <ol className="breadcrumb">
                              <li className="breadcrumb-item">
                                {item?.category_name}
                              </li>
                              <li
                                className="breadcrumb-item active"
                                aria-current="page"
                              >
                                {" "}
                                {item?.subject_name}
                              </li>
                            </ol>
                            <h3>
                              <a href={"/article/" + item?.slug}>
                                {item?.title}
                              </a>
                            </h3>
                            <p>{item?.sub_title}</p>
                            <div className="post-by">
                              By <span>{item?.author_name}</span>
                            </div>
                            <div
                              className="post-date"
                              style={{ textTransform: "uppercase" }}
                            >
                              {convertDateFormate(item?.publish_date)}
                            </div>
                          </div>
                          <div className="article-thumbnail">
                            <a href={"/article/" + item?.slug}>
                              {isValidUrl(item?.image) ? (
                                <img src={item?.image} alt="" />
                              ) : (
                                <img
                                  src={Config.IMG_URL + "thumb/" + item?.image}
                                  alt=""
                                />
                              )}
                            </a>
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                  {searchLoad ? (
                    <Loading type={"spin"} color={"#000"} />
                  ) : searchData?.data?.next_page_url ? (
                    <div className="load-more text-center pb-5">
                      <a
                        href="javascript:void(0)"
                        onClick={() =>
                          onHandleLoadMore(searchData?.data?.next_page_url)
                        }
                      >
                        Load more result
                      </a>
                    </div>
                  ) : (
                    <>
                      {articleList.length === 0 && (
                        <p className="text-center my-5">No article found</p>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
        {/* <!--============================== Content End ==============================--> */}
      </main>
      {/* <!--============================== Main End ==============================--> */}
      {/* <!--============================== Footer Start ==============================--> */}
      <Footer />
      {/* <!--============================== Footer End ==============================--> */}
    </div>
  );
};

const mapStateToProps = (state) => ({
  searchData: state.search.data,
  searchLoad: state.search.load,
});

const mapDispatchToProps = (dispatch) => ({
  searchDSPCH: (data) => {
    dispatch(ActionCreators.search(data));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Search);
