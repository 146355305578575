import { takeEvery, call, put } from 'redux-saga/effects'
import { PROFILE } from '../actions/type'
import { profile } from '../services/profile.api'
import { ActionCreators } from '../actions'

export const watchProfile = function* () {    
  yield takeEvery(PROFILE, workerProfile)
}

function* workerProfile(action) {
  try {          
    const res = yield call(profile, action.data);
    yield put(ActionCreators.profileSuccess(res))
  } catch (e){
    yield put(ActionCreators.profileFailure(e))
  }
}
