import { takeEvery, call, put } from 'redux-saga/effects'
import { CMS_FOOTER } from '../actions/type'
import { cmsFooter } from '../services/cmsFooter.api'
import { ActionCreators } from '../actions'

export const watchGetCmsFooter = function* () {    
  yield takeEvery(CMS_FOOTER, workerGetCmsFooter)
}

function* workerGetCmsFooter(action) {
  try {   
    const res = yield call(cmsFooter, action.data);
    yield put(ActionCreators.cmsFooterSuccess(res))
  } catch (e){
    yield put(ActionCreators.cmsFooterFailure(e))
  }
}
