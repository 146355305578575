import Config from "../values/Strings/Config";

export const saveArticle = ({ token, article_id }) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token
    },
    body: JSON.stringify({
      article_id,
    }),
  };
  let url = Config.SAVE_ARTICLE_URL;
  return fetch(url, requestOptions)
    .then((res) => res.json())
    .then((res) => res);
};
