import Config from "../values/Strings/Config";

export const getSubject = () => {
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  };
  let url = Config.SUBJECT_URL;
  return fetch(url, requestOptions)
    .then((res) => res.json())
    .then((res) => res);
};
