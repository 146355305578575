import { GET_ALL_VIDEO_ARTICLE, GET_ALL_VIDEO_ARTICLE_SUCCESS, GET_ALL_VIDEO_ARTICLE_ERROR } from './type'

export const getAllVideoArticle = (data) => ({
  type: GET_ALL_VIDEO_ARTICLE,
  data
})

export const getAllVideoArticleSuccess = (data) => ({
  type: GET_ALL_VIDEO_ARTICLE_SUCCESS,
  data
})

export const getAllVideoArticleFailure = (error) => ({
  type: GET_ALL_VIDEO_ARTICLE_ERROR,
  error
})


