import Config from "../values/Strings/Config";

export const resetPassword = ({ password, token }) => {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      password,
      token
    }),
  };
  let url = Config.RESET_PASSWORD_URL;
  return fetch(url, requestOptions)
    .then((res) => res.json())
    .then((res) => res);
};
