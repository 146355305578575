//login logout
export const LOGIN_USER = 'LOGIN_USER'
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS'
export const LOGIN_USER_ERROR = 'LOGIN_USER_ERROR'
export const LOGOUT = 'LOGOUT'

//page data
export const PAGE_DATA = 'PAGE_DATA'
export const PAGE_DATA_SUCCESS = 'PAGE_DATA_SUCCESS'
export const PAGE_DATA_ERROR = 'PAGE_DATA_ERROR'

//get quote for option
export const GET_QUOTE = 'GET_QUOTE'
export const GET_QUOTE_SUCCESS = 'GET_QUOTE_SUCCESS'
export const GET_QUOTE_ERROR = 'GET_QUOTE_ERROR'

//get qna for option
export const GET_QNA = 'GET_QNA'
export const GET_QNA_SUCCESS = 'GET_QNA_SUCCESS'
export const GET_QNA_ERROR = 'GET_QNA_ERROR'

//get article
export const GET_ARTICLE = 'GET_ARTICLE'
export const GET_ARTICLE_SUCCESS = 'GET_ARTICLE_SUCCESS'
export const GET_ARTICLE_ERROR = 'GET_ARTICLE_ERROR'

// register user
export const REGISTER_USER = 'REGISTER_USER'
export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS'
export const REGISTER_USER_ERROR = 'REGISTER_USER_ERROR'

// AUTHOR
export const AUTHOR = 'AUTHOR'
export const AUTHOR_SUCCESS = 'AUTHOR_SUCCESS'
export const AUTHOR_ERROR = 'AUTHOR_ERROR'

// get subject
export const GET_SUBJECT = 'GET_SUBJECT'
export const GET_SUBJECT_SUCCESS = 'GET_SUBJECT_SUCCESS'
export const GET_SUBJECT_ERROR = 'GET_SUBJECT_ERROR'

// get profile
export const PROFILE = 'PROFILE'
export const PROFILE_SUCCESS = 'PROFILE_SUCCESS'
export const PROFILE_ERROR = 'PROFILE_ERROR'

// get profile
export const GET_INDIVIDUAL_SUBJECT = 'GET_INDIVIDUAL_SUBJECT'
export const GET_INDIVIDUAL_SUBJECT_SUCCESS = 'GET_INDIVIDUAL_SUBJECT_SUCCESS'
export const GET_INDIVIDUAL_SUBJECT_ERROR = 'GET_INDIVIDUAL_SUBJECT_ERROR'

// get profile
export const GET_INDIVIDUAL_AUTHOR = 'GET_INDIVIDUAL_AUTHOR'
export const GET_INDIVIDUAL_AUTHOR_SUCCESS = 'GET_INDIVIDUAL_AUTHOR_SUCCESS'
export const GET_INDIVIDUAL_AUTHOR_ERROR = 'GET_INDIVIDUAL_AUTHOR_ERROR'

// get profile
export const GOOGLE_LOGIN = 'GOOGLE_LOGIN'
export const GOOGLE_LOGIN_SUCCESS = 'GOOGLE_LOGIN_SUCCESS'
export const GOOGLE_LOGIN_ERROR = 'GOOGLE_LOGIN_ERROR'
export const GOOGLE_LOGOUT = 'GOOGLE_LOGOUT'

// get profile
export const COMPLETE_ORDER = 'COMPLETE_ORDER'
export const COMPLETE_ORDER_SUCCESS = 'COMPLETE_ORDER_SUCCESS'
export const COMPLETE_ORDER_ERROR = 'COMPLETE_ORDER_ERROR'

// get plan
export const PLAN = 'PLAN'
export const PLAN_SUCCESS = 'PLAN_SUCCESS'
export const PLAN_ERROR = 'PLAN_ERROR'

// profile update
export const PROFILE_UPDATE = 'PROFILE_UPDATE'
export const PROFILE_UPDATE_SUCCESS = 'PROFILE_UPDATE_SUCCESS'
export const PROFILE_UPDATE_ERROR = 'PROFILE_UPDATE_ERROR'

// search
export const SEARCH = 'SEARCH'
export const SEARCH_SUCCESS = 'SEARCH_SUCCESS'
export const SEARCH_ERROR = 'SEARCH_ERROR'

// forget password
export const FORGET_PASSWORD = 'FORGET_PASSWORD'
export const FORGET_PASSWORD_SUCCESS = 'FORGET_PASSWORD_SUCCESS'
export const FORGET_PASSWORD_ERROR = 'FORGET_PASSWORD_ERROR'

// reset password
export const RESET_PASSWORD = 'RESET_PASSWORD'
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS'
export const RESET_PASSWORD_ERROR = 'RESET_PASSWORD_ERROR'

// about us
export const CMS_FOOTER = 'CMS_FOOTER'
export const CMS_FOOTER_SUCCESS = 'CMS_FOOTER_SUCCESS'
export const CMS_FOOTER_ERROR = 'CMS_FOOTER_ERROR'

// save article
export const SAVE_ARTICLE = 'SAVE_ARTICLE'
export const SAVE_ARTICLE_SUCCESS = 'SAVE_ARTICLE_SUCCESS'
export const SAVE_ARTICLE_ERROR = 'SAVE_ARTICLE_ERROR'

// get crossword
export const GET_CROSSWORD = 'GET_CROSSWORD'
export const GET_CROSSWORD_SUCCESS = 'GET_CROSSWORD_SUCCESS'
export const GET_CROSSWORD_ERROR = 'GET_CROSSWORD_ERROR'

// get crossword
export const GET_CROSSWORD_SINGLE = 'GET_CROSSWORD_SINGLE'
export const GET_CROSSWORD_SINGLE_SUCCESS = 'GET_CROSSWORD_SINGLE_SUCCESS'
export const GET_CROSSWORD_SINGLE_ERROR = 'GET_CROSSWORD_SINGLE_ERROR'

// get saved article
export const GET_SAVED_ARTICLE = 'GET_SAVED_ARTICLE'
export const GET_SAVED_ARTICLE_SUCCESS = 'GET_SAVED_ARTICLE_SUCCESS'
export const GET_SAVED_ARTICLE_ERROR = 'GET_SAVED_ARTICLE_ERROR'

// get archive year
export const ARCHIVE_YEAR = 'ARCHIVE_YEAR'
export const ARCHIVE_YEAR_SUCCESS = 'ARCHIVE_YEAR_SUCCESS'
export const ARCHIVE_YEAR_ERROR = 'ARCHIVE_YEAR_ERROR'

// get archive data
export const ARCHIVE_DATA = 'ARCHIVE_DATA'
export const ARCHIVE_DATA_SUCCESS = 'ARCHIVE_DATA_SUCCESS'
export const ARCHIVE_DATA_ERROR = 'ARCHIVE_DATA_ERROR'

// get faq data
export const FAQ = 'FAQ'
export const FAQ_SUCCESS = 'FAQ_SUCCESS'
export const FAQ_ERROR = 'FAQ_ERROR'

// get remove saved article data
export const REMOVE_SAVEDARTICLE = 'REMOVE_SAVEDARTICLE'
export const REMOVE_SAVEDARTICLE_SUCCESS = 'REMOVE_SAVEDARTICLE_SUCCESS'
export const REMOVE_SAVEDARTICLE_ERROR = 'REMOVE_SAVEDARTICLE_ERROR'

// cancel subject 
export const CANCEL_SUBSCRIPTION = 'CANCEL_SUBSCRIPTION'
export const CANCEL_SUBSCRIPTION_SUCCESS = 'CANCEL_SUBSCRIPTION_SUCCESS'
export const CANCEL_SUBSCRIPTION_ERROR = 'CANCEL_SUBSCRIPTION_ERROR'

// get all video article 
export const GET_ALL_VIDEO_ARTICLE = 'GET_ALL_VIDEO_ARTICLE'
export const GET_ALL_VIDEO_ARTICLE_SUCCESS = 'GET_ALL_VIDEO_ARTICLE_SUCCESS'
export const GET_ALL_VIDEO_ARTICLE_ERROR = 'GET_ALL_VIDEO_ARTICLE_ERROR'

