import { PROFILE, PROFILE_SUCCESS, PROFILE_ERROR } from "../actions/type"

const defaultState = {
  load: false,
  data: {},
  error: null,
}

export const profile = (state = defaultState, action) => {
  const response = action.data;
  switch (action.type) {
    case PROFILE:
      return {
        ...state,
        load: true
      }
    case PROFILE_SUCCESS:
      return { 
        ...state, 
        data: response,
        load: false
      }
    case PROFILE_ERROR:
      return { 
        ...state, 
        error: response,
        load: false,
      }
    default:
      return state
  }
}


