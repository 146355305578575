import { takeEvery, call, put } from 'redux-saga/effects'
import { GET_ALL_VIDEO_ARTICLE } from '../actions/type'
import { getAllVideoArticle } from '../services/getAllVideoArticle.api'
import { ActionCreators } from '../actions'

export const watchGetAllVideoArticle = function* () {    
  yield takeEvery(GET_ALL_VIDEO_ARTICLE, workerGetAllVideoArticle)
}

function* workerGetAllVideoArticle(action) {
  try {   
    const res = yield call(getAllVideoArticle, action.data);
    yield put(ActionCreators.getAllVideoArticleSuccess(res))
  } catch (e){
    yield put(ActionCreators.getAllVideoArticleFailure(e))
  }
}
