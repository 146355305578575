import React, { useEffect, useRef } from "react";
import { Helmet } from "react-helmet";
import { Footer, Header } from "../components";
import Config from "../values/Strings/Config";
import { connect } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { ActionCreators } from "../actions";
import { useAlert } from "react-alert";
import { GoogleLogout } from "react-google-login";
import { gapi } from "gapi-script";
import { confirmAlert } from "react-confirm-alert"; // Import
import profileImg from "../assets/images/profile.svg";
import headerShapeRight from "../assets/images/header-shape-right.svg";
import headerShapeLeft from "../assets/images/header-shape-left.svg";
import goodIcon from "../assets/images/good-icon.png";
import closeIcon from "../assets/images/close.svg";
import { fixedHeader } from "../values/utils";

const SubscriptionActive = ({
  logoutDSPCH,
  profileData,
  userData,
  faqDSPCH,
  faqData,
  cancelSubscriptionDSPCH,
  cancelSubscriptionData,
}) => {
  const alert = useAlert();
  let navigate = useNavigate();
  const cancelSubscriptionDataRef = useRef(cancelSubscriptionData);

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }, 100);
    fixedHeader();
    getFaq();
  }, []);

  useEffect(() => {
    if (cancelSubscriptionData !== cancelSubscriptionDataRef.current) {
      if (cancelSubscriptionData?.success === true) {
        navigate("/profile");
        alert.info(
          <div style={{ textTransform: "initial" }}>
            {cancelSubscriptionData?.message}
          </div>
        );
      } else {
        navigate("/profile");
        alert.info(
          <div style={{ textTransform: "initial" }}>
            {cancelSubscriptionData?.message}
          </div>
        );
      }
    }
  }, [cancelSubscriptionData, cancelSubscriptionDataRef]);

  const getFaq = () => {
    faqDSPCH();
  };

  const logout = () => {
    alert.info(
      <div style={{ textTransform: "initial" }}>
        You have successfully signed out!
      </div>
    );
    navigate("/");
    logoutDSPCH();
  };

  const onSuccess = () => {
    const auth2 = gapi.auth2.getAuthInstance();

    if (auth2 != null) {
      auth2.signOut().then(auth2.disconnect().then(logout()));
    }
  };

  const onHandleCancel = () => {
    if (userData?.success === true && profileData?.success) {
      confirmAlert({
        message: "Are you sure want to cancel this subscription?",
        overlayClassName: "overlay-custom-class-name",
        buttons: [
          {
            label: "Yes",
            onClick: () =>
              cancelSubscriptionDSPCH({
                token: userData?.data?.token,
                stripe_subscription_id:
                  profileData?.data?.subscription["stripe_subscription_id"],
              }),
          },
          {
            label: "No",
            onClick: () => console.log("Click No"),
          },
        ],
      });
    }
  };

  return (
    <div>
      <Helmet>
        <title>Subscription Active - Object Magazine</title>
        <meta name="description" content="{}" />
      </Helmet>

      <Header />

      {/* <!--============================== Main Start ==============================--> */}
      {/* <!--============================== Main Start ==============================--> */}
      <main id="main">
        {/* <!--============================== Content Start ==============================--> */}
        <div className="content-container profile-container">
          <div className="container">
            <div className="row">
              {profileData?.success === true && (
                <div className="col-lg-12">
                  <div className="profile-content d-flex flex-wrap">
                    <div className="profile-img">
                      <img
                        src={
                          profileData?.data?.user?.image
                            ? `${Config.USER_IMG_URL}${profileData?.data?.user?.image}`
                            : profileImg
                        }
                        alt="userphoto"
                      />{" "}
                    </div>
                    <div className="profile-deatail">
                      <h6>
                        PROFILE DETAILS
                        <span>
                          <Link to="/profile-edit" className="read-more-btn">
                            Edit
                          </Link>
                        </span>
                      </h6>
                      <h3>{profileData?.data?.user?.name}</h3>
                      <p>{profileData?.data?.user?.email}</p>
                      {userData?.success === true &&
                      userData?.data?.googleId === "" ? (
                        <button className=" " onClick={() => logout()}>
                          sign out
                        </button>
                      ) : (
                        <GoogleLogout
                          clientId={Config.GOOGLE_CLIENT_ID}
                          render={(renderProps) => (
                            <button
                              className="btn btn-default "
                              onClick={renderProps.onClick}
                            >
                              sign out
                            </button>
                          )}
                          buttonText="Logout"
                          onLogoutSuccess={onSuccess}
                        ></GoogleLogout>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        {/* <!--============================== Content End ==============================--> */}
        {/* <!--============================== Content Start ==============================--> */}
        <div className="content-container p-0">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="subscription-tabs-content">
                  <ul className="subscription-tabs d-flex justify-content-between">
                    <li>
                      <Link to="/profile" className="subscription-tabs-link">
                        SAVED ARTICLES
                      </Link>
                    </li>
                    <li className="active">
                      <Link
                        to="/subscription-active"
                        className="subscription-tabs-link"
                      >
                        SUBSCRIPTION DETAILS
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!--============================== Content End ==============================-->  */}
        {/* <!--============================== Content Start ==============================--> */}
        <div className="content-container p-0">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                {
                  console.log("userData", userData)
                }
                {userData?.success === true && (
                  <div className="tab-subscription-box">
                    <div className="subscription-heading d-none d-md-block">
                      <h3>You Have An Active Subscription Plan</h3>
                    </div>
                    <div className="active-tab-subscription">
                      <div className="ats-plane-box">
                        <div className="subscription-heading d-md-none">
                          <h3>You Have An Active Subscription Plan</h3>
                        </div>
                        <div className="ats-plane">
                          <div className="ats-icon">
                            <img src={goodIcon} alt="" />{" "}
                          </div>
                          <h4>
                            {profileData?.data?.subscription?.plan_name}{" "}
                            <span>
                              at just Rs.
                              {parseFloat(
                                profileData?.data?.subscription?.plan_amount /
                                  30
                              ).toFixed(2)}
                              /DAY
                            </span>
                          </h4>
                          <h5>
                            {" "}
                            <span> ONLY</span> Rs.{" "}
                            {profileData?.data?.subscription?.plan_amount}
                          </h5>
                        </div>
                      </div>
                      <a
                        href="javascript:void(0)"
                        role="button"
                        onClick={onHandleCancel}
                        className="btn btn-lg btn-default btn-block"
                      >
                        CANCEL
                      </a>
                      {faqData?.success === true &&
                        faqData?.data.map((item, ind) => (
                          <>
                            <p>
                              <a
                                data-bs-toggle="modal"
                                data-bs-target={"#offerQuestionModal" + ind}
                                href=""
                              >
                                {item?.title}
                              </a>
                            </p>

                            <div
                              className="modal fade subsc-modal"
                              id={"offerQuestionModal" + ind}
                              tabindex="-1"
                              aria-labelledby="offerQuestionModalLabel"
                              aria-hidden="true"
                            >
                              <div className="modal-dialog modal-dialog-centered">
                                <div className="modal-content">
                                  <div className="modal-body">
                                    <button
                                      type="button"
                                      className="btn-close"
                                      data-bs-dismiss="modal"
                                      aria-label="Close"
                                    >
                                      <img src={closeIcon} alt="" />
                                    </button>
                                    <div className="student-plane">
                                      <h4>{item?.title}</h4>
                                      <div
                                        dangerouslySetInnerHTML={{
                                          __html: item?.description,
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        ))}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        {/* <!--============================== Content End ==============================-->  */}
        {/* <!--============================== Content Start ==============================--> */}
        <div className="content-container">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="end-section">
                  <h5>
                    <span className="left">
                      <img src={headerShapeRight} alt="" />
                    </span>
                    end
                    <span className="right">
                      <img src={headerShapeLeft} alt="" />
                    </span>
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!--============================== Content End ==============================--> */}
      </main>
      {/* <!--============================== Main End ==============================--> */}
      {/* <!--============================== Footer Start ==============================--> */}
      <Footer />
      {/* <!--============================== Footer End ==============================--> */}
    </div>
  );
};

const mapStateToProps = (state) => ({
  userData: state.login.user,
  profileData: state.profile.data,
  faqData: state.faq.data,
  cancelSubscriptionData: state.cancelSubscription.data,
});

const mapDispatchToProps = (dispatch) => ({
  logoutDSPCH: () => {
    dispatch(ActionCreators.logoutUser());
  },
  faqDSPCH: () => {
    dispatch(ActionCreators.faq());
  },
  cancelSubscriptionDSPCH: (data) => {
    dispatch(ActionCreators.cancelSubscription(data));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionActive);
