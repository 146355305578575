import { SEARCH, SEARCH_SUCCESS, SEARCH_ERROR } from './type'

export const search = (data) => ({
  type: SEARCH,
  data
})

export const searchSuccess = (data) => ({
  type: SEARCH_SUCCESS,
  data
})

export const searchFailure = (error) => ({
  type: SEARCH_ERROR,
  error
})


