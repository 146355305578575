
import Config from "../values/Strings/Config";

export const profileUpdate = ({ formData, token }) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Authorization": "Bearer "+ token 
    },
    body: formData
  };
  let url = Config.PROFILE_UPDATE_URL;
  
  return fetch(url, requestOptions)
    .then((res) => res.json())
    .then((res) => res);
};

