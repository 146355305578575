import { takeEvery, call, put } from 'redux-saga/effects'
import { RESET_PASSWORD } from '../actions/type'
import { resetPassword } from '../services/resetPassword.api'
import { ActionCreators } from '../actions'

export const watchResetPassword = function* () {    
  yield takeEvery(RESET_PASSWORD, workerResetPassword)
}

function* workerResetPassword(action) {
  try {   
    const res = yield call(resetPassword, action.data);
    yield put(ActionCreators.resetPasswordSuccess(res))
  } catch (e){
    yield put(ActionCreators.resetPasswordFailure(e))
  }
}
