import React, { useEffect, useState, useRef } from "react";
import { Helmet } from "react-helmet";
import { Footer, Header, Loading } from "../components";
import Config from "../values/Strings/Config";
import { ActionCreators } from "../actions";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import moment from "moment";
import { convertDateFormate, fixedHeader, isValidUrl } from "../values/utils";
import headerShapeRight from "../assets/images/header-shape-right.svg";
import headerShapeLeft from "../assets/images/header-shape-left.svg";

const Archive = ({
  archiveYearDSPCH,
  archiveYearData,
  archiveYearLoad,
  archiveDataDSPCH,
  archiveData,
  archiveDataLoad,
  userData,
}) => {
  const [sortByYear, setSortByYear] = useState("");
  const [sortByMonth, setSortByMonth] = useState("");
  const [articleList, setArticleList] = useState([]);

  const [showData, setshowData] = useState(false);
  const [showMonth, setShowMonth] = useState(false);
  const archiveDataRef = useRef(archiveData);

  useEffect(() => {
    archiveYearDSPCH();
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }, 100);
    fixedHeader();
  }, []);

  useEffect(() => {
    if (archiveData !== archiveDataRef.current) {
      if (archiveData?.success === true && archiveData?.data?.data) {
        setArticleList((articleList) => [
          ...articleList,
          ...archiveData?.data?.data,
        ]);
      } else {
        setArticleList([]);
      }
    }
  }, [archiveData, archiveDataRef]);

  const handleYear = (event) => {
    setSortByYear(event.target.value);
    setShowMonth(true);
    setSortByMonth("");
    setshowData(false);
  };

  const handleMonth = (event) => {
    setSortByMonth(event.target.value);
    setArticleList([]);
    setshowData(true);

    archiveDataDSPCH({
      year: sortByYear,
      month: event.target.value,
    });
  };

  const onHandleLoadMore = (nextPageUrl) => {
    archiveDataDSPCH({
      year: sortByYear,
      month: sortByMonth,
      nextPageUrl: nextPageUrl,
    });
  };

  return (
    <div>
      <Helmet>
        <title>Archives - Object Magazine</title>
        <meta name="description" content={""} />
      </Helmet>

      <Header />

      {/* <!--============================== Main Start ==============================--> */}
      <main id="main">
        {/* <!--============================== Content Start ==============================--> */}
        <div className="content-container contact-container pb-5">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div>
                  <h2 className="text-center">ARCHIVES</h2>
                  <div className="filter-content d-flex flex-wrap flex-row-reverse">
                    <div className="filter-box d-flex">
                      <span>Year: </span>
                      <select
                        value={sortByYear}
                        onChange={handleYear}
                        name="year"
                      >
                        <option value="">Select</option>
                        {archiveYearData?.data?.years &&
                          Object.values(archiveYearData?.data?.years)?.map(
                            (year) => (
                              <option key={year} value={year}>
                                {year}
                              </option>
                            )
                          )}
                      </select>

                      {showMonth === true && sortByYear !== "" && (
                        <>
                          <span className="ps-3"> Month: </span>
                          <select
                            value={sortByMonth}
                            onChange={handleMonth}
                            name="month"
                          >
                            <option value="">Select</option>
                            {Object.values(
                              archiveYearData?.data?.y_months[sortByYear]
                            )?.map((month) => (
                              <option key={month} value={month}>
                                {moment(month).format("MMMM")}
                              </option>
                            ))}
                          </select>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              {showData === true && (
                <>
                  <ul className="article-list">
                    {articleList?.length > 0 &&
                      articleList?.map((item, idx) => (
                        <li className="article-item" key={idx}>
                          <div className="article-box d-flex flex-wrap">
                            <div className="article-text">
                              <ol className="breadcrumb">
                                <li
                                  className="breadcrumb-item active"
                                  aria-current="page"
                                >
                                  {item?.categories}
                                </li>
                              </ol>
                              <h3>
                                <Link to={"/article/" + item?.slug}>
                                  {item?.title}
                                </Link>
                              </h3>
                              <p>{item?.sub_title}</p>
                              <div className="post-by">
                                By <span>{item?.author_name}</span>
                              </div>
                              <div
                                className="post-date"
                                style={{ textTransform: "uppercase" }}
                              >
                                {convertDateFormate(item?.publish_date)}
                              </div>
                            </div>
                            <div className="article-thumbnail">
                              {item?.mediaData !== null && (
                                <Link to={"/article/" + item?.slug}>
                                  {isValidUrl(item?.image) ? (
                                    <img src={item?.image} alt="" />
                                  ) : (
                                    <img
                                      src={Config.IMG_URL + item?.image}
                                      alt=""
                                    />
                                  )}
                                </Link>
                              )}
                            </div>
                          </div>
                        </li>
                      ))}
                  </ul>
                  {archiveDataLoad ? (
                    <Loading type={"spin"} color={"#000"} />
                  ) : archiveData?.data?.next_page_url ? (
                    <div className="load-more text-center">
                      <a
                        href="javascript:void(0)"
                        role="button"
                        onClick={() =>
                          onHandleLoadMore(archiveData?.data?.next_page_url)
                        }
                      >
                        Load more results
                      </a>
                    </div>
                  ) : (
                    <>
                      {articleList.length === 0 && (
                        <p className="text-center my-5">No article found</p>
                      )}
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        </div>

        {showData === true &&
          archiveData.success === true &&
          archiveDataLoad === false && (
            <div className="content-container">
              <div className="container">
                <div className="row">
                  <div className="col-md-12">
                    <div className="end-section">
                      <h5>
                        <span className="left">
                          <img src={headerShapeRight} alt="" />
                        </span>
                        end
                        <span className="right">
                          <img src={headerShapeLeft} alt="" />
                        </span>
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
      </main>

      <Footer />
    </div>
  );
};

const mapStateToProps = (state) => ({
  archiveYearData: state.archiveYear.data,
  archiveYearLoad: state.archiveYear.load,
  archiveData: state.archiveData.data,
  archiveDataLoad: state.archiveData.load,
  userData: state.login.user,
});

const mapDispatchToProps = (dispatch) => ({
  archiveYearDSPCH: (data) => {
    dispatch(ActionCreators.archiveYear(data));
  },
  archiveDataDSPCH: (data) => {
    dispatch(ActionCreators.archiveData(data));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Archive);
