import { SAVE_ARTICLE, SAVE_ARTICLE_SUCCESS, SAVE_ARTICLE_ERROR } from './type'

export const saveArticle = (data) => ({
  type: SAVE_ARTICLE,
  data
})

export const saveArticleSuccess = (data) => ({
  type: SAVE_ARTICLE_SUCCESS,
  data
})

export const saveArticleFailure = (error) => ({
  type: SAVE_ARTICLE_ERROR,
  error
})


