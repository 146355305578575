import { GET_INDIVIDUAL_SUBJECT, GET_INDIVIDUAL_SUBJECT_SUCCESS, GET_INDIVIDUAL_SUBJECT_ERROR } from './type'

export const getIndividualSubject = (data) => ({
  type: GET_INDIVIDUAL_SUBJECT,
  data
})

export const getIndividualSubjectSuccess = (data) => ({
  type: GET_INDIVIDUAL_SUBJECT_SUCCESS,
  data
})

export const getIndividualSubjectFailure = (error) => ({
  type: GET_INDIVIDUAL_SUBJECT_ERROR,
  error
})


