import { PAGE_DATA, PAGE_DATA_SUCCESS, PAGE_DATA_ERROR } from './type'

export const pageData = (data) => ({
  type: PAGE_DATA,
  data
})

export const pageDataSuccess = (data) => ({
  type: PAGE_DATA_SUCCESS,
  data
})

export const pageDataFailure = (error) => ({
  type: PAGE_DATA_ERROR,
  error
})


