import { takeEvery, call, put } from 'redux-saga/effects'
import { GET_INDIVIDUAL_AUTHOR } from '../actions/type'
import { getIndividualAuthor } from '../services/getIndividualAuthor.api'
import { ActionCreators } from '../actions'

export const watchGetIndividualAuthor = function* () {    
  yield takeEvery(GET_INDIVIDUAL_AUTHOR, workerGetIndividualAuthor)
}

function* workerGetIndividualAuthor(action) {
  try {     
    const res = yield call(getIndividualAuthor, action.data);
    yield put(ActionCreators.getIndividualAuthorSuccess(res));
  } catch (e){
    yield put(ActionCreators.getIndividualAuthorFailure(e));
  }
}
