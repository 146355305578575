import React, { useState, useEffect, useRef } from "react";
import { Helmet } from "react-helmet";
import { Footer, Header, Loading } from "../components";
import Config from "../values/Strings/Config";
import { ActionCreators } from "../actions";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
} from "react-share";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";
import headerShapeRight from "../assets/images/header-shape-right.svg";
import headerShapeLeft from "../assets/images/header-shape-left.svg";
import crosswordImg from "../assets/images/crosword.png";
import { convertDateFormate, fixedHeader } from "../values/utils";
import Tesseract from 'tesseract.js';
import CrosswordExtractor from "../components/crossword/CrosswordExtractor";


const CrosswordSingle = ({
  dailyCrosswordSingleDSPCH,
  getCrosswordSingleData,
  getCrosswordSingleLoad,
}) => {
  const { slug } = useParams();
  const pageURL = window.location.href;

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }, 100);
    fixedHeader();
    getDailyCrosswordSingle();
  }, [slug]);

  const [crosswordData, setCrosswordData] = useState([]);
  const canvasRef = useRef(null);
  //const crosswordImageUrl = Config.CROSSWORD_URL + getCrosswordSingleData?.data?.image;
  //const crosswordImageUrl = `https://cors-anywhere.herokuapp.com/${Config.CROSSWORD_URL}${getCrosswordSingleData?.data?.image}`;



  const getDailyCrosswordSingle = () => {
    dailyCrosswordSingleDSPCH({
      slug: slug,
    });
  };

  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          arrows: true,
          slidesToShow: 2,
          slidesToScroll: 1,
          dots: false,
          adaptiveHeight: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          arrows: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
          adaptiveHeight: false,
        },
      },
    ],
  };




  return (
    <div>
      <Helmet>
        <title>
          {getCrosswordSingleData?.success === true &&
            getCrosswordSingleData?.data?.meta_title}
        </title>
        <meta
          name="description"
          content={
            getCrosswordSingleData?.success === true &&
            getCrosswordSingleData?.data?.meta_description
          }
        />
      </Helmet>

      <Header />

      <main id="main">

        {/* <!--============================== Content Start ==============================--> */}
        <div className="content-container less-pad pb-0">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="page-heading">
                  <h1>{getCrosswordSingleData?.data?.title}</h1>
                  <div className="news-post-meta d-flex justify-content-center d-none d-md-flex">
                    {/* <span className="news-post-cat">Crime </span> */}
                    <span className="news-post-author">
                      {" "}
                      by {
                        getCrosswordSingleData?.data?.author_detail?.name
                      }{" "}
                    </span>
                    {/* <span className="news-post-date">
                            {moment(
                              getCrosswordSingleData?.data?.publish_date
                            ).format("MMMM D, YYYY")}{" "}
                          </span> */}
                  </div>
                </div>
                <div className="filter-content d-flex flex-wrap ">
                  {/* <h5>THEME- MINIMALISM</h5> */}

                </div>

                {/*<div>
                  <CrosswordExtractor getCrosswordSingleData={getCrosswordSingleData} />
                </div>*/}
                {/* Render the crossword data */}
                <div className="crossword-grid">
                  {crosswordData.map((row, rowIndex) => (
                    <div className="row" key={rowIndex}>
                      {row.map((cell, cellIndex) => (
                        <div
                          className={`cell ${cell === 0 ? 'black-cell' : 'white-cell'}`}
                          key={cellIndex}
                        >
                          {/* Render cells */}
                        </div>
                      ))}
                    </div>
                  ))}
                </div>
                {getCrosswordSingleData?.data?.iframe ? (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: getCrosswordSingleData?.data?.iframe,
                    }}
                  />
                ) : (
                  <div className="content-container article-single-container">
                    <div className="container">
                      <div className="row">
                        <div className="col-lg-12">
                          <p className="text-center">Records not found!</p>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        {/* <!--============================== Content End ==============================--> */}

        {/* <!--============================== Content Start ==============================--> */}
        <div className="content-container pb-0">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="post-author-container d-flex flex-wrap">
                  <div className="post-author-content">
                    <h5>Author</h5>
                    <div className="post-auther">
                      <Link
                        to={
                          "/author/" +
                          getCrosswordSingleData?.data?.author_detail?.slug
                        }
                        className="post-auther-img"
                      >
                        <img
                          src={
                            Config.AUTHOR_IMG_URL +
                            getCrosswordSingleData?.data?.author_detail?.image
                          }
                          alt=""
                        />
                      </Link>
                      <div className="post-author-name">
                        <h3>
                          {getCrosswordSingleData?.data?.author_detail?.name}
                        </h3>
                        <h6>
                          {
                            getCrosswordSingleData?.data?.author_detail
                              ?.designation
                          }
                        </h6>
                        <p>
                          {getCrosswordSingleData?.data?.author_detail?.about}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="share-post text-end">
                    <h5>Share</h5>
                    <ul className="share-post-list d-flex">
                      <li>
                        <FacebookShareButton
                          url={pageURL}
                          quote={getCrosswordSingleData?.data?.title}
                          title={getCrosswordSingleData?.data?.title}
                        >
                          <a href="#!" target="_blank">
                            <i
                              className="fab fa-facebook-f"
                              aria-hidden="true"
                            ></i>
                          </a>
                        </FacebookShareButton>
                      </li>
                      <li>
                        <TwitterShareButton
                          url={pageURL}
                          quote={getCrosswordSingleData?.data?.title}
                          title={getCrosswordSingleData?.data?.title}
                        >
                          <a href="#!" target="_blank">
                            <i
                              className="fab fa-twitter"
                              aria-hidden="true"
                            ></i>
                          </a>
                        </TwitterShareButton>
                      </li>

                      <li>
                        <LinkedinShareButton
                          url={pageURL}
                          quote={getCrosswordSingleData?.data?.title}
                          title={getCrosswordSingleData?.data?.title}
                        >
                          <a href="#!" target="_blank">
                            <i
                              className="fab fa-linkedin-in"
                              aria-hidden="true"
                            ></i>
                          </a>
                        </LinkedinShareButton>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!--============================== Content End ==============================--> */}
        {/* <!--============================== Content Start ==============================--> */}

        <div className="content-container crossword-slide-container pb-0">
          <div className="container">
            {getCrosswordSingleData?.data?.popular.length > 3 && (
              <div className="row">
                <div className="col-lg-12">
                  <div className="section-heading">
                    <h5>
                      <span className="left">
                        <img src={headerShapeRight} alt="" />
                      </span>
                      popular
                      <span className="right">
                        <img src={headerShapeLeft} alt="" />
                      </span>
                    </h5>
                  </div>
                  <div className="slider-container">
                    <Slider
                      {...settings}
                      className="grid-list pb-4 popular_slider"
                    >
                      {getCrosswordSingleData?.data?.popular?.length > 3 &&
                        getCrosswordSingleData?.data?.popular?.map(
                          (item, ind) => (
                            <div className="grid-item" key={ind}>
                              <div className="grid-box">
                                <Link
                                  to={"/crossword/" + item?.slug}
                                  className="grid-img"
                                >
                                  <img src={crosswordImg} alt="popularimage" />
                                </Link>
                                <Link to={"/article/" + item?.slug}>
                                  <h4>{item?.title}</h4>
                                </Link>
                                <div className="post-by">
                                  By <span>{item?.author_name}</span>
                                </div>
                                <p style={{ textTransform: "uppercase" }}>
                                  {convertDateFormate(item?.publish_date)}
                                </p>
                              </div>
                            </div>
                          )
                        )}
                    </Slider>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        {/* <!--============================== Content End ==============================--> */}
      </main>

      <Footer />
      {/* <!--============================== Main End ==============================--> */}
    </div>
  );
};

const mapStateToProps = (state) => ({
  getCrosswordSingleData: state.getCrosswordSingle.data,
  getCrosswordSingleLoad: state.getCrosswordSingle.load,
});

const mapDispatchToProps = (dispatch) => ({
  dailyCrosswordSingleDSPCH: (data) => {
    dispatch(ActionCreators.getCrosswordSingle(data));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(CrosswordSingle);
