import { takeEvery, call, put } from 'redux-saga/effects'
import { GET_QNA } from '../actions/type'
import { getQna } from '../services/getQna.api'
import { ActionCreators } from '../actions'

export const watchGetQna = function* () {    
  yield takeEvery(GET_QNA, workerGetQna)
}

function* workerGetQna(action) {
  try {          
    const res = yield call(getQna, action.data);
    yield put(ActionCreators.getQnaSuccess(res))
  } catch (e){
    yield put(ActionCreators.getQnaFailure(e))
  }
}
