import { ARCHIVE_YEAR, ARCHIVE_YEAR_SUCCESS, ARCHIVE_YEAR_ERROR, ARCHIVE_DATA, ARCHIVE_DATA_SUCCESS, ARCHIVE_DATA_ERROR } from './type'

export const archiveYear = () => ({
  type: ARCHIVE_YEAR,
})

export const archiveYearSuccess = (data) => ({
  type: ARCHIVE_YEAR_SUCCESS,
  data
})

export const archiveYearFailure = (error) => ({
  type: ARCHIVE_YEAR_ERROR,
  error
})


export const archiveData = (data) => ({
  type: ARCHIVE_DATA,
  data
})

export const archiveDataSuccess = (data) => ({
  type: ARCHIVE_DATA_SUCCESS,
  data
})

export const archiveDataFailure = (error) => ({
  type: ARCHIVE_DATA_ERROR,
  error
})


