
import * as login from './login.action'
import * as pageData from './pageData.action'
import * as getQuote from './getQuote.action'
import * as getQna from './getQna.action'
import * as getArticle from './getArticle.action'
import * as register from './register.action'
import * as author from './author.action'
import * as getSubject from './getSubject.action'
import * as profile from './profile.action'
import * as getIndividualSubject from './getIndividualSubject.action'
import * as getIndividualAuthor from './getIndividualAuthor.action'
import * as googleLogin from './googleLogin.action'
import * as completeOrder from './completeOrder.action'
import * as plan from './plan.action'
import * as profileUpdate from './profileUpdate.action'
import * as search from './search.action'
import * as forgetPassword from './forgetPassword.action'
import * as resetPassword from './resetPassword.action'
import * as cmsFooter from './cmsFooter.action'
import * as saveArticle from './saveArticle.action'
import * as getCrossword from './getCrossword.action'
import * as getCrosswordSingle from './getCrosswordSingle.action'
import * as getSavedArticle from './getSavedArticle.action'
import * as archive from './archive.action'
import * as faq from './faq.action'
import * as removeSavedArticle from './removeSavedArticle.action'
import * as cancelSubscription from './cancelSubscription.action'
import * as getAllVideoArticle from './getAllVideoArticle.action'

export const ActionCreators = {
  ...login,
  ...pageData,
  ...getQuote,
  ...getQna,
  ...getArticle,
  ...register,
  ...author,
  ...getSubject,
  ...profile,
  ...getIndividualSubject,
  ...getIndividualAuthor,
  ...googleLogin,
  ...completeOrder,
  ...plan,
  ...profileUpdate,
  ...search,
  ...forgetPassword,
  ...resetPassword,
  ...cmsFooter,
  ...saveArticle,
  ...getCrossword,
  ...getCrosswordSingle,
  ...getSavedArticle,
  ...archive,
  ...faq,
  ...removeSavedArticle,
  ...cancelSubscription,
  ...getAllVideoArticle
}