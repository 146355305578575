import React, { useRef, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { ActionCreators } from "../../actions";
import { Formik } from "formik";
import * as Yup from "yup";
import { Header, Footer } from "../../components";
import Config from "../../values/Strings/Config";
import { Link, useNavigate } from "react-router-dom";
import { GoogleLogin } from "react-google-login";
import { gapi } from "gapi-script";
import { useAlert } from "react-alert";
import googleIcon from "../../assets/images/google-icon.svg";
import { fixedHeader } from "../../values/utils";

import thincross from "../../assets/icons/thincross.svg";


const ForgotPasswordPopUp = ({
    forgetPasswordDSPCH,
    forgetPasswordData,
    forgetPasswordLoad,
    closeModal
}) => {
    const forgetPasswordDataRef = useRef(forgetPasswordData);
    const alert = useAlert();

    useEffect(() => {
        setTimeout(() => {
            window.scrollTo({
                top: 0,
                behavior: "smooth",
            });
        }, 100);
        fixedHeader();
    }, []);

    useEffect(() => {
        if (forgetPasswordData !== forgetPasswordDataRef.current) {
            alert.show(forgetPasswordData?.message);
        }
    }, [forgetPasswordData, forgetPasswordDataRef]);

    const onSubmit = (fields) => {
        forgetPasswordDSPCH({
            email: fields.email,
        });
    };

    return (
        <div className="content-container">
            <div className="container">
                <div className="row">
                    <div className="col-md-6">
                        <div className="cc-content-box position-relative loginpopup">
                            <button className="close-modal" onClick={closeModal}>
                                <img src={thincross}
                                    alt="close" className="thin-cross" />
                            </button> {/* Close button */}

                            <div className="login-form-modal loginpop-form forgot-form-modal">
                                <div className="forgot-password-texts">
                                    <h4>Forgot Password?</h4>
                                    <p>
                                        Please mention a verified email-id
                                        and we will send you a link.
                                    </p>
                                </div>
                                <div className="member-form">
                                    <Formik
                                        initialValues={{
                                            email: "",
                                        }}
                                        validationSchema={Yup.object().shape({
                                            email: Yup.string()
                                                .email("Email is invalid")
                                                .required("Email is required"),
                                        })}
                                        onSubmit={(fields, { resetForm }) => {
                                            resetForm();
                                            onSubmit(fields);
                                        }}
                                    >
                                        {({
                                            errors,
                                            touched,
                                            setFieldValue,
                                            values,
                                            handleSubmit,
                                            isSubmitting,
                                        }) => (
                                            <form onSubmit={handleSubmit}>
                                                <div className="form-group">
                                                    <input
                                                        type="email"
                                                        className="form-control "
                                                        id="email"
                                                        placeholder="Email Address"
                                                        value={values.email}
                                                        onChange={(e) =>
                                                            setFieldValue("email", e.target.value)
                                                        }
                                                    />
                                                </div>
                                                {touched.email && errors.email && (
                                                    <div
                                                        style={{
                                                            textAlign: "left",
                                                            color: "red",
                                                            marginBottom: 10,
                                                            fontSize: 12,
                                                        }}
                                                    >
                                                        {errors.email}
                                                    </div>
                                                )}

                                                <div className="form-group">
                                                    <button
                                                        type="submit"
                                                        disabled={isSubmitting}
                                                        className="btn  btn-default"
                                                    >
                                                        {forgetPasswordLoad ? "Loading..." : "SEND"}
                                                    </button>
                                                </div>
                                            </form>
                                        )}
                                    </Formik>
                                </div>
                            </div>
                            <div className="forgot-pass-outer text-center"
                                onClick={closeModal}
                            >
                                <Link className="forgot-pass" to="">
                                    Sign In
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
const mapStateToProps = (state) => ({
    forgetPasswordData: state.forgetPassword.data,
    forgetPasswordLoad: state.forgetPassword.load,
});

const mapDispatchToProps = (dispatch) => ({
    forgetPasswordDSPCH: (data) => {
        dispatch(ActionCreators.forgetPassword(data));
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPasswordPopUp);