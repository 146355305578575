import { takeEvery, call, put } from 'redux-saga/effects'
import { GET_SUBJECT } from '../actions/type'
import { getSubject } from '../services/getSubject.api'
import { ActionCreators } from '../actions'

export const watchGetSubject = function* () {    
  yield takeEvery(GET_SUBJECT, workerGetSubject)
}

function* workerGetSubject(action) {
  try {          
    const res = yield call(getSubject, action.data);
    yield put(ActionCreators.getSubjectSuccess(res))
  } catch (e){
    yield put(ActionCreators.getSubjectFailure(e))
  }
}
