import { GET_QNA, GET_QNA_SUCCESS, GET_QNA_ERROR } from './type'

export const getQna = (data) => ({
  type: GET_QNA,
  data
})

export const getQnaSuccess = (data) => ({
  type: GET_QNA_SUCCESS,
  data
})

export const getQnaFailure = (error) => ({
  type: GET_QNA_ERROR,
  error
})


