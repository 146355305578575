import { takeEvery, call, put } from 'redux-saga/effects'
import { LOGIN_USER } from '../actions/type'
import { loginUser } from '../services/login.api'
import { ActionCreators } from '../actions'

export const watchLogin = function* () {    
  yield takeEvery(LOGIN_USER, workerLogin)
}

function* workerLogin(action) {
  try {    
    const res = yield call(loginUser, action.data);
    yield put(ActionCreators.loginUserSuccess(res));
  } catch (e){
    yield put(ActionCreators.loginUsersFailure(e));
  }
}
