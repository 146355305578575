import { ARCHIVE_YEAR, ARCHIVE_YEAR_SUCCESS, ARCHIVE_YEAR_ERROR, ARCHIVE_DATA, ARCHIVE_DATA_SUCCESS, ARCHIVE_DATA_ERROR } from "../actions/type"

const defaultState = {
  load: false,
  data: {},
  error: null,
}

export const archiveYear = (state = defaultState, action) => {
  const response = action.data;
  switch (action.type) {
    case ARCHIVE_YEAR:
      return {
        ...state,
        load: true
      }
    case ARCHIVE_YEAR_SUCCESS:
      return { 
        ...state, 
        data: response,
        load: false
      }
    case ARCHIVE_YEAR_ERROR:
      return { 
        ...state, 
        error: response,
        load: false,
      }
    default:
      return state
  }
}

const defaultState2 = {
  load: false,
  data: {},
  error: null,
}

export const archiveData = (state = defaultState2, action) => {
  const response = action.data;

  switch (action.type) {
    case ARCHIVE_DATA:
      return {
        ...state,
        load: true
      }
    case ARCHIVE_DATA_SUCCESS:
      return { 
        ...state, 
        data: response,
        load: false
      }
    case ARCHIVE_DATA_ERROR:
      return { 
        ...state, 
        error: response,
        load: false,
      }
    default:
      return state
  }
}


