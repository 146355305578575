import { takeEvery, call, put } from 'redux-saga/effects'
import { FORGET_PASSWORD } from '../actions/type'
import { forgetPassword } from '../services/forgetPassword.api'
import { ActionCreators } from '../actions'

export const watchForgetPassword = function* () {    
  yield takeEvery(FORGET_PASSWORD, workerForgetPassword)
}

function* workerForgetPassword(action) {
  try {   
    const res = yield call(forgetPassword, action.data);
    yield put(ActionCreators.forgetPasswordSuccess(res))
  } catch (e){
    yield put(ActionCreators.forgetPasswordFailure(e))
  }
}
