import React, { useRef, useEffect, useState } from 'react';
import Config from '../../values/Strings/Config';

//TODO: Make this responsive

const CrosswordExtractor = ({ getCrosswordSingleData }) => {
    const canvasRef = useRef(null);
    const [grid, setGrid] = useState(Array.from({ length: 13 }, () => new Array(13).fill(0))); // Initialize a 13x13 grid

    const [cellSize, setCellSize] = useState(47.5); // Cell size for a 618x618 grid
    const [textInputs, setTextInputs] = useState(Array.from({ length: 13 }, () => new Array(13).fill(''))); // For text inputs

    useEffect(() => {
        const processImage = async () => {
            const img = new Image();
            img.crossOrigin = 'Anonymous'; // Ensure CORS is handled
            const imageUrl = `https://cors-anywhere.herokuapp.com/${Config.CROSSWORD_URL}${getCrosswordSingleData.data.image}`;
            img.src = imageUrl; // Use your image URL

            img.onload = () => {
                const canvas = canvasRef.current;
                const ctx = canvas.getContext('2d');
                canvas.width = img.width;
                canvas.height = img.height;
                ctx.drawImage(img, 0, 0, img.width, img.height);

                // Get the pixel data from the canvas
                const imageData = ctx.getImageData(0, 0, img.width, img.height);
                const data = imageData.data;

                const rows = 13;
                const cols = 13;
                const cellWidth = img.width / cols; // 618 / 13 = 47.538
                const cellHeight = img.height / rows; // 618 / 13 = 47.538

                // Initialize the grid
                const newGrid = Array.from({ length: rows }, () => new Array(cols).fill(0));

                // Iterate over the grid cells
                for (let row = 0; row < rows; row++) {
                    for (let col = 0; col < cols; col++) {
                        // Calculate the pixel region for each cell
                        const startX = Math.floor(col * cellWidth);
                        const startY = Math.floor(row * cellHeight);
                        const endX = Math.ceil((col + 1) * cellWidth);
                        const endY = Math.ceil((row + 1) * cellHeight);

                        // Initialize color counters
                        let blackCount = 0;
                        let whiteCount = 0;

                        // Count pixels in the cell
                        for (let y = startY; y < endY; y++) {
                            for (let x = startX; x < endX; x++) {
                                const index = (y * img.width + x) * 4; // Get pixel index (RGBA)

                                // Get the color (assuming a grayscale image)
                                const r = data[index];
                                const g = data[index + 1];
                                const b = data[index + 2];

                                // Check if the pixel is black or white
                                if (r === 0 && g === 0 && b === 0) {
                                    blackCount++;
                                } else {
                                    whiteCount++;
                                }
                            }
                        }

                        // Determine if the cell is editable or not
                        // Set 1 for editable (white) cells and 0 for non-editable (black) cells
                        newGrid[row][col] = whiteCount > blackCount ? 1 : 0; // 1 for white, 0 for black
                    }
                }

                // Update the grid state
                setGrid(newGrid);
                console.log("Processed Grid:", newGrid); // Log the 13x13 grid
            };

            img.onerror = (error) => {
                console.error("Error loading the image:", error);
            };
        };

        processImage();
    }, [getCrosswordSingleData]);

    const handleKeyDown = (row, col, event) => {
        const { key } = event;

        // Check if the key pressed is a single character
        if (key.length === 1) {
            // Update the text input for the specific cell to the new value, replacing the old character
            const updatedInputs = textInputs.map((r, rowIndex) =>
                r.map((cell, colIndex) => (rowIndex === row && colIndex === col ? key : cell))
            );
            setTextInputs(updatedInputs);
        }
    };

    return (
        <div>
            <canvas ref={canvasRef} style={{ display: 'none' }} />
            <div style={{ display: 'grid', gridTemplateColumns: `repeat(${grid[0]?.length}, ${cellSize}px)` }}>
                {grid.map((row, rowIndex) => (
                    row.map((cell, colIndex) => (
                        <div
                            key={`${rowIndex}-${colIndex}`}
                            style={{
                                width: `${cellSize}px`,
                                height: `${cellSize}px`,
                                backgroundColor: cell === 1 ? 'white' : 'black', // White for editable, black for non-editable
                                border: '1px solid gray',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                position: 'relative'
                            }}
                        >
                            {cell === 0 ? (
                                <div style={{ opacity: 0.01 }}>😊</div> // Optional symbol for non-editable cells
                            ) : (
                                <input
                                    type="text"
                                    value={textInputs[rowIndex][colIndex]}
                                    onKeyDown={(e) => handleKeyDown(rowIndex, colIndex, e)}
                                    maxLength={1} // Limit to one character
                                    style={{
                                        width: '100%',
                                        height: '100%',
                                        border: 'none',
                                        outline: 'none',
                                        textAlign: 'center',
                                        background: 'transparent', // Transparent background
                                        color: 'black',
                                        fontSize: '26px',
                                        textTransform: 'uppercase',
                                        pointerEvents: 'auto' // Allow input
                                    }}
                                />
                            )}
                        </div>
                    ))
                ))}
            </div>
        </div>
    );
};

export default CrosswordExtractor;
