import React, { useEffect, useRef } from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { ActionCreators } from "../actions";
import { Formik } from "formik";
import * as Yup from "yup";
import { Header, Footer } from "../components";
import Config from "../values/Strings/Config";
import { useAlert } from "react-alert";
import { useNavigate } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert"; // Import
import profileImg from "../assets/images/profile.svg";
import { fixedHeader } from "../values/utils";

const ProfileEdit = ({
  profileData,
  userData,
  profileUpdateDSPCH,
  profileUpdateData,
  profileUpdateLoad,
}) => {
  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  const alert = useAlert();
  let navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }, 100);
    fixedHeader();
  }, []);

  const profileUpdateDatasRef = useRef(profileUpdateData);

  useEffect(() => {
    if (profileUpdateData !== profileUpdateDatasRef.current) {
      if (profileUpdateData?.success === true) {
        navigate("/profile");
        alert.success(
          <div style={{ textTransform: "initial" }}>
            {profileUpdateData?.message}
          </div>
        );
      } else {
        alert.error(
          <div style={{ textTransform: "initial" }}>
            {profileUpdateData?.message}
          </div>
        );
      }
    }
  }, [profileUpdateData, profileUpdateDatasRef]);

  const onSubmit = (fields) => {
    let formData = new FormData();

    if (userData?.success === true) {
      const token = userData?.data?.token;

      formData.append("name", fields.name);
      formData.append("email", fields.email);
      formData.append("phone", fields.phoneNumber);
      if (fields.password !== "") {
        formData.append("password", fields.password);
      }
      if (fields.picture !== "") {
        formData.append("image", fields.picture);
      }

      if (fields.checked === true) {
        formData.append("remove", "1");
      }

      profileUpdateDSPCH({
        formData,
        token,
      });
    }
  };
  console.log(profileUpdateData,'profileUpdateData')
  return (
    <div>
      <Helmet>
        <title>Profile Edit - Object magazine</title>
        <meta name="description" content={""} />
      </Helmet>
      <Header />
      {/* <!--============================== modal Start ==============================--> */}
      <main id="main">
        <div className="content-container">
          <div className="container">
            <div className="row">
              <div className="col-md-6 offset-md-3">
                <div className="cc-content-box position-relative">
                  <div className="login-form-modal w-100">
                    <h4>Profile Edit</h4>
                    <div className="member-form">
                      <Formik
                        initialValues={{
                          name:
                            profileData?.success === true
                              ? profileData?.data?.user?.name
                              : "",
                          email:
                            profileData?.success === true
                              ? profileData?.data?.user?.email
                              : "",
                          password: "",
                          photo:
                            profileData?.success === true
                              ? profileData?.data?.user?.image
                                ? `${Config.USER_IMG_URL}${profileData?.data?.user?.image}`
                                : profileImg
                              : profileImg,
                          phoneNumber:
                            profileData?.success === true &&
                            profileData?.data?.user?.phone !== null
                              ? profileData?.data?.user?.phone
                              : "",
                          picture: "",
                          checked: false,
                        }}
                        enableReinitialize
                        validationSchema={Yup.object().shape({
                          name: Yup.string().required("Name is required"),
                          email: Yup.string()
                            .email("Email is invalid")
                            .required("Email is required"),
                          phoneNumber: Yup.string()
                            .required("Phone number is required")
                            .matches(phoneRegExp, "Phone number is not valid")
                            .min(10, "to short")
                            .max(10, "to long"),
                        })}
                        onSubmit={(fields) => onSubmit(fields)}
                      >
                        {({
                          errors,
                          touched,
                          setFieldValue,
                          values,
                          handleSubmit,
                          isSubmitting,
                        }) => (
                          <form
                            enctype="multipart/form-data"
                            onSubmit={handleSubmit}
                          >
                            <div style={{ marginBottom: 30 }}>
                              <img
                                htmlFor="photo-upload"
                                className="profile-img"
                                src={values.photo}
                                alt="userphoto"
                              />
                            </div>
                            <div className="form-group">
                              <input
                                style={{
                                  marginBottom: 30,
                                  display: "flex",
                                }}
                                id="photo-upload"
                                type="file"
                                accept="image/*"
                                onChange={(e) => {
                                  if (e.target.files.length === 0) {
                                    setFieldValue("picture", "");
                                    setFieldValue("photo", profileImg);
                                  } else {
                                    const fileExtension = e.target.files[0].name
                                      .split(".")
                                      .at(-1);
                                    const allowedFileTypes = [
                                      "jpg",
                                      "png",
                                      "jpeg",
                                      "gif",
                                      "svg",
                                    ];
                                    if (
                                      !allowedFileTypes.includes(
                                        fileExtension.toLowerCase()
                                      )
                                    ) {
                                      confirmAlert({
                                        message: `File does not support. Files type must be ${allowedFileTypes.join(
                                          ", "
                                        )}`,
                                        overlayClassName:
                                          "overlay-custom-class-name",
                                        buttons: [
                                          {
                                            label: "Ok",
                                            onClick: () => {
                                              setFieldValue("picture", "");
                                              setFieldValue(
                                                "photo",
                                                profileImg
                                              );
                                            },
                                          },
                                        ],
                                      });
                                      return false;
                                    } else {
                                      var picture = e.target.files[0];
                                      setFieldValue("picture", picture);
                                      var src = URL.createObjectURL(picture);
                                      setFieldValue("photo", src);
                                    }
                                  }
                                }}
                              />
                            </div>
                            {profileData?.success === true &&
                              profileData?.data?.user["image"] && (
                                <div
                                  className="form-group"
                                  style={{
                                    marginBottom: 30,
                                    display: "flex",
                                  }}
                                >
                                  <label>
                                    <span>
                                      <input
                                        type="checkbox"
                                        style={{
                                          marginRight: "20px",
                                          color: "black",
                                        }}
                                        defaultChecked={values.checked}
                                        onChange={() => {
                                          setFieldValue("picture", "");
                                          setFieldValue("photo", profileImg);
                                          setFieldValue(
                                            "checked",
                                            !values.checked
                                          );
                                        }}
                                      />
                                    </span>
                                    Remove profile picture
                                  </label>
                                </div>
                              )}
                            <div className="form-group">
                              <input
                                type="text"
                                className="form-control"
                                id="name"
                                placeholder="Name"
                                value={values.name}
                                onChange={(e) =>
                                  setFieldValue("name", e.target.value)
                                }
                              />
                            </div>
                            {touched.name && errors.name && (
                              <div
                                style={{
                                  textAlign: "left",
                                  color: "red",
                                  marginBottom: 10,
                                  fontSize: 12,
                                }}
                              >
                                {errors.name}
                              </div>
                            )}
                            <div className="form-group">
                              <input
                                type="email"
                                className="form-control"
                                id="email"
                                placeholder="Email Address"
                                value={values.email}
                                onChange={(e) =>
                                  setFieldValue("email", e.target.value)
                                }
                              />
                            </div>
                            {touched.email && errors.email && (
                              <div
                                style={{
                                  textAlign: "left",
                                  color: "red",
                                  marginBottom: 10,
                                  fontSize: 12,
                                }}
                              >
                                {errors.email}
                              </div>
                            )}
                            <div className="form-group">
                              <input
                                type="password"
                                className="form-control"
                                id="password"
                                placeholder="Password"
                                value={values.password}
                                onChange={(e) =>
                                  setFieldValue("password", e.target.value)
                                }
                              />
                            </div>
                            {touched.password && errors.password && (
                              <div
                                style={{
                                  textAlign: "left",
                                  color: "red",
                                  marginBottom: 10,
                                  fontSize: 12,
                                }}
                              >
                                {errors.password}
                              </div>
                            )}
                            <div className="form-group">
                              <input
                                type="text"
                                className="form-control"
                                id="phoneNumber"
                                placeholder="Phone Number"
                                value={values.phoneNumber}
                                onChange={(e) =>
                                  setFieldValue("phoneNumber", e.target.value)
                                }
                              />
                            </div>
                            {touched.phoneNumber && errors.phoneNumber && (
                              <div
                                style={{
                                  textAlign: "left",
                                  color: "red",
                                  marginBottom: 10,
                                  fontSize: 12,
                                }}
                              >
                                {errors.phoneNumber}
                              </div>
                            )}

                            <div className="form-group">
                              <button
                                type="submit"
                                className="btn  btn-default"
                                // disabled={isSubmitting}
                              >
                                {profileUpdateLoad ? "Loading..." : "Update"}
                              </button>
                            </div>
                          </form>
                        )}
                      </Formik>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
      {/* <!--============================== modal End ==============================--> */}
    </div>
  );
};

const mapStateToProps = (state) => ({
  profileData: state.profile.data,
  userData: state.login.user,
  profileUpdateData: state.profileUpdate.data,
  profileUpdateLoad: state.profileUpdate.load,
});

const mapDispatchToProps = (dispatch) => ({
  profileUpdateDSPCH: (data) => {
    dispatch(ActionCreators.profileUpdate(data));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfileEdit);
