import Config from "../values/Strings/Config";

export const plan = (tokenValue) => {
   const requestOptions = {
      method: "GET",
      headers: { "Content-Type": "application/json" },
   };
   let url = `${Config.PLAN_URL}?user=${tokenValue}`;
   return fetch(url, requestOptions)
      .then((res) => res.json())
      .then((res) => res);
};
