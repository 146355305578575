import { PLAN, PLAN_SUCCESS, PLAN_ERROR } from './type'

export const plan = (data) => ({
  type: PLAN,
  data
})

export const planSuccess = (data) => ({
  type: PLAN_SUCCESS,
  data
})

export const planFailure = (error) => ({
  type: PLAN_ERROR,
  error
})


