import React from "react";
import { Link, useLocation } from "react-router-dom";

function Col2Header() {
  const location = useLocation()

  return (
    <div className="page-link-container pb-0">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <ul className="page-links d-flex flex-wrap">
            <li className={`${location.pathname ==="/privacy-policy" ?"active": ""}`}>
                <Link to="/privacy-policy" className="page-link">
                  PRIVACY POLICY
                </Link>
              </li>
              <li className={`${location.pathname ==="/terms-and-conditions" ?"active": ""}`}>
                <Link to="/terms-and-conditions" className="page-link">
                  TERMS & CONDITIONS
                </Link>
              </li>
              <li className={`${location.pathname ==="/refund-and-cancellations" ?"active": ""}`}>
                <Link to="/refund-and-cancellations" className="page-link">
                  REFUND & CANCELLATIONS
                </Link>
              </li>
              <li className={`${location.pathname ==="/delivery-and-shipping" ?"active": ""}`}>
                <Link to="/delivery-and-shipping" className="page-link">
                  DELIVERY AND SHIPPING
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Col2Header;
