import React, { useEffect } from "react";
import { Footer, Header } from "../components";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import menuIcon3 from "../assets/images/menu-icon3.svg";
import menuIcon6 from "../assets/images/menu-icon6.svg";
import menuIcon8 from "../assets/images/menu-icon8.svg";
import { fixedHeader } from "../values/utils";

const NewsLetter = () => {
  useEffect(() => {
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }, 100);
    fixedHeader();
  }, []);

  return (
    <div>
      <Helmet>
        <title>Newletter - Object magazine</title>
        <meta name="description" content={""} />
      </Helmet>
      <Header />
      <main id="main">
        {/* <!--============================== Content Start ==============================--> */}
        <div className="content-container less-pad  pb-0">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="page-heading">
                  <h1>EXCLUSIVE NEWSLETTERS</h1>
                  <h3>
                    {" "}
                    Written By The Best Journalists In The Country, <br /> Our
                    Newsletters Covers Multiple Subjects.
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!--============================== Content End ==============================--> */}
        {/* <!--============================== Content Start ==============================--> */}
        <div className="content-container">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <ul className="newsletter-list">
                  <li className="newsletter-item">
                    <div className="newsletter-box d-flex flex-wrap bg-success">
                      <div className="newsletter-left">
                        <div className="newsletter-icon">
                          <img src={menuIcon8} alt="" />
                        </div>
                        <div className="newsletter-cat">Arts and Culture</div>
                      </div>
                      <div className="newsletter-mid">
                        <div className="newsletter-title">
                          <h2> MYTHOS</h2>
                        </div>
                        <div className="newsletter-weekend">
                          WEEKDAYS ON WEDNESDAY
                        </div>
                      </div>
                      <div className="newsletter-right">
                        <div className="newsletter-text">
                          <p>
                            {" "}
                            Lorem ipsum dolor sit amet, consectetuer adipiscing
                            elit, sed diam Lorem Lorem ipsum dolor sit amet,
                            consectetuer adipiscing elit, sed diam nonummy nibh
                            euismod tincidunt ut laoreet dolore magna aliquam
                            erat Lorem ipsum dolor sit amet, consecLorem ipsum
                            dolor sit amet.{" "}
                          </p>
                        </div>
                        <div className="newsletter-link">
                          <Link
                            to="/newsletter-arts-and-culture"
                            className="read-more-btn"
                          >
                            Click here to Read More
                          </Link>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li className="newsletter-item">
                    <div className="newsletter-box d-flex flex-wrap bg-muted">
                      <div className="newsletter-left">
                        <div className="newsletter-icon">
                          <img src={menuIcon3} alt="" />
                        </div>
                        <div className="newsletter-cat">Environment</div>
                      </div>
                      <div className="newsletter-mid">
                        <div className="newsletter-title">
                          <h3> HOT ROCK</h3>
                        </div>
                        <div className="newsletter-weekend">
                          WEEKDAYS ON WEDNESDAY
                        </div>
                      </div>
                      <div className="newsletter-right">
                        <div className="newsletter-text">
                          <p>
                            {" "}
                            Lorem ipsum dolor sit amet, consectetuer adipiscing
                            elit, sed diam Lorem Lorem ipsum dolor sit amet,
                            consectetuer adipiscing elit, sed diam nonummy nibh
                            euismod tincidunt ut laoreet dolore magna aliquam
                            erat Lorem ipsum dolor sit amet, consecLorem ipsum
                            dolor sit amet.{" "}
                          </p>
                        </div>
                        <div className="newsletter-link">
                          <Link
                            to="/newsletter-environment"
                            className="read-more-btn"
                          >
                            Click here to Read More
                          </Link>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li className="newsletter-item">
                    <div className="newsletter-box d-flex flex-wrap bg-secondary">
                      <div className="newsletter-left">
                        <div className="newsletter-icon">
                          <img src={menuIcon6} alt="" />
                        </div>
                        <div className="newsletter-cat">Sports</div>
                      </div>
                      <div className="newsletter-mid">
                        <div className="newsletter-title">
                          <h4> SERIOUS PLAY</h4>
                        </div>
                        <div className="newsletter-weekend">
                          WEEKDAYS ON WEDNESDAY
                        </div>
                      </div>
                      <div className="newsletter-right">
                        <div className="newsletter-text">
                          <p>
                            {" "}
                            Lorem ipsum dolor sit amet, consectetuer adipiscing
                            elit, sed diam Lorem Lorem ipsum dolor sit amet,
                            consectetuer adipiscing elit, sed diam nonummy nibh
                            euismod tincidunt ut laoreet dolore magna aliquam
                            erat Lorem ipsum dolor sit amet, consecLorem ipsum
                            dolor sit amet.{" "}
                          </p>
                        </div>
                        <div className="newsletter-link">
                          <Link to="/newsletter-sports" className="read-more-btn">
                            Click here to Read More
                          </Link>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        {/* <!--============================== Content End ==============================--> */}
        <Footer />
      </main>
    </div>
  );
};

export default NewsLetter;
