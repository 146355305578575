import { COMPLETE_ORDER, COMPLETE_ORDER_SUCCESS, COMPLETE_ORDER_ERROR } from './type'

export const completeOrder = (data, token) => ({
  type: COMPLETE_ORDER,
  data,
  token
})

export const completeOrderSuccess = (data) => ({
  type: COMPLETE_ORDER_SUCCESS,
  data
})

export const completeOrderFailure = (error) => ({
  type: COMPLETE_ORDER_ERROR,
  error
})


