import { takeEvery, call, put } from 'redux-saga/effects'
import { GET_CROSSWORD_SINGLE } from '../actions/type'
import { getCrosswordSingle } from '../services/getCrosswordSingle.api'
import { ActionCreators } from '../actions'

export const watchGetCrosswordSingle = function* () {    
  yield takeEvery(GET_CROSSWORD_SINGLE, workerGetCrosswordSingle)
}

function* workerGetCrosswordSingle(action) {
  try {   
    const res = yield call(getCrosswordSingle, action.data);
    yield put(ActionCreators.getCrosswordSingleSuccess(res))
  } catch (e){
    yield put(ActionCreators.getCrosswordSingleFailure(e))
  }
}
