import { takeEvery, call, put } from 'redux-saga/effects'
import { PAGE_DATA } from '../actions/type'
import { pageData } from '../services/pageData.api'
import { ActionCreators } from '../actions'

export const watchPageData = function* () {    
  yield takeEvery(PAGE_DATA, workerPageData)
}

function* workerPageData(action) {
  try {          
    const res = yield call(pageData, action.data);
    yield put(ActionCreators.pageDataSuccess(res))
  } catch (e){
    yield put(ActionCreators.pageDataFailure(e))
  }
}
