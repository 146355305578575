import { takeEvery, call, put } from 'redux-saga/effects'
import { GET_SAVED_ARTICLE } from '../actions/type'
import { getSavedArticle } from '../services/getSavedArticle.api'
import { ActionCreators } from '../actions'

export const watchGetSavedArticle = function* () {    
  yield takeEvery(GET_SAVED_ARTICLE, workerGetSavedArticle)
}

function* workerGetSavedArticle(action) {
  try {   
    const res = yield call(getSavedArticle, action.data);
    yield put(ActionCreators.getSavedArticleSuccess(res))
  } catch (e){
    yield put(ActionCreators.getSavedArticleFailure(e))
  }
}
