import Config from "../values/Strings/Config";

export const profile = ({ token }) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Authorization": "Bearer "+ token 
    },
  };
  let url = Config.PROFILE_URL ;
  return fetch(url, requestOptions)
    .then((res) => res.json())
    .then((res) => res);
};
