import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { Footer, Header } from "../components";
import Config from "../values/Strings/Config";
import { connect } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { ActionCreators } from "../actions";
import { useAlert } from "react-alert";
import { GoogleLogout } from "react-google-login";
import { gapi } from "gapi-script";
import headerShapeRight from "../assets/images/header-shape-right.svg";
import headerShapeLeft from "../assets/images/header-shape-left.svg";
import profileImg from "../assets/images/profile.svg";
import { fixedHeader } from "../values/utils";

const SubscriptionInActive = ({
  profileData,
  logoutDSPCH,
  userData,
  planDSPCH,
  planData,
}) => {
  const alert = useAlert();
  let navigate = useNavigate();

  const logout = () => {
    alert.info(
      <div style={{ textTransform: "initial" }}>
        You have successfully signed out!
      </div>
    );
    navigate("/");
    logoutDSPCH();
  };

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }, 100);
    fixedHeader();
    getPaln();
  }, []);

  const getPaln = () => {
    planDSPCH();
  };

  const onSuccess = () => {
    const auth2 = gapi.auth2.getAuthInstance();

    if (auth2 != null) {
      auth2.signOut().then(auth2.disconnect().then(logout()));
    }
  };

  return (
    <div>
      <Helmet>
        <title>Subscription In Active - Object Magazine</title>
        <meta name="description" content="{}" />
      </Helmet>

      <Header />

      {/* <!--============================== Main Start ==============================--> */}
      {/* <!--============================== Main Start ==============================--> */}
      <main id="main">
        {/* <!--============================== Content Start ==============================--> */}
        <div className="content-container profile-container">
          <div className="container">
            <div className="row">
              {profileData?.success === true && (
                <div className="col-lg-12">
                  <div className="profile-content d-flex flex-wrap">
                    <div className="profile-img">
                      <img
                        src={
                          profileData?.data?.user?.image
                            ? `${Config.USER_IMG_URL}${profileData?.data?.user?.image}`
                            : profileImg
                        }
                        alt="userphoto"
                      />{" "}
                    </div>
                    <div className="profile-deatail">
                      <h6>
                        PROFILE DETAILS
                        <span>
                          <Link to="/profile-edit" className="read-more-btn">
                            Edit
                          </Link>
                        </span>
                      </h6>
                      <h3>{profileData?.data?.user?.name}</h3>
                      <p>{profileData?.data?.user?.email}</p>
                      {userData?.success === true &&
                      userData?.data?.googleId === "" ? (
                        <button
                          className="btn btn-default "
                          onClick={() => logout()}
                        >
                          sign out
                        </button>
                      ) : (
                        <GoogleLogout
                          clientId={Config.GOOGLE_CLIENT_ID}
                          render={(renderProps) => (
                            <button
                              className="btn btn-default "
                              onClick={renderProps.onClick}
                            >
                              sign out
                            </button>
                          )}
                          buttonText="Logout"
                          onLogoutSuccess={onSuccess}
                        ></GoogleLogout>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        {/* <!--============================== Content End ==============================--> */}
        {/* <!--============================== Content Start ==============================--> */}
        <div className="content-container p-0">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="subscription-tabs-content">
                  <ul className="subscription-tabs d-flex justify-content-between">
                    <li>
                      <Link to="/profile" className="subscription-tabs-link">
                        SAVED ARTICLES
                      </Link>
                    </li>
                    <li className="active">
                      <Link
                        to="/subscription-inactive"
                        className="subscription-tabs-link"
                      >
                        SUBSCRIPTION DETAILS
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!--============================== Content End ==============================-->  */}
        {/* <!--============================== Content Start ==============================--> */}
        <div className="content-container p-0">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="tab-subscription-box">
                  <div className="subscription-heading">
                    <h3>You Have No Active Subscriptions</h3>
                    <p>
                      {" "}
                      Choose from our subscription plans to gain unlimited
                      access <br /> to all our stories and archives.
                    </p>
                  </div>
                  <div className="inactive-tab-subscription">
                    <ul className="its-plane-list">
                      {planData?.success === true &&
                        planData?.data?.map((item, ind) => (
                          <li className="its-plane-item" key={ind}>
                            <Link to="/subscription" className="its-plane">
                              {ind === 0 && (
                                <div className="its-recommemd">
                                  <span>RECOMMENDED</span>
                                </div>
                              )}

                              <h3>
                                {item?.name}{" "}
                                <span>
                                  {" "}
                                  at just Rs.
                                  {parseFloat(item?.amount / 30).toFixed(2)}
                                  /DAY
                                </span>
                              </h3>
                              <h5>
                                <span> only </span> Rs. {item?.amount}
                              </h5>
                            </Link>
                          </li>
                        ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!--============================== Content End ==============================-->  */}
        {/* <!--============================== Content Start ==============================--> */}
        <div className="content-container">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="end-section">
                  <h5>
                    <span className="left">
                      <img src={headerShapeRight} alt="" />
                    </span>
                    end
                    <span className="right">
                      <img src={headerShapeLeft} alt="" />
                    </span>
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <!--============================== Content End ==============================--> */}
      </main>
      {/* <!--============================== Main End ==============================--> */}
      {/* <!--============================== Footer Start ==============================--> */}
      <Footer />
      {/* <!--============================== Footer End ==============================--> */}
    </div>
  );
};

const mapStateToProps = (state) => ({
  userData: state.login.user,
  profileData: state.profile.data,
  planData: state.plan.data,
});

const mapDispatchToProps = (dispatch) => ({
  logoutDSPCH: () => {
    dispatch(ActionCreators.logoutUser());
  },
  planDSPCH: () => {
    dispatch(ActionCreators.plan());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SubscriptionInActive);
