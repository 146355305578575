import { GET_QUOTE, GET_QUOTE_SUCCESS, GET_QUOTE_ERROR } from "../actions/type"

const defaultState = {
  load: false,
  data: {},
  error: null,
}

export const getQuote = (state = defaultState, action) => {
  const response = action.data;
  switch (action.type) {
    case GET_QUOTE:
      return {
        ...state,
        load: true
      }
    case GET_QUOTE_SUCCESS:
      return { 
        ...state, 
        data: response,
        load: false
      }
    case GET_QUOTE_ERROR:
      return { 
        ...state, 
        error: response,
        load: false,
      }
    default:
      return state
  }
}


