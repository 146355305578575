import { CMS_FOOTER, CMS_FOOTER_SUCCESS, CMS_FOOTER_ERROR } from './type'

export const cmsFooter = (data) => ({
  type: CMS_FOOTER,
  data
})

export const cmsFooterSuccess = (data) => ({
  type: CMS_FOOTER_SUCCESS,
  data
})

export const cmsFooterFailure = (error) => ({
  type: CMS_FOOTER_ERROR,
  error
})


